import { IAgency } from "api/types/_agency";
import { IMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownAgency from "components/Common/DropdownAgency";
import DropdownMedia from "components/Common/DropdownMedia";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import SVGImageDefault from "components/Images/SVGImageDefault";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { formatLinkHTTPS, formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getAllAgencies, getAllMedias, reportMediasSales as onGetReportMediasSales } from "../../../store/thunks";
import DropdownOption from "components/Common/DropdownOption";


const SORT_BY_DEFAULT = 'impression';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const ReportMediaSales = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const OS_DATA_FORMAT_OPTIONS = [
        {
            label: t('Rate'),
            value: 'rate'
        },
        {
            label: t('Number'),
            value: 'number'
        }
    ];
    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 100),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        media_id: withDefault(StringParam, ''),
        agency_id: withDefault(StringParam, ''),
        start_date: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),
        end_date: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),
        os_data_format: withDefault(StringParam, OS_DATA_FORMAT_OPTIONS[0]?.value),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Report;

    const ReportProperties = createSelector(
        selectLayoutState,
        (state) => ({
            mediasSales: state.mediasSales,
            isMediaSaleSuccess: state.isMediaSaleSuccess,
            isMediaSalesLoading: state.isMediaSalesLoading,
            error: state.error,
        })
    );

    const { mediasSales, isMediaSalesLoading } = useSelector(ReportProperties);
    const [isCallAllOptionLoading, setIsCallAllOptionLoading] = useState<boolean>(true);

    const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date, 'Y-MM-DD').toDate() : null, query?.end_date ? moment(query?.end_date || '', 'Y-MM-DD').toDate() : null]);
    const [startDate, endDate] = dateSearch;

    const [listMedia, setListMedia] = useState<IMedia[]>([]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>({
        label: listMedia?.filter((item) => String(item?.id || '') === String(query?.media_id || ''))[0]?.name || t('All Media'),
        value: `${listMedia?.filter((item) => String(item?.id || '') === String(query?.media_id || ''))[0]?.id || ''}`
    });


    const [listAgency, setListAgency] = useState<IAgency[]>([]);
    const [agencySearch, setAgencySearch] = useState<Option | null>({
        label: listAgency?.find((item) => String(item?.id || '') === String(query?.agency_id || ''))?.name || t('All Agency'),
        value: `${listAgency?.find((item) => String(item?.id || '') === String(query?.agency_id || ''))?.code || ''}`
    });

    const [osDataFormatSearch, setOsDataFormatSearch] = useState<Option | null>(OS_DATA_FORMAT_OPTIONS?.filter((item) => String(item?.value) === String(query?.os_data_format))[0]);

    useEffect(() => {
        dispatch(onGetReportMediasSales(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            media_id: mediaSearch?.value || '',
            agency_id: agencySearch?.value || '',
            os_data_format: osDataFormatSearch?.value || OS_DATA_FORMAT_OPTIONS[0]?.value,
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            start_date: `${moment().format("Y-MM-DD")}`,
            end_date: `${moment().format("Y-MM-DD")}`,
            media_id: '',
            agency_id: '',
            os_data_format: OS_DATA_FORMAT_OPTIONS[0]?.value,
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
        setMediaSearch({ label: t('All Media'), value: '' });
        setAgencySearch({ label: t('All Agency'), value: '' });
        setOsDataFormatSearch(OS_DATA_FORMAT_OPTIONS[0])
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Ranking'),
                accessor: "rank",
                filterable: true,
                sortable: false,
                thWidth: 100,
                thClass: 'align-middle text-center',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '60px' }}>
                            <span>{cell?.value}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Media'),
                accessor: "media",
                filterable: true,
                sortable: false,
                thWidth: 220,
                thClass: 'align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const media = item?.media || null;
                    return (<>
                        {item?.text_row ? <strong>{item?.text_row}</strong> :
                            (<div className="d-flex align-items-center" style={{ minWidth: '200px' }}>
                                <div className="me-2">
                                    {media?.logo_url ? (
                                        <img src={media?.logo_url || ''} alt={media?.name} className="avatar-sm object-fit-contain" style={{ backgroundColor: 'rgb(234 234 234 / 49%)', borderRadius: '5px' }} />) : (
                                        <SVGImageDefault style={{ height: '48px' }} />
                                    )}
                                </div>
                                <div>
                                    <div>
                                        {media?.name || ''}
                                    </div>
                                    <Link className='text-normal flex-shrink-0 ' to={formatLinkHTTPS(media?.website_link)} target="_blank">
                                        {media?.website || ''}
                                    </Link>
                                </div>
                            </div>)}
                    </>)
                },
            },
            {
                Header: t('Impression'),
                accessor: "impression",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 210,
                thComponent: () => (
                    <div className="text-center d-inline-block">
                        {t('Impression')}
                        <br />
                        <span className="fw-normal">({t('AOS/iOS/Etc')})</span>
                    </div>
                ),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                            <div className={`text-end pe-3 ${!!item?.text_row ? '' : 'text-muted'}`} style={{ minWidth: '100px' }}>(
                                {formatNumberWithCommas(item?.os_impressions?.[query?.os_data_format === 'rate' ? 'rate_android' : 'android'])} / {formatNumberWithCommas(item?.os_impressions?.[query?.os_data_format === 'rate' ? 'rate_ios' : 'ios'])} / {formatNumberWithCommas(item?.os_impressions?.[query?.os_data_format === 'rate' ? 'rate_etc' : 'etc'])}
                                )</div>
                        </>
                    )
                },
            },
            {
                Header: t('Click'),
                accessor: "click",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 210,
                thComponent: () => (
                    <div className="text-center d-inline-block">
                        {t('Click')}
                        <br />
                        <span className="fw-normal">({t('AOS/iOS/Etc')})</span>
                    </div>
                ),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                            <div className={`text-end pe-3 ${!!item?.text_row ? '' : 'text-muted'}`} style={{ minWidth: '100px' }}>(
                                {formatNumberWithCommas(item?.os_clicks?.[query?.os_data_format === 'rate' ? 'rate_android' : 'android'])} / {formatNumberWithCommas(item?.os_clicks?.[query?.os_data_format === 'rate' ? 'rate_ios' : 'ios'])} / {formatNumberWithCommas(item?.os_clicks?.[query?.os_data_format === 'rate' ? 'rate_etc' : 'etc'])}

                                )</div>
                        </>
                    )
                },
            },
            {
                Header: t('CTR'),
                accessor: "ctr",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 170,
                thComponent: () => (
                    <div className="text-center d-inline-block">
                        {t('CTR')}
                        <br />
                        <span className="fw-normal">({t('AOS/iOS/Etc')})</span>
                    </div>
                ),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)} %</div>
                            <div className={`text-end pe-3 ${!!item?.text_row ? '' : 'text-muted'}`} style={{ minWidth: '100px' }}>(
                                {formatNumberWithCommas(item?.os_ctr?.android)} / {formatNumberWithCommas(item?.os_ctr?.ios)} / {formatNumberWithCommas(item?.os_ctr?.etc)}
                                )</div>
                        </>
                    )
                },
            },
            {
                Header: t('Click'),
                accessor: "normal_click",
                thClass: 'text-end',
                filterable: false,
                sortable: false,
                // description: t('Description PV field each platform'),
                thWidth: 200,
                thComponent: () => (
                    <table>
                        <thead>
                            <tr>
                                <th className="text-center pb-1" colSpan={2}>
                                    {t('Click')}
                                </th>
                            </tr>
                            <tr>
                                <tr>
                                    <th className="text-center px-0 py-1 cursor-pointer" style={{ width: '100px' }}
                                    >
                                        {t('Click')}
                                    </th>
                                    <th className="text-center px-0 py-1 cursor-pointer" style={{ width: '100px' }}
                                    >
                                        {t('Ratio')}
                                    </th>
                                </tr>
                            </tr>
                        </thead>
                    </table>
                ),
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original?.normal_click;
                    return (
                        <div className="d-flex text-center">
                            <div style={{ width: '100px' }}>{formatNumberWithCommas(item?.click)}</div>
                            <div style={{ width: '100px', borderLeft: "1px solid #eaeaea", }}>{item?.ctr} %</div>
                        </div>
                    );
                },
            },
            {
                Header: t('Redirect'),
                accessor: "auto_redirect",
                thClass: 'text-end',
                filterable: false,
                sortable: false,
                // description: t('Description PV field each platform'),
                thWidth: 200,
                thComponent: () => (
                    <table>
                        <thead>
                            <tr>
                                <th className="text-center pb-1" colSpan={2}>
                                    {t('Redirect')}
                                </th>
                            </tr>
                            <tr>
                                <tr>
                                    <th className="text-center px-0 py-1 cursor-pointer" style={{ width: '100px' }}
                                    >
                                        {t('Redirect')}
                                    </th>
                                    <th className="text-center px-0 py-1 cursor-pointer" style={{ width: '100px' }}
                                    >
                                        {t('Ratio')}
                                    </th>
                                </tr>
                            </tr>
                        </thead>
                    </table>
                ),
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original?.auto_redirect;
                    return (
                        <div className="d-flex text-center">
                            <div style={{ width: '100px' }}>{formatNumberWithCommas(item?.click)}</div>
                            <div style={{ width: '100px', borderLeft: "1px solid #eaeaea", }}>{item?.ctr} %</div>
                        </div>
                    );
                },
            },
            {
                Header: t('Page RPM'),
                accessor: "page_rpm",
                filterable: true,
                sortable: false,
                thClass: 'align-middle text-end',
                description: t('Page revenue per thousand impressions (RPM) is calculated by dividing your estimated earnings by the number of page views you received, then multiplying by 1000'),
                thWidth: 130,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end" style={{ minWidth: '120px' }}>{String(cell?.value) === "0" ? "-" : formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Impression RPM'),
                accessor: "impression_rpm",
                filterable: true,
                sortable: false,
                thClass: 'align-middle text-end',
                description: t('The impression revenue per thousand impressions (RPM) is the average earnings per one thousand'),
                thWidth: 170,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end" style={{ minWidth: '150px' }}>{String(cell?.value) === "0" ? "-" : formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Commission'),
                accessor: "commission",
                filterable: true,
                sortable: false,
                thClass: 'align-middle text-end',
                thWidth: 120,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end" style={{ minWidth: '100px' }}>{String(cell?.value) === "0" ? "-" : formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Media Revenue'),
                accessor: "coupang_revenue",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 140,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '130px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Agency Revenue'),
                accessor: "agency_revenue",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '130px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('AdsHub Revenue'),
                accessor: "adshub_revenue",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 160,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '140px' }}>{item?.text_row ? formatNumberWithCommas(cell?.value) : '-'}</div>
                        </>
                    )
                },
            },
        ],
        [i18n?.language, userPermissions, query?.os_data_format]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                setIsCallAllOptionLoading((_prev) => true);
                const [resMedia, resAgency]: any = await Promise.all([getAllMedias(), getAllAgencies()]);
                setIsCallAllOptionLoading((_prev) => false);
                const list_m = ([{ name: t('All Media'), id: '', } as any]).concat(resMedia?.data || []);
                const media = list_m?.find((item) => String(item?.id || '') === String(query?.id || '')) || null;
                setListMedia((_prev) => list_m);
                setMediaSearch((_prev) => ({
                    label: media?.name || t('All Media'),
                    value: `${media?.id || ''}`
                }));

                const list_a = ([{ name: t('All Agency'), id: '', code: '' } as any]).concat(resAgency?.data || []);
                const agency = list_a?.find((item) => String(item?.id || '') === String(query?.agency_id || '')) || null;
                setListAgency((_prev) => list_a);
                setAgencySearch((_prev) => ({
                    label: agency?.name || t('All Agency'),
                    value: `${agency?.id || ''}`
                }));

            } catch (error: any) {
                setIsCallAllOptionLoading((_prev) => false);
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        if (mediaSearch) {
            setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
            (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
        }
        if (agencySearch) {
            setListAgency((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Agency') })));
            (!agencySearch?.value) && setAgencySearch((prev) => ({ label: t('All Agency'), value: '' }));
        }
        document.title = `${t("Report Media (Sales)")} - ${t("Reports")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Report Media (Sales)')} pageTitle={t('Reports')} />
                    {isHavePermissionRole(ROLES_FOR_APP.REPORT_MEDIA_SALES, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('This data is analyzed per 10 mins')}</div>
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={mediasSales?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={12} md={3} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Agency')} isShow={!!agencySearch?.value}>
                                                    <DropdownAgency
                                                        name="agency"
                                                        typeValue="id"
                                                        dataList={listAgency}
                                                        initialValue={agencySearch}
                                                        onChangeSelect={(event: any) => {
                                                            setAgencySearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={3} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                                    <DropdownMedia
                                                        name="medias"
                                                        dataList={listMedia}
                                                        initialValue={mediaSearch}
                                                        onChangeSelect={(event: any) => {
                                                            setMediaSearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={3} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t("OS Data Format")} isShow={true}>
                                                    <DropdownOption
                                                        name="ad_platform"
                                                        dataList={OS_DATA_FORMAT_OPTIONS}
                                                        placeholder={`${t("OS Data Format")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={osDataFormatSearch || OS_DATA_FORMAT_OPTIONS[0]}
                                                        onChangeSelect={(e: any) => setOsDataFormatSearch(e)}
                                                        isHasOptionAll={false}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={3} xl={3} xxl={2} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDate || null}
                                                        endDate={endDate || null}
                                                        onChangePicker={handleChangePicker}
                                                        showOptions={[
                                                            'today', 'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={12} xl={12} xxl={3} className="hstack gap-1 justify-content-center justify-content-md-end justify-content-xxl-between mt-3 mt-xxl-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={(isMediaSalesLoading || isCallAllOptionLoading)}
                                                    >
                                                        {(isMediaSalesLoading || isCallAllOptionLoading) ? <div className="me-1" style={{ display: 'inline-table', verticalAlign: 'top' }}><Spinner size="sm" style={{ width: '14px', height: '14px' }}></Spinner></div> : <i className="ri-search-line align-bottom me-1"></i>}{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed table-have-first-row-is-total sticky-table-report-media"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={mediasSales?.list?.length ? (
                                                [
                                                    {
                                                        ...mediasSales?.total,
                                                        impression_coupang: mediasSales?.coupang?.impression,
                                                        click_coupang: mediasSales?.coupang?.click,
                                                        commission_coupang: mediasSales?.coupang?.commission,
                                                        ctr_coupang: mediasSales?.coupang?.ctr,
                                                        domain: null,
                                                        text_row: t('Sum')
                                                    },
                                                    ...mediasSales?.list]
                                            ) : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={mediasSales?.pagination?.total}
                                            customPageCount={Math.ceil(Number(mediasSales?.pagination?.total) / Number(mediasSales?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isMediaSalesLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ReportMediaSales;
