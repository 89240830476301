import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IIP, IKeyword, IMediaIPStatistic, IMediaStatistic, IPath, IReferrer, IUUID } from "api/types/_statistic";
import { ipBlocks, ipExcepts, ipStatistics, keywordStatistics, mediaIPStatistics, mediaStatistics, pathStatistics, referrerStatistics, uuidBlocks, uuidExcepts, uuidStatistics } from "./thunk";



export interface IState {
  ipStatistics: ResponseData<IIP[]> & PaginationResponse | null,
  isIpStatisticLoading: boolean,
  isIpStatisticSuccess: boolean,

  uuidStatistics: ResponseData<IUUID[]> & PaginationResponse | null,
  isUUIDStatisticLoading: boolean,
  isUUIDStatisticSuccess: boolean,

  referrerStatistics: ResponseData<IReferrer[]> & PaginationResponse | null,
  isReferrerStatisticLoading: boolean,
  isReferrerStatisticSuccess: boolean,

  mediaStatistics: ResponseData<IMediaStatistic[]> & PaginationResponse | null,
  isMediaStatisticLoading: boolean,
  isMediaStatisticSuccess: boolean,

  pathStatistics: ResponseData<IPath[]> & PaginationResponse | null,
  isPathStatisticLoading: boolean,
  isPathStatisticSuccess: boolean,

  uuidBlocks: ResponseData<IUUID[]> & PaginationResponse | null,
  isUUIDBlockLoading: boolean,
  isUUIDBlockSuccess: boolean,

  uuidExcepts: ResponseData<IUUID[]> & PaginationResponse | null,
  isUUIDBlockAdsLoading: boolean,
  isUUIDBlockAdsSuccess: boolean,


  ipBlocks: ResponseData<IIP[]> & PaginationResponse | null,
  isIPBlockLoading: boolean,
  isIPBlockSuccess: boolean,

  ipExcepts: ResponseData<IIP[]> & PaginationResponse | null,
  isIPBlockAdsLoading: boolean,
  isIPBlockAdsSuccess: boolean,

  mediaIPStatistics: ResponseData<IMediaIPStatistic[]> & PaginationResponse | null,
  isMediaIPStatisticLoading: boolean,
  isMediaIPStatisticSuccess: boolean,


  keywordStatistics: ResponseData<IKeyword[]> & PaginationResponse | null,
  isKeywordStatisticLoading: boolean,
  isKeywordStatisticSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  ipStatistics: null,
  isIpStatisticLoading: false,
  isIpStatisticSuccess: false,

  uuidStatistics: null,
  isUUIDStatisticLoading: false,
  isUUIDStatisticSuccess: false,

  referrerStatistics: null,
  isReferrerStatisticLoading: false,
  isReferrerStatisticSuccess: false,

  mediaStatistics: null,
  isMediaStatisticLoading: false,
  isMediaStatisticSuccess: false,

  pathStatistics: null,
  isPathStatisticLoading: false,
  isPathStatisticSuccess: false,

  uuidBlocks: null,
  isUUIDBlockLoading: false,
  isUUIDBlockSuccess: false,

  uuidExcepts: null,
  isUUIDBlockAdsLoading: false,
  isUUIDBlockAdsSuccess: false,

  ipBlocks: null,
  isIPBlockLoading: false,
  isIPBlockSuccess: false,

  ipExcepts: null,
  isIPBlockAdsLoading: false,
  isIPBlockAdsSuccess: false,

  mediaIPStatistics: null,
  isMediaIPStatisticLoading: false,
  isMediaIPStatisticSuccess: false,

  keywordStatistics: null,
  isKeywordStatisticLoading: false,
  isKeywordStatisticSuccess: false,

  error: {},
};

const StatisticSlice = createSlice({
  name: "Statistic",
  initialState,
  reducers: {
    changeStatusBlockIPAction(state: any, action: any) {
      state.ipStatistics = action.payload;
    },
    changeStatusBlockUUIDAction(state: any, action: any) {
      state.uuidStatistics = action.payload;
    },
  },
  extraReducers: (builder) => {
    // IP Statistics
    builder.addCase(ipStatistics.pending, (state: IState, action: any) => {
      state.isIpStatisticLoading = true
    });
    builder.addCase(ipStatistics.fulfilled, (state: IState, action: any) => {
      state.ipStatistics = action.payload.data;
      state.isIpStatisticSuccess = true;
      state.isIpStatisticLoading = false;
    });
    builder.addCase(ipStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isIpStatisticSuccess = false;
      state.isIpStatisticLoading = false;
    });

    // UUID Statistics
    builder.addCase(uuidStatistics.pending, (state: IState, action: any) => {
      state.isUUIDStatisticLoading = true
    });
    builder.addCase(uuidStatistics.fulfilled, (state: IState, action: any) => {
      state.uuidStatistics = action.payload.data;
      state.isUUIDStatisticSuccess = true;
      state.isUUIDStatisticLoading = false;
    });
    builder.addCase(uuidStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isUUIDStatisticSuccess = false;
      state.isUUIDStatisticLoading = false;
    });


    // Referrer Statistics
    builder.addCase(referrerStatistics.pending, (state: IState, action: any) => {
      state.isReferrerStatisticLoading = true
    });
    builder.addCase(referrerStatistics.fulfilled, (state: IState, action: any) => {
      state.referrerStatistics = action.payload.data;
      state.isReferrerStatisticSuccess = true;
      state.isReferrerStatisticLoading = false;
    });
    builder.addCase(referrerStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isReferrerStatisticSuccess = false;
      state.isReferrerStatisticLoading = false;
    });

    // Media Statistics
    builder.addCase(mediaStatistics.pending, (state: IState, action: any) => {
      state.isMediaStatisticLoading = true
    });
    builder.addCase(mediaStatistics.fulfilled, (state: IState, action: any) => {
      state.mediaStatistics = action.payload.data;
      state.isMediaStatisticSuccess = true;
      state.isMediaStatisticLoading = false;
    });
    builder.addCase(mediaStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaStatisticSuccess = false;
      state.isMediaStatisticLoading = false;
    });

    // Path Statistics
    builder.addCase(pathStatistics.pending, (state: IState, action: any) => {
      state.isPathStatisticLoading = true
    });
    builder.addCase(pathStatistics.fulfilled, (state: IState, action: any) => {
      state.pathStatistics = action.payload.data;
      state.isPathStatisticSuccess = true;
      state.isPathStatisticLoading = false;
    });
    builder.addCase(pathStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isPathStatisticSuccess = false;
      state.isPathStatisticLoading = false;
    });

    // UUID Blocks
    builder.addCase(uuidBlocks.pending, (state: IState, action: any) => {
      state.isUUIDBlockLoading = true
    });
    builder.addCase(uuidBlocks.fulfilled, (state: IState, action: any) => {
      state.uuidBlocks = action.payload.data;
      state.isUUIDBlockSuccess = true;
      state.isUUIDBlockLoading = false;
    });
    builder.addCase(uuidBlocks.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isUUIDBlockSuccess = false;
      state.isUUIDBlockLoading = false;
    });

    // UUID Except
    builder.addCase(uuidExcepts.pending, (state: IState, action: any) => {
      state.isUUIDBlockAdsLoading = true
    });
    builder.addCase(uuidExcepts.fulfilled, (state: IState, action: any) => {
      state.uuidExcepts = action.payload.data;
      state.isUUIDBlockAdsSuccess = true;
      state.isUUIDBlockAdsLoading = false;
    });
    builder.addCase(uuidExcepts.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isUUIDBlockAdsSuccess = false;
      state.isUUIDBlockAdsLoading = false;
    });


    // IP Blocks
    builder.addCase(ipBlocks.pending, (state: IState, action: any) => {
      state.isIPBlockLoading = true
    });
    builder.addCase(ipBlocks.fulfilled, (state: IState, action: any) => {
      state.ipBlocks = action.payload.data;
      state.isIPBlockSuccess = true;
      state.isIPBlockLoading = false;
    });
    builder.addCase(ipBlocks.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isIPBlockSuccess = false;
      state.isIPBlockLoading = false;
    });

    // IP Except
    builder.addCase(ipExcepts.pending, (state: IState, action: any) => {
      state.isIPBlockAdsLoading = true
    });
    builder.addCase(ipExcepts.fulfilled, (state: IState, action: any) => {
      state.ipExcepts = action.payload.data;
      state.isIPBlockAdsSuccess = true;
      state.isIPBlockAdsLoading = false;
    });
    builder.addCase(ipExcepts.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isIPBlockAdsSuccess = false;
      state.isIPBlockAdsLoading = false;
    });

    // Media IP Statistics
    builder.addCase(mediaIPStatistics.pending, (state: IState, action: any) => {
      state.isMediaIPStatisticLoading = true
    });
    builder.addCase(mediaIPStatistics.fulfilled, (state: IState, action: any) => {
      state.mediaIPStatistics = action.payload.data;
      state.isMediaIPStatisticSuccess = true;
      state.isMediaIPStatisticLoading = false;
    });
    builder.addCase(mediaIPStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaIPStatisticSuccess = false;
      state.isMediaIPStatisticLoading = false;
    });


    // Keyword Statistics
    builder.addCase(keywordStatistics.pending, (state: IState, action: any) => {
      state.isKeywordStatisticLoading = true
    });
    builder.addCase(keywordStatistics.fulfilled, (state: IState, action: any) => {
      state.keywordStatistics = action.payload.data;
      state.isKeywordStatisticSuccess = true;
      state.isKeywordStatisticLoading = false;
    });
    builder.addCase(keywordStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordStatisticSuccess = false;
      state.isKeywordStatisticLoading = false;
    });
  },
});

export const {
  changeStatusBlockIPAction, changeStatusBlockUUIDAction
} = StatisticSlice.actions;

export default StatisticSlice.reducer;