import { createSlice } from "@reduxjs/toolkit";
import { IADPlatform } from "api/types/_adPlatform";
import { IAdvertiser, IConfigCPC } from "api/types/_advertiser";
import { IAdvertising } from "api/types/_advertising";
import { IAgency } from "api/types/_agency";
import { ICredential } from "api/types/_credential";
import { IInventory, IInventoryHistory } from "api/types/_inventory";
import { IHistoryConfigCPM, IMedia } from "api/types/_media";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getADPlatforms, getAdvertiserAgencies, getAdvertisers, getAdvertising, getAgencies, getCoupangs, getCredentials, getHistoryConfigAdvertiserCPC, getHistoryConfigCPM, getInventories, getInventoryHistories, getMedias, getSSPMedias } from "./thunk";
import { IAdvertiserAgency } from "api/types/_advertiserAgency";
import { ISSPMedia } from "api/types/_ssp";
import { ICoupang } from "api/types/_coupang";


export interface IState {
  medias: ResponseData<IMedia[]> & PaginationResponse | null,
  isMediaLoading: boolean,
  isMediaSuccess: boolean,

  inventories: ResponseData<IInventory[]> & PaginationResponse | null,
  isInventoryLoading: boolean,
  isInventorySuccess: boolean,

  inventoryHistories: ResponseData<IInventoryHistory[]> & PaginationResponse | null,
  isInventoryHistoryLoading: boolean,
  isInventoryHistorySuccess: boolean,

  historyConfigs: ResponseData<IHistoryConfigCPM[]> & PaginationResponse | null,
  isHistoryConfigLoading: boolean,
  isHistoryConfigSuccess: boolean,

  credentials: ResponseData<ICredential[]> & PaginationResponse | null,
  isCredentialLoading: boolean,
  isCredentialSuccess: boolean,

  agencies: ResponseData<IAgency[]> & PaginationResponse | null,
  isAgencyLoading: boolean,
  isAgencySuccess: boolean,

  advertiserAgencies: ResponseData<IAdvertiserAgency[]> & PaginationResponse | null,
  isAdvertiserAgencyLoading: boolean,
  isAdvertiserAgencySuccess: boolean,

  advertisers: ResponseData<IAdvertiser[]> & PaginationResponse | null,
  isAdvertiserLoading: boolean,
  isAdvertiserSuccess: boolean,

  advertising: ResponseData<IAdvertising[]> & PaginationResponse | null,
  isAdvertisingLoading: boolean,
  isAdvertisingSuccess: boolean,

  historyConfigAdvertiserCPCs: ResponseData<IConfigCPC[]> & PaginationResponse | null,
  isHistoryConfigAdvertiserCPCLoading: boolean,
  isHistoryConfigAdvertiserCPCSuccess: boolean,

  ADPlatforms: ResponseData<IADPlatform[]> & PaginationResponse | null,
  isADPlatformLoading: boolean,
  isADPlatformSuccess: boolean,

  SSPMedias: ResponseData<ISSPMedia[]> & PaginationResponse | null,
  isSSPMediaLoading: boolean,
  isSSPMediaSuccess: boolean,

  coupangs: ResponseData<ICoupang[]> & PaginationResponse | null,
  isCoupangLoading: boolean,
  isCoupangSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  medias: null,
  isMediaLoading: false,
  isMediaSuccess: false,

  inventories: null,
  isInventoryLoading: false,
  isInventorySuccess: false,

  inventoryHistories: null,
  isInventoryHistoryLoading: false,
  isInventoryHistorySuccess: false,

  historyConfigs: null,
  isHistoryConfigLoading: false,
  isHistoryConfigSuccess: false,

  credentials: null,
  isCredentialLoading: false,
  isCredentialSuccess: false,

  agencies: null,
  isAgencyLoading: false,
  isAgencySuccess: false,

  advertiserAgencies: null,
  isAdvertiserAgencyLoading: false,
  isAdvertiserAgencySuccess: false,

  advertisers: null,
  isAdvertiserLoading: false,
  isAdvertiserSuccess: false,

  historyConfigAdvertiserCPCs: null,
  isHistoryConfigAdvertiserCPCLoading: false,
  isHistoryConfigAdvertiserCPCSuccess: false,

  advertising: null,
  isAdvertisingLoading: false,
  isAdvertisingSuccess: false,

  ADPlatforms: null,
  isADPlatformLoading: false,
  isADPlatformSuccess: false,

  SSPMedias: null,
  isSSPMediaLoading: false,
  isSSPMediaSuccess: false,

  coupangs: null,
  isCoupangLoading: false,
  isCoupangSuccess: false,

  error: {},
};

const ManagementSlice = createSlice({
  name: "Management",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get medias
    builder.addCase(getMedias.pending, (state: IState, action: any) => {
      state.isMediaLoading = true
    });
    builder.addCase(getMedias.fulfilled, (state: IState, action: any) => {
      state.medias = action.payload.data;
      state.isMediaSuccess = true;
      state.isMediaLoading = false;
    });
    builder.addCase(getMedias.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaSuccess = false;
      state.isMediaLoading = false;
    });

    //get Inventories
    builder.addCase(getInventories.pending, (state: IState, action: any) => {
      state.isInventoryLoading = true
    });
    builder.addCase(getInventories.fulfilled, (state: IState, action: any) => {
      state.inventories = action.payload.data;
      state.isInventorySuccess = true;
      state.isInventoryLoading = false;
    });
    builder.addCase(getInventories.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isInventorySuccess = false;
      state.isInventoryLoading = false;
    });

    //get Inventory History
    builder.addCase(getInventoryHistories.pending, (state: IState, action: any) => {
      state.isInventoryHistoryLoading = true
    });
    builder.addCase(getInventoryHistories.fulfilled, (state: IState, action: any) => {
      state.inventoryHistories = action.payload.data;
      state.isInventoryHistorySuccess = true;
      state.isInventoryHistoryLoading = false;
    });
    builder.addCase(getInventoryHistories.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isInventoryHistorySuccess = false;
      state.isInventoryHistoryLoading = false;
    });


    //get history configs
    builder.addCase(getHistoryConfigCPM.pending, (state: IState, action: any) => {
      state.isHistoryConfigLoading = true
    });
    builder.addCase(getHistoryConfigCPM.fulfilled, (state: IState, action: any) => {
      state.historyConfigs = action.payload.data;
      state.isHistoryConfigSuccess = true;
      state.isHistoryConfigLoading = false;
    });
    builder.addCase(getHistoryConfigCPM.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isHistoryConfigSuccess = false;
      state.isHistoryConfigLoading = false;
    });


    //get Credentials
    builder.addCase(getCredentials.pending, (state: IState, action: any) => {
      state.isCredentialLoading = true
    });
    builder.addCase(getCredentials.fulfilled, (state: IState, action: any) => {
      state.credentials = action.payload.data;
      state.isCredentialSuccess = true;
      state.isCredentialLoading = false;
    });
    builder.addCase(getCredentials.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCredentialSuccess = false;
      state.isCredentialLoading = false;
    });


    //get Agencies
    builder.addCase(getAgencies.pending, (state: IState, action: any) => {
      state.isAgencyLoading = true
    });
    builder.addCase(getAgencies.fulfilled, (state: IState, action: any) => {
      state.agencies = action.payload.data;
      state.isAgencySuccess = true;
      state.isAgencyLoading = false;
    });
    builder.addCase(getAgencies.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAgencySuccess = false;
      state.isAgencyLoading = false;
    });


    //get Advertiser Agencies
    builder.addCase(getAdvertiserAgencies.pending, (state: IState, action: any) => {
      state.isAdvertiserAgencyLoading = true
    });
    builder.addCase(getAdvertiserAgencies.fulfilled, (state: IState, action: any) => {
      state.advertiserAgencies = action.payload.data;
      state.isAdvertiserAgencySuccess = true;
      state.isAdvertiserAgencyLoading = false;
    });
    builder.addCase(getAdvertiserAgencies.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAdvertiserAgencySuccess = false;
      state.isAdvertiserAgencyLoading = false;
    });

    //get advertisers
    builder.addCase(getAdvertisers.pending, (state: IState, action: any) => {
      state.isAdvertiserLoading = true
    });
    builder.addCase(getAdvertisers.fulfilled, (state: IState, action: any) => {
      state.advertisers = action.payload.data;
      state.isAdvertiserSuccess = true;
      state.isAdvertiserLoading = false;
    });
    builder.addCase(getAdvertisers.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAdvertiserSuccess = false;
      state.isAdvertiserLoading = false;
    });

    //get history config AdvertiserCPC s
    builder.addCase(getHistoryConfigAdvertiserCPC.pending, (state: IState, action: any) => {
      state.isHistoryConfigAdvertiserCPCLoading = true
    });
    builder.addCase(getHistoryConfigAdvertiserCPC.fulfilled, (state: IState, action: any) => {
      state.historyConfigAdvertiserCPCs = action.payload.data;
      state.isHistoryConfigAdvertiserCPCSuccess = true;
      state.isHistoryConfigAdvertiserCPCLoading = false;
    });
    builder.addCase(getHistoryConfigAdvertiserCPC.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isHistoryConfigAdvertiserCPCSuccess = false;
      state.isHistoryConfigAdvertiserCPCLoading = false;
    });

    //get advertising
    builder.addCase(getAdvertising.pending, (state: IState, action: any) => {
      state.isAdvertisingLoading = true
    });
    builder.addCase(getAdvertising.fulfilled, (state: IState, action: any) => {
      state.advertising = action.payload.data;
      state.isAdvertisingSuccess = true;
      state.isAdvertisingLoading = false;
    });
    builder.addCase(getAdvertising.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAdvertisingSuccess = false;
      state.isAdvertisingLoading = false;
    });


    //get ADPlatforms
    builder.addCase(getADPlatforms.pending, (state: IState, action: any) => {
      state.isADPlatformLoading = true
    });
    builder.addCase(getADPlatforms.fulfilled, (state: IState, action: any) => {
      state.ADPlatforms = action.payload.data;
      state.isADPlatformSuccess = true;
      state.isADPlatformLoading = false;
    });
    builder.addCase(getADPlatforms.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isADPlatformSuccess = false;
      state.isADPlatformLoading = false;
    });

    //get SSPMedias
    builder.addCase(getSSPMedias.pending, (state: IState, action: any) => {
      state.isSSPMediaLoading = true
    });
    builder.addCase(getSSPMedias.fulfilled, (state: IState, action: any) => {
      state.SSPMedias = action.payload.data;
      state.isSSPMediaSuccess = true;
      state.isSSPMediaLoading = false;
    });
    builder.addCase(getSSPMedias.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSSPMediaSuccess = false;
      state.isSSPMediaLoading = false;
    });


    //get Coupangs
    builder.addCase(getCoupangs.pending, (state: IState, action: any) => {
      state.isCoupangLoading = true
    });
    builder.addCase(getCoupangs.fulfilled, (state: IState, action: any) => {
      state.coupangs = action.payload.data;
      state.isCoupangSuccess = true;
      state.isCoupangLoading = false;
    });
    builder.addCase(getCoupangs.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCoupangSuccess = false;
      state.isCoupangLoading = false;
    });

  },
});

export default ManagementSlice.reducer;