import { Option } from "api/types/_public";
import { ISSPMedia } from "api/types/_ssp";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownMultiOption from "components/Common/DropdownMultiOption";
import DropdownOption from "components/Common/DropdownOption";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { TYPE_SSP, TYPE_SSP_OPTIONS } from "helpers/constans";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Col,
    Input,
    Row,
    Spinner
} from "reactstrap";
import { getSSPMedia, postSSPMedia, putSSPMedia } from "store/thunks";
import * as Yup from "yup";


export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: ISSPMedia | null;
    mediaIds?: { label: string; value: string; type: string }[];
    triggerRefresh?: () => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);


const FormSSPMedia = ({
    detail,
    mediaIds = [],
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();

    const TYPE_SSP_OPTIONS_LANG = TYPE_SSP_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [options, setOptions] = useState<Option[]>(mediaIds?.filter((_m: any) => _m?.type === TYPE_SSP.INTERWORK));

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                name: values?.name || '',
                ssp: values?.ssp?.value || TYPE_SSP_OPTIONS_LANG[0]?.value,
                media_ids: (values?.media_ids || [])?.map((_m: any) => Number(_m?.value)),
            };
            const response: any = detail?.id ? await putSSPMedia(detail?.id, data) : await postSSPMedia(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().required(`${t("This is required")}`),
        ssp: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
        media_ids: Yup.array()
            .of(
                Yup.object().shape({
                    value: Yup.string(),
                    label: Yup.string(),
                })
            ).required(`${t("This is required")}`)
            .min(1, `${t("At least 1 media ID is required")}`),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            ssp: null,
            media_ids: null
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const v_name = valueDefault?.name;
        const v_ssp = TYPE_SSP_OPTIONS_LANG?.find((_type) => String(_type?.value) === String(valueDefault?.ssp)) || TYPE_SSP_OPTIONS_LANG[0];

        const _options = mediaIds?.filter((_m: any) => _m?.type === v_ssp?.value) || [];
        const v_media_ids = _options?.filter((_o: any) => (valueDefault?.media_ids || [])?.includes(Number(_o?.value)));

        formik.setFieldValue("name", v_name);
        formik.setFieldValue("ssp", v_ssp);
        setTimeout(() => {
            formik.setFieldValue("media_ids", v_media_ids);
        }, 300);
    };

    useEffect(() => {
        if (mediaIds) {
            const _options = mediaIds?.filter((_m: any) => _m?.type === (formik?.values?.ssp as any)?.value) || [];
            setOptions((_prev) => _options);
            formik.setFieldValue("media_ids", []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(formik?.values?.ssp)]);

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            const getDetail = async () => {
                try {
                    const response: any = await getSSPMedia(detail?.id);
                    if (response?.code === 200) {
                        handleSetValueForm(response?.data || detail);
                    } else {
                        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
                    }
                } catch (error: any) {
                    toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
                    return error;
                }
            }
            getDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Media Name")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        autocomplete={false}
                                                        value={formik?.values?.name}
                                                        placeholder={`${t("Media Name")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("name", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.name && formik.errors.name ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("SSP")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownOption
                                                        name="ssp"
                                                        dataList={TYPE_SSP_OPTIONS_LANG || []}
                                                        placeholder={`${t("SSP")}...`}
                                                        className="dropdown-status-rounded form-ph"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={formik?.values?.ssp || TYPE_SSP_OPTIONS_LANG[0]}
                                                        onChangeSelect={(event: any) => {
                                                            formik.setFieldValue("ssp", event);
                                                        }
                                                        }
                                                    />
                                                    {formik.touched.ssp && formik.errors.ssp ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.ssp}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} lg={12} className="mt-3">
                                                <div>
                                                    <label className="form-label">
                                                        {((formik?.values?.ssp as any)?.value === TYPE_SSP.DABLE) ? t('Service ID') : t("Media ID")} <span className="text-muted">({t('multiple')})</span> <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownMultiOption
                                                        name=""
                                                        dataList={options}
                                                        placeholder={((formik?.values?.ssp as any)?.value === TYPE_SSP.DABLE) ? t('Service ID') : t("Media ID")}
                                                        className="search-filter-category-type dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={(formik?.values?.media_ids || []) as any}
                                                        onChangeSelect={(e: any) => {
                                                            formik.setFieldValue("media_ids", e);
                                                        }}
                                                        isHasOptionAll={false}
                                                        cacheOptions={true}
                                                    />
                                                    {formik.touched.media_ids && formik.errors.media_ids ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.media_ids}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>
                                                    {!detail?.is_preview && (<button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '170px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update SSP Media") : t("Button Create SSP Media")}</>}
                                                    </button>)}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment >
    );
};

export default FormSSPMedia;
