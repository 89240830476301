import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { ICoupang, ICoupangReport } from "./types/_coupang";
const api = new APIClient();

const path = '/coupangs';
const coupangApi = {
    coupangs(params: any): Promise<AxiosResponse<ResponseData<ICoupang[]> & PaginationResponse, any>> {
        const url = `${path}`
        return api.get(url, params)
    },
    allCoupangs(params: any = {}): Promise<AxiosResponse<ResponseData<ICoupang[]>, any>> {
        const url = `/masters/coupangs`;
        return api.get(url, params)
    },
    getCoupang(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<ICoupang>, any>> {
        const url = `${path}/${id}`;
        return api.get(url, params)
    },
    postCoupang(dataForm: any): Promise<AxiosResponse<ResponseData<ICoupang>, any>> {
        const url = `${path}`;
        return api.post(url, dataForm)
    },
    putCoupang(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ICoupang>, any>> {
        const url = `${path}/${id}`;
        return api.put(url, dataForm)
    },
    deleteCoupang(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ICoupang>, any>> {
        const url = `${path}/${id}`;
        return api.delete(url, dataForm)
    },

    coupangReports(params: any = {}): Promise<AxiosResponse<ResponseData<ICoupangReport>, any>> {
        const url = `/reports/coupang`;
        return api.get(url, params)
    },
    refreshCoupangReports(params: any = {}): Promise<AxiosResponse<ResponseData<ICoupangReport>, any>> {
        const url = `/reports/coupang/refresh`;
        return api.get(url, params)
    },
    coupangNasmediaReports(params: any = {}): Promise<AxiosResponse<ResponseData<ICoupangReport>, any>> {
        const url = `/reports/coupang-nasmob`;
        return api.get(url, params)
    },

}
export default coupangApi
