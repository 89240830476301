import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Import Icons
import { useRole } from "components/Hooks/UserHooks";
import config from "config";
import FeatherIcon from "feather-icons-react";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import { useTranslation } from "react-i18next";
import { ROUTES } from "routes/allRoutes";

const Navdata = () => {
    const history = useNavigate();
    const { t } = useTranslation();
    const { userPermissions } = useRole();

    const [menuActive, setMenuActive] = useState<string>('DASHBOARD');

    // Multi Level
    const [isLevel1, setIsLevel1] = useState<boolean>(false);

    const [isCurrentState, setIsCurrentState] = useState('Dashboard');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }

    const redirectMessageHub = () => {
        const { message_hub_sso_redirect_url } = config
        const accessToken: any = localStorage.getItem("access_token");

        if (message_hub_sso_redirect_url && accessToken) {
            return `${message_hub_sso_redirect_url}?sso_token_newshub=${accessToken}`
        } else {
            return '/#'
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
    }, [history]);

    const menuItems: any = [
        // {
        //     id: "dashboard",
        //     label: t('Dashboards'),
        //     icon: <FeatherIcon icon="home" className="icon-dual" />,
        //     link: ROUTES.DASHBOARD,
        //     click: function (e: any) {
        //         setMenuActive((prev) => 'DASHBOARD');
        //         setIsCurrentState((prev) => 'Dashboard');
        //         updateIconSidebar(e);
        //     },
        //     stateVariables: (menuActive === 'DASHBOARD'),
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.DASHBOARD_VIEW, userPermissions),
        //     // subItems: []
        // },
        {
            id: "multilevel",
            label: t('Ads Management'),
            icon: <FeatherIcon icon="grid" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'ADVERTISING');
                setIsCurrentState((prev) => "Ads Management");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'ADVERTISING'),
            isShow: isHavePermissionRole([
                ROLES_FOR_APP.MANAGEMENT_MEDIA,
                ROLES_FOR_APP.MANAGEMENT_INVENTORY,
                ROLES_FOR_APP.MANAGEMENT_AGENCY,
                ROLES_FOR_APP.MANAGEMENT_ADVERTISER_AGENCY,
                ROLES_FOR_APP.MANAGEMENT_ADVERTISER,
                ROLES_FOR_APP.MANAGEMENT_ADVERTISING,
                ROLES_FOR_APP.MANAGEMENT_COUPANG,
                ROLES_FOR_APP.MANAGEMENT_API,
                ROLES_FOR_APP.MANAGEMENT_PROXY,

            ], userPermissions),
            subItems: [
                {
                    id: "media-management",
                    label: t('Media Management'),
                    link: ROUTES.MANAGEMENT_MEDIA,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_MEDIA, userPermissions),
                },
                {
                    id: "inventory-management",
                    label: t('Inventory Management'),
                    link: ROUTES.MANAGEMENT_INVENTORY,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_INVENTORY, userPermissions),
                },
                {
                    id: "coupang-management",
                    label: t('Coupang Management'),
                    link: ROUTES.MANAGEMENT_COUPANG,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_COUPANG, userPermissions),
                },
                {
                    id: "agency-management",
                    label: t('Media Agency Management'),
                    link: ROUTES.MANAGEMENT_AGENCY,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_AGENCY, userPermissions),
                },
                {
                    id: "advertiser-management",
                    label: t('Advertiser Management'),
                    link: ROUTES.MANAGEMENT_ADVERTISER,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_ADVERTISER, userPermissions),
                },
                {
                    id: "advertising-management",
                    label: t('Advertising Management'),
                    link: ROUTES.MANAGEMENT_ADVERTISING,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_ADVERTISING, userPermissions),
                },
                {
                    id: "advertiser-agency-management",
                    label: t('Advertiser Agency Management'),
                    link: ROUTES.MANAGEMENT_ADVERTISER_AGENCY,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_ADVERTISER_AGENCY, userPermissions),
                },
                {
                    id: "api-management",
                    label: t('API Management'),
                    link: ROUTES.MANAGEMENT_API,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_API, userPermissions),
                },
                {
                    id: "admin-management-proxy",
                    label: t("Proxy Management"),
                    link: ROUTES.MANAGEMENT_PROXY,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_PROXY, userPermissions),
                },
            ],
        },
        {
            id: "multilevel2",
            label: t('Reports'),
            icon: <FeatherIcon icon="clipboard" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'REPORT');
                setIsCurrentState((prev) => "Report");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'REPORT'),
            isShow: isHavePermissionRole([
                ROLES_FOR_APP.REPORT_MEDIA,
                ROLES_FOR_APP.REPORT_MEDIA_SALES,
                ROLES_FOR_APP.REPORT_INVENTORY,
                ROLES_FOR_APP.REPORT_AD_PLATFORM,
                ROLES_FOR_APP.REPORT_AD_PLATFORM_SALES,
                ROLES_FOR_APP.REPORT_AD_TYPE,
                ROLES_FOR_APP.REPORT_AGENCY,
                ROLES_FOR_APP.REPORT_COMMISSION,
                ROLES_FOR_APP.REPORT_REVENUE,
                ROLES_FOR_APP.REPORT_SETTLEMENT,
                ROLES_FOR_APP.REPORT_COUPANG,
                ROLES_FOR_APP.REPORT_ADVERTISER,
                ROLES_FOR_APP.REPORT_ADVERTISING,
            ], userPermissions),
            subItems: [
                {
                    id: "report-media",
                    label: t('Report Media'),
                    link: ROUTES.REPORT_MEDIA,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_MEDIA, userPermissions),
                },
                {
                    id: "report-media-sale",
                    label: t('Report Media (Sales)'),
                    link: ROUTES.REPORT_MEDIA_SALES,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_MEDIA_SALES, userPermissions),
                },
                {
                    id: "report-inventory",
                    label: t('Report Inventory'),
                    link: ROUTES.REPORT_INVENTORY,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_INVENTORY, userPermissions),
                },
                {
                    id: "report-ad-platform",
                    label: t('Report AD Platform'),
                    link: ROUTES.REPORT_AD_PLATFORM,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_AD_PLATFORM, userPermissions),
                },
                {
                    id: "report-ad-platform",
                    label: t('Report AD Platform (Sales)'),
                    link: ROUTES.REPORT_AD_PLATFORM_SALES,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_AD_PLATFORM_SALES, userPermissions),
                },
                {
                    id: "report-ad-type",
                    label: t('Report AD Type'),
                    link: ROUTES.REPORT_AD_TYPE,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_AD_TYPE, userPermissions),
                },
                {
                    id: "report-agency",
                    label: t('Report Agency'),
                    link: ROUTES.REPORT_AGENCY,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_AGENCY, userPermissions),
                },
                {
                    id: "report-advertiser",
                    label: t('Report Advertiser'),
                    link: ROUTES.REPORT_ADVERTISER,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_ADVERTISER, userPermissions),
                },
                {
                    id: "report-advertising",
                    label: t('Report Advertising'),
                    link: ROUTES.REPORT_ADVERTISING,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_ADVERTISING, userPermissions),
                },
                {
                    id: "report-commission",
                    label: t('Report Commission'),
                    link: ROUTES.REPORT_COMMISSION,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_COMMISSION, userPermissions),
                },
                {
                    id: "report-revenue",
                    label: t('Report Revenue'),
                    link: ROUTES.REPORT_REVENUE,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_REVENUE, userPermissions),
                },
                {
                    id: "settlement-report",
                    label: t('Settlement Report'),
                    link: ROUTES.REPORT_SETTLEMENT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_SETTLEMENT, userPermissions),
                },
                {
                    id: "report-coupang",
                    label: t('Report Coupang'),
                    link: ROUTES.REPORT_COUPANG,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_COUPANG, userPermissions),
                },
            ],
        },
        {
            id: "multilevel5",
            label: t("Statistics"),
            icon: <FeatherIcon icon="bar-chart-2" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "STATISTICS");
                setIsCurrentState((prev) => "Statistics");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "STATISTICS",
            isShow: isHavePermissionRole(
                [
                    ROLES_FOR_APP.ADS_LOG,
                    ROLES_FOR_APP.IP_STATISTICS,
                    ROLES_FOR_APP.UUID_STATISTICS,
                    ROLES_FOR_APP.REFERRAL_STATISTICS,
                    ROLES_FOR_APP.PATH_STATISTICS,
                    ROLES_FOR_APP.MEDIA_STATISTICS,
                    ROLES_FOR_APP.MEDIA_IP_STATISTICS,
                    ROLES_FOR_APP.MEDIA_HOURLY_STATISTICS,
                    ROLES_FOR_APP.DAILY_STATISTICS,
                    ROLES_FOR_APP.HOURLY_STATISTICS,
                    ROLES_FOR_APP.KEYWORD_STATISTICS,
                    ROLES_FOR_APP.DAILY_IMPRESSION_STATISTICS
                ],
                userPermissions
            ),
            subItems: [
                {
                    id: "ads-log",
                    label: t('Ads Log'),
                    link: ROUTES.ADS_LOG,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.ADS_LOG, userPermissions),
                },
                {
                    id: "ip-statistics",
                    label: t('IP Statistics'),
                    link: ROUTES.IP_STATISTICS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.IP_STATISTICS, userPermissions),
                },
                {
                    id: "uuid-statistics",
                    label: t('UUID Statistics'),
                    link: ROUTES.UUID_STATISTICS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.UUID_STATISTICS, userPermissions),
                },
                {
                    id: "referrer-statistics",
                    label: t('Referrer Statistics'),
                    link: ROUTES.REFERRAL_STATISTICS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REFERRAL_STATISTICS, userPermissions),
                },
                {
                    id: "path-statistics",
                    label: t('Path Statistics'),
                    link: ROUTES.PATH_STATISTICS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.PATH_STATISTICS, userPermissions),
                },
                {
                    id: "media-statistics",
                    label: t('Media Statistics'),
                    link: ROUTES.MEDIA_STATISTICS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MEDIA_STATISTICS, userPermissions),
                },
                {
                    id: "media-ip-statistics",
                    label: t('Media IP Statistics'),
                    link: ROUTES.MEDIA_IP_STATISTICS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MEDIA_IP_STATISTICS, userPermissions),
                },
                {
                    id: "media-hourly-statistics",
                    label: t('Media Hourly Statistics'),
                    link: ROUTES.MEDIA_HOURLY_STATISTICS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MEDIA_HOURLY_STATISTICS, userPermissions),
                },
                {
                    id: "keyword-statistics",
                    label: t('Keyword Statistics'),
                    link: ROUTES.KEYWORD_STATISTICS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.KEYWORD_STATISTICS, userPermissions),
                },
                {
                    id: "daily-statistics",
                    label: t('Daily Statistics'),
                    link: ROUTES.DAILY_STATISTICS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.DAILY_STATISTICS, userPermissions),
                },
                {
                    id: "daily-impression-statistics",
                    label: t('Daily Impression Statistics'),
                    link: ROUTES.DAILY_IMPRESSION_STATISTICS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.DAILY_IMPRESSION_STATISTICS, userPermissions),
                },
                {
                    id: "hourly-statistics",
                    label: t('Hourly Statistics'),
                    link: ROUTES.HOURLY_STATISTICS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.HOURLY_STATISTICS, userPermissions),
                },
            ],
        },
        {
            id: "multilevel5",
            label: t("SSP"),
            icon: <FeatherIcon icon="bar-chart-2" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "SSP");
                setIsCurrentState((prev) => "SSP");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "SSP",
            isShow: isHavePermissionRole(
                [
                    ROLES_FOR_APP.MANAGEMENT_SSP_MEDIA,
                    ROLES_FOR_APP.REPORT_INTERWORDS,
                    ROLES_FOR_APP.REPORT_DAILY_INTERWORDS,
                    ROLES_FOR_APP.REPORT_DABLE,
                    ROLES_FOR_APP.REPORT_DAILY_DABLE
                ],
                userPermissions
            ),
            subItems: [
                {
                    id: "ssp-media-management",
                    label: t('SSP Media Management'),
                    link: ROUTES.MANAGEMENT_SSP_MEDIA,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_SSP_MEDIA, userPermissions),
                },
                {
                    id: "report-interworks",
                    label: t('Report Interworks'),
                    link: ROUTES.REPORT_INTERWORDS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_INTERWORDS, userPermissions),
                },

                {
                    id: "report-daily-interworks",
                    label: t('Report Daily Interworks'),
                    link: ROUTES.REPORT_DAILY_INTERWORDS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_DAILY_INTERWORDS, userPermissions),
                },
                {
                    id: "report-dable",
                    label: t('Report Dable'),
                    link: ROUTES.REPORT_DABLE,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_DABLE, userPermissions),
                },
                {
                    id: "report-daily-dable",
                    label: t('Report Daily Dable'),
                    link: ROUTES.REPORT_DAILY_DABLE,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_DAILY_DABLE, userPermissions),
                },
            ],
        },
        {
            id: "multilevel7",
            label: t("Monitor"),
            icon: <FeatherIcon icon="settings" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "MONITOR");
                setIsCurrentState((prev) => "Monitor");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "MONITOR",
            isShow: isHavePermissionRole(
                [
                    ROLES_FOR_APP.MONITOR_SCRIPT,
                    ROLES_FOR_APP.MONITOR_EXCEPT_IP_LIST,
                    ROLES_FOR_APP.MONITOR_BLOCK_IP_LIST,
                    ROLES_FOR_APP.MONITOR_EXCEPT_UUID_LIST,
                    ROLES_FOR_APP.MONITOR_BLOCK_UUID_LIST,
                    ROLES_FOR_APP.MONITOR_CONFIGURATION_LIST
                ],
                userPermissions
            ),
            subItems: [
                {
                    id: "monitor-script",
                    label: t('Monitor Script'),
                    link: ROUTES.MONITOR_SCRIPT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MONITOR_SCRIPT, userPermissions),
                },
                {
                    id: "except-ip",
                    label: t('Except IP'),
                    link: ROUTES.IP_EXCEPT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MONITOR_EXCEPT_IP_LIST, userPermissions),
                },
                {
                    id: "block-ip",
                    label: t('Block IP'),
                    link: ROUTES.IP_BLOCKS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MONITOR_BLOCK_IP_LIST, userPermissions),
                },
                {
                    id: "except-uuid",
                    label: t('Except UUID'),
                    link: ROUTES.UUID_EXCEPT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MONITOR_EXCEPT_UUID_LIST, userPermissions),
                },
                {
                    id: "block-uuid",
                    label: t('Block UUID'),
                    link: ROUTES.UUID_BLOCKS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MONITOR_BLOCK_UUID_LIST, userPermissions),
                },
                {
                    id: "configuration",
                    label: t('Monitor Configuration'),
                    link: ROUTES.MONITOR_CONFIGURATION,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MONITOR_CONFIGURATION_LIST, userPermissions),
                },
            ],
        },
        {
            id: "multilevel6",
            label: t("User"),
            icon: <FeatherIcon icon="users" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "USER");
                setIsCurrentState((prev) => "User");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "USER",
            isShow: isHavePermissionRole([ROLES_FOR_APP.USER_LIST, ROLES_FOR_APP.ROLE_LIST], userPermissions),
            subItems: [
                {
                    id: "user",
                    label: t("User"),
                    link: ROUTES.USER_LIST,
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.USER_LIST,
                        userPermissions
                    ),
                },
                {
                    id: "role-list",
                    label: t("Role"),
                    link: ROUTES.USER_ROLE,
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.ROLE_LIST,
                        userPermissions
                    ),
                },
                {
                    id: "admin-activity-logs",
                    label: t("Admin Activity Logs"),
                    link: ROUTES.ADMIN_ACTIVITY_LOGS,
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.ADMIN_ACTIVITY_LOGS,
                        userPermissions
                    ),
                },
            ],
        },
        {
            id: "multilevel9",
            label: t('Lab'),
            icon: <i className="ri ri-flask-line fs-20" style={{ transform: 'translateX(-1px)' }} />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'LAB');
                setIsCurrentState((prev) => "Lab");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'LAB'),
            isShow: isHavePermissionRole([
                ROLES_FOR_APP.REPORT_MEDIA_V2,
                ROLES_FOR_APP.REPORT_COUPANG_NASMEDIA,
                ROLES_FOR_APP.MANAGEMENT_AD_PLATFORM,
                ROLES_FOR_APP.REPORT_AD_PLATFORM_MEDIA,
                ROLES_FOR_APP.REPORT_CLICK,
                ROLES_FOR_APP.REPORT_ADVERTISER_AGENCY,
            ], userPermissions),
            subItems: [
                {
                    id: "report-media-v2",
                    label: t('Report Media v2'),
                    link: ROUTES.REPORT_MEDIA_V2,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_MEDIA_V2, userPermissions),
                },
                {
                    id: "report-coupang-nasmedia",
                    label: t('Report Coupang (nasmedia)'),
                    link: ROUTES.REPORT_COUPANG_NASMEDIA,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_COUPANG_NASMEDIA, userPermissions),
                },
                {
                    id: "ad-platform-management",
                    label: t('AD Platform Management'),
                    link: ROUTES.MANAGEMENT_AD_PLATFORM,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_AD_PLATFORM, userPermissions),
                },
                {
                    id: "report-ad-platform",
                    label: t('Report AD Platform (Media)'),
                    link: ROUTES.REPORT_AD_PLATFORM_MEDIA,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_AD_PLATFORM_MEDIA, userPermissions),
                },
                {
                    id: "report-click",
                    label: t('Report Click'),
                    link: ROUTES.REPORT_CLICK,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_CLICK, userPermissions),
                },
                {
                    id: "report-advertiser-agency",
                    label: t('Report Advertiser Agency'),
                    link: ROUTES.REPORT_ADVERTISER_AGENCY,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.REPORT_ADVERTISER_AGENCY, userPermissions),
                },
            ],
        },
        // {
        //     id: "logout",
        //     label: "Logout",
        //     icon: <FeatherIcon icon="log-out" className="icon-log-out" />,
        //     link: "/logout",
        //     stateVariables: isDashboard,
        //     click: function (e: any) {
        //         e.preventDefault();
        //         setIsDashboard(!isDashboard);
        //         setIsCurrentState( (prev) =>  'Dashboard');
        //         updateIconSidebar(e);
        //     },
        // },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
