import { ICoupang } from "api/types/_coupang";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Col,
    Input,
    Row,
    Spinner
} from "reactstrap";
import { getCoupang, postCoupang, putCoupang } from "store/thunks";
import * as Yup from "yup";


export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: ICoupang | null;
    triggerRefresh?: () => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);


const FormCoupang = ({
    detail,
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                name: values?.name || '',
                tracking_code: values?.tracking_code || '',
                ad_id: values?.ad_id || '',
                sub_id: values?.sub_id || '',
            };
            const response: any = detail?.id ? await putCoupang(detail?.id, data) : await postCoupang(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().required(`${t("This is required")}`),
        tracking_code: Yup.string().required(`${t("This is required")}`),
        ad_id: Yup.string().required(`${t("This is required")}`),
        sub_id: Yup.string().required(`${t("This is required")}`),

    });

    const formik = useFormik({
        initialValues: {
            name: '',
            tracking_code: '',
            ad_id: '',
            sub_id: ''
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const v_name = valueDefault?.name;
        const v_tracking_code = valueDefault?.tracking_code;
        const v_ad_id = valueDefault?.ad_id;
        const v_sub_id = valueDefault?.sub_id;

        formik.setFieldValue("name", v_name);
        formik.setFieldValue("tracking_code", v_tracking_code);
        formik.setFieldValue("ad_id", v_ad_id);
        formik.setFieldValue("sub_id", v_sub_id);
    };

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            const getDetail = async () => {
                try {
                    const response: any = await getCoupang(detail?.id);
                    if (response?.code === 200) {
                        handleSetValueForm(response?.data || detail);
                    } else {
                        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
                    }
                } catch (error: any) {
                    toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
                    return error;
                }
            }
            getDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Name")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        autocomplete={false}
                                                        value={formik?.values?.name}
                                                        placeholder={`${t("Name")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("name", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.name && formik.errors.name ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Tracking Code")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="tracking_code"
                                                        name="tracking_code"
                                                        autocomplete={false}
                                                        value={formik?.values?.tracking_code}
                                                        placeholder={`${t("Tracking Code")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("tracking_code", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.tracking_code && formik.errors.tracking_code ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.tracking_code}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Ad ID")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="ad_id"
                                                        name="ad_id"
                                                        autocomplete={false}
                                                        value={formik?.values?.ad_id}
                                                        placeholder={`${t("Ad ID")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("ad_id", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.ad_id && formik.errors.ad_id ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.ad_id}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Sub ID")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="sub_id"
                                                        name="sub_id"
                                                        autocomplete={false}
                                                        value={formik?.values?.sub_id}
                                                        placeholder={`${t("Sub ID")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("sub_id", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.sub_id && formik.errors.sub_id ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.sub_id}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>
                                                    {!detail?.is_preview && (<button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '150px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update Coupang") : t("Button Create Coupang")}</>}
                                                    </button>)}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment >
    );
};

export default FormCoupang;
