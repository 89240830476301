import { IMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";
import DropdownMedia from "components/Common/DropdownMedia";
import DropdownOption from "components/Common/DropdownOption";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { AD_TYPE_OPTIONS } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { downloadExcelKeywordStatistics, getAllMedias, keywordStatistics as onGetKeywordStatistics } from "../../../store/thunks";


const SORT_BY_DEFAULT = 'pv';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const KeywordStatistic = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const AD_TYPE_OPTIONS_LANG = AD_TYPE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 100),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        media_id: withDefault(StringParam, ''),
        ad_type: withDefault(StringParam, 'request'),
        keyword: withDefault(StringParam, ''),
        start_date: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),
        end_date: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Statistic;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            keywordStatistics: state.keywordStatistics,
            isDKeywordSuccess: state.isDKeywordSuccess,
            isKeywordStatisticLoading: state.isKeywordStatisticLoading,
            error: state.error,
        })
    );

    const { keywordStatistics, isKeywordStatisticLoading } = useSelector(ManagementProperties);
    const [isCallAllOptionLoading, setIsCallAllOptionLoading] = useState<boolean>(true);

    const [dateSearch, setDateSearch] = useState<string>(query?.start_date || "");

    const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

    const [listMedia, setListMedia] = useState<IMedia[]>([]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>({
        label: listMedia?.filter((item) => String(item?.id || '') === String(query?.media_id || ''))[0]?.name || t('All Media'),
        value: `${listMedia?.filter((item) => String(item?.id || '') === String(query?.media_id || ''))[0]?.id || ''}`
    });

    const [isLoadingExportFile, setIsLoadingExportFile] = useState<boolean>(false);

    useEffect(() => {
        dispatch(onGetKeywordStatistics(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            start_date: dateSearch || '',
            end_date: dateSearch || '',
            media_id: mediaSearch?.value || '',
            ad_type: 'request',
            keyword: keywordSearch || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            start_date: moment(new Date()).format("Y-MM-DD"),
            end_date: moment(new Date()).format("Y-MM-DD"),
            media_id: '',
            ad_type: 'request',
            keyword: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setDateSearch(moment(new Date()).format("Y-MM-DD"));
        setMediaSearch({ label: t('All Media'), value: '' });
        setKeywordSearch('');
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Ranking'),
                accessor: "rank",
                filterable: true,
                sortable: false,
                thClass: 'align-middle text-start',
                thWidth: 90,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-start" style={{ minWidth: '60px' }}>{((query?.page - 1) * (query?.limit)) + (cell?.cell?.row?.index + 1)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Keyword'),
                accessor: "keyword",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-start',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-start" style={{ minWidth: '100px' }}>{cell?.value || ''}</div>
                        </>
                    )
                },
            },
            {
                Header: t('PV'),
                accessor: "pv",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 90,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '80px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: '',
                accessor: "__",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 90,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '80px' }}></div>
                        </>
                    )
                },
            },
            {
                Header: 'UV',
                accessor: "uv",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 90,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '80px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: '',
                accessor: "___",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 90,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '80px' }}></div>
                        </>
                    )
                },
            },
        ],
        [i18n?.language, userPermissions]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (value: Date | null) => {
        setDateSearch((_prev) => value ? moment(new Date(value)).format("Y-MM-DD") : '');
    }

    const handleDownloadExcel = async () => {
        try {
            setIsLoadingExportFile((_prev) => true);
            const response: any = await downloadExcelKeywordStatistics({ ...query, is_export: 1 });
            if (response?.data) {
                setIsLoadingExportFile((_prev) => false);
                const link = document.createElement('a');
                link.href = response?.data;
                link.download = 'keyword_statistics';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                setIsLoadingExportFile((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoadingExportFile((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                setIsCallAllOptionLoading((_prev) => true);
                const [resMediaLog]: any = await Promise.all([getAllMedias()]);
                setIsCallAllOptionLoading((_prev) => false);
                const list = ([{ name: t('All Media'), id: '' } as any]).concat(resMediaLog?.data || []);
                setListMedia((_prev) => list);
                const media = list?.find((item) => String(item?.id || '') === String(query?.media_id || ''));
                setMediaSearch((_prev) => ({
                    label: media?.name || t('All Media'),
                    value: `${media?.id || ''}`
                }));
            } catch (error: any) {
                setIsCallAllOptionLoading((_prev) => false);
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        if (mediaSearch) {
            setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
            (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
        }
        document.title = `${t("Keyword Statistics")} - ${t("Statistics")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Keyword Statistics')} pageTitle={t('Statistics')} />
                    {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_STATISTICS, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    {/* <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('This data is analyzed per 10 mins')}</div> */}
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-5 col-sm-6">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={keywordStatistics?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-7 col-sm-6 text-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-success"
                                                    onClick={handleDownloadExcel}
                                                    disabled={isLoadingExportFile}
                                                >
                                                    {isLoadingExportFile ? <Spinner size="sm" ></Spinner> : <i className="ri-download-line align-bottom"></i>}
                                                    <span className="ms-1">{t('Button Download Excel')}</span>
                                                </button>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={6} md={6} xl={3} xxl={3} className='mt-3 mt-xl-2'>
                                                <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                                    <DropdownMedia
                                                        name="medias"
                                                        dataList={listMedia}
                                                        initialValue={mediaSearch}
                                                        onChangeSelect={(event: any) => {
                                                            setMediaSearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>

                                            <Col sm={6} md={6} xl={3} xxl={3} className='mt-3 mt-xl-2'>
                                                <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Keyword')}...`}
                                                        value={keywordSearch}
                                                        onChange={(event: any) => {
                                                            const input = event?.target?.value;
                                                            setKeywordSearch(input)
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={6} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-xl-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                                                    <DatePicker
                                                        className="form-control search"
                                                        placeholderText={`${t('Date')}...`}
                                                        value={dateSearch || ''}
                                                        dateFormat="yyyy-MM-dd"
                                                        isClearable={true}
                                                        locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                                                        onChange={handleChangePicker}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={6} xl={3} xxl={3} className="hstack gap-1 justify-content-center justify-content-sm-end justify-content-xl-start mt-3 mt-xl-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={(isKeywordStatisticLoading || isCallAllOptionLoading)}
                                                    >
                                                        {(isKeywordStatisticLoading || isCallAllOptionLoading) ? <div className="me-1" style={{ display: 'inline-table', verticalAlign: 'top' }}><Spinner size="sm" style={{ width: '14px', height: '14px' }}></Spinner></div> : <i className="ri-search-line align-bottom me-1"></i>}{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={keywordStatistics?.list?.length ? keywordStatistics?.list : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={keywordStatistics?.pagination?.total}
                                            customPageCount={Math.ceil(Number(keywordStatistics?.pagination?.total) / Number(keywordStatistics?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isKeywordStatisticLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default KeywordStatistic;
