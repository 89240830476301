import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { ISSPMedia } from "./types/_ssp";

const api = new APIClient();

const path = '/ssp-medias';
const sspApi = {
  SSPMedias(params: any): Promise<AxiosResponse<ResponseData<ISSPMedia[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  allSSPMedias(params: any = {}): Promise<AxiosResponse<ResponseData<ISSPMedia[]>, any>> {
    const url = `/masters/ssp-medias`;
    return api.get(url, params)
  },
  allSSPIdMedias(params: any = {}): Promise<AxiosResponse<ResponseData<ISSPMedia[]>, any>> {
    const url = `/ssp-logs-medias`;
    return api.get(url, params)
  },
  getSSPMedia(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<ISSPMedia>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postSSPMedia(dataForm: any): Promise<AxiosResponse<ResponseData<ISSPMedia>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putSSPMedia(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ISSPMedia>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteSSPMedia(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ISSPMedia>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
}
export default sspApi
