import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IIP, IKeyword, IMediaIPStatistic, IMediaStatistic, IPath, IReferrer, IUUID } from "./types/_statistic";
const api = new APIClient();

const path = '/';
const statisticApi = {
  ipStatistics(params: any): Promise<AxiosResponse<ResponseData<IIP[]> & PaginationResponse, any>> {
    const url = `${path}ip-statistics`
    return api.get(url, params)
  },
  uuidStatistics(params: any): Promise<AxiosResponse<ResponseData<IUUID[]> & PaginationResponse, any>> {
    const url = `${path}uuid-statistics`
    return api.get(url, params)
  },
  referrerStatistics(params: any): Promise<AxiosResponse<ResponseData<IReferrer[]> & PaginationResponse, any>> {
    const url = `${path}statistics/referrals`
    return api.get(url, params)
  },
  mediaStatistics(params: any): Promise<AxiosResponse<ResponseData<IMediaStatistic[]> & PaginationResponse, any>> {
    const url = `${path}reports/media`
    return api.get(url, params)
  },
  pathStatistics(params: any): Promise<AxiosResponse<ResponseData<IPath[]> & PaginationResponse, any>> {
    const url = `${path}statistics/urls`
    return api.get(url, params)
  },
  mediaIPStatistics(params: any): Promise<AxiosResponse<ResponseData<IMediaIPStatistic[]> & PaginationResponse, any>> {
    const url = `${path}media-ip-statistics`
    return api.get(url, params)
  },
  keywordStatistics(params: any): Promise<AxiosResponse<ResponseData<IKeyword[]> & PaginationResponse, any>> {
    const url = `${path}keyword-statistics`
    return api.get(url, params)
  },
  uuidBlocks(params: any): Promise<AxiosResponse<ResponseData<IUUID[]> & PaginationResponse, any>> {
    const url = `${path}uuid-blocks`
    return api.get(url, params)
  },
  uuidExcepts(params: any): Promise<AxiosResponse<ResponseData<IUUID[]> & PaginationResponse, any>> {
    const url = `${path}uuid-excepts`
    return api.get(url, params)
  },
  ipBlocks(params: any): Promise<AxiosResponse<ResponseData<IIP[]> & PaginationResponse, any>> {
    const url = `${path}ip-blocks`
    return api.get(url, params)
  },
  ipExcepts(params: any): Promise<AxiosResponse<ResponseData<IIP[]> & PaginationResponse, any>> {
    const url = `${path}ip-excepts`
    return api.get(url, params)
  },
  postLookupIP(params: any = {}): Promise<AxiosResponse<ResponseData<IIP[]> & PaginationResponse, any>> {
    const url = `${path}utilities/ip/lookup`
    return api.post(url, params)
  },
  putStatusBlockIP(ip: string | number, dataForm: any = {}): Promise<AxiosResponse<ResponseData<IIP[]> & PaginationResponse, any>> {
    const url = `${path}ip-statistics/${ip}/block`
    return api.post(url, dataForm)
  },
  postBlockMultiplesUUID(dataForm: any = {}): Promise<AxiosResponse<ResponseData<IUUID[]> & PaginationResponse, any>> {
    const url = `${path}uuid-blocks`
    return api.post(url, dataForm)
  },
  deleteBlockMultiplesUUID(dataForm: any = {}): Promise<AxiosResponse<ResponseData<IUUID[]> & PaginationResponse, any>> {
    const url = `${path}uuid-blocks`
    return api.delete(url, { data: dataForm })
  },
  postExceptMultiplesUUID(dataForm: any = {}): Promise<AxiosResponse<ResponseData<IUUID[]> & PaginationResponse, any>> {
    const url = `${path}uuid-excepts`
    return api.post(url, dataForm)
  },
  deleteExceptMultiplesUUID(dataForm: any = {}): Promise<AxiosResponse<ResponseData<IUUID[]> & PaginationResponse, any>> {
    const url = `${path}uuid-excepts`
    return api.delete(url, { data: dataForm })
  },

  postBlockMultiplesIP(dataForm: any = {}): Promise<AxiosResponse<ResponseData<IIP[]> & PaginationResponse, any>> {
    const url = `${path}ip-blocks`
    return api.post(url, dataForm)
  },
  deleteBlockMultiplesIP(dataForm: any = {}): Promise<AxiosResponse<ResponseData<IIP[]> & PaginationResponse, any>> {
    const url = `${path}ip-blocks`
    return api.delete(url, { data: dataForm })
  },
  postExceptMultiplesIP(dataForm: any = {}): Promise<AxiosResponse<ResponseData<IIP[]> & PaginationResponse, any>> {
    const url = `${path}ip-excepts`
    return api.post(url, dataForm)
  },
  deleteExceptMultiplesIP(dataForm: any = {}): Promise<AxiosResponse<ResponseData<IIP[]> & PaginationResponse, any>> {
    const url = `${path}ip-excepts`
    return api.delete(url, { data: dataForm })
  },
}
export default statisticApi
