import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import ModalConfirm from 'components/Common/ModalConfirm';
import TooltipCustom from 'components/Common/TooltipCustom';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { formatNumberWithCommas } from 'helpers/format';
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row, Spinner, Table } from "reactstrap";
import { addConfigCPM, deleteConfigCPM, getConfigCPMs, updateConfigCPM } from "../../../../store/thunks";
import History from './History';
import { IConfigCPM } from 'api/types/_inventory';
import CopyWrapper from 'components/Common/CopyWrapper';
import { AD_PLATFORM_INVENTORY_OPTIONS, AD_TYPE_INVENTORY_IS_FIXED_BANNER, CPM_TYPE, POSITION_INVENTORY_OPTIONS } from 'helpers/constans';
import SVGImageDefault from 'components/Images/SVGImageDefault';

registerLocale('en', en);
registerLocale('ko', ko);

const getDefaultEndDate = (startDate: Date) => {
  if (!startDate) return null;
  const endDate = new Date(startDate);
  endDate.setFullYear(endDate.getFullYear() + 100);
  return endDate;
};

const ConfigInventoryCPM = ({ inventoryId = '', inventory = {}, handleCloseModal, handleActionRefresh }: any) => {
  const { t, i18n } = useTranslation();

  const [query, setQuery] = useState<any>({
    time_request: + new Date(),
    inventory_id: inventoryId,
    page: 1,
    limit: 30,
    type: CPM_TYPE.INVENTORY
  });

  const POSITION_INVENTORY_OPTIONS_LANG = POSITION_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const AD_PLATFORM_INVENTORY_OPTIONS_LANG = AD_PLATFORM_INVENTORY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const [inventoryConfigCPM, setInventoryConfigCPM] = useState<any>({});
  const [inventoryIdShowHistory, setInventoryIdShowHistory] = useState<any>(null);

  const [valueRate, setValueRate] = useState<any>({});
  const [idInventoryLoading, setIdInventoryLoading] = useState<number | null>(null);


  const [valueRateAdd, setValueRateAdd] = useState<any>({});
  const [addConfigLoading, setAddConfigLoading] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [idConfirmDelete, setIdConfirmDelete] = useState<number | null>(null);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  const tableRef = useRef<any>(null);

  const freshData = async () => {
    try {
      setIsLoading((_prev) => true);
      const response: any = await getConfigCPMs(query);
      setIsLoading((_prev) => false);
      if (response?.code === 200) {
        setInventoryConfigCPM((prev: any) => response?.data || {});
        setValueRate((prev: any) => {
          return response?.data?.list?.reduce((ob: any, item: IConfigCPM) => ({
            ...ob,
            [`cell-${item?.id}`]: {
              cpm: item?.cpm,
              effective_from: item?.effective_from ? moment(item?.effective_from || "", 'Y-MM-DD').toDate() : '',
              effective_to: item?.effective_to ? moment(item?.effective_to || "", 'Y-MM-DD').toDate() : '',
            }
          }), {});
        });
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  useEffect(() => {
    if (!!inventoryId) {
      freshData();
    }
  }, [JSON.stringify(query)]);

  // Begin::Update Ads Account
  const handleActionUpdateAdsAccount = async (item: IConfigCPM) => {
    if (!(item?.id)) { return; };
    try {
      setIdInventoryLoading((_prev: any) => item?.id);
      const response: any = await updateConfigCPM(item?.id, {
        inventory_id: inventoryId,
        cpm: Number(valueRate[`cell-${item?.id}`]?.cpm),
        effective_from: valueRate[`cell-${item?.id}`]?.effective_from ? moment(new Date(valueRate[`cell-${item?.id}`]?.effective_from)).format("Y-MM-DD") : '',
        effective_to: valueRate[`cell-${item?.id}`]?.effective_to ? moment(new Date(valueRate[`cell-${item?.id}`]?.effective_to)).format("Y-MM-DD") : '',
        type: CPM_TYPE.INVENTORY
      });
      if (response?.code === 200) {
        setQuery((_prev: any) => ({ ..._prev, time_request: + new Date() }));
        setIdInventoryLoading((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        handleActionRefresh && handleActionRefresh();
      } else {
        setIdInventoryLoading((_prev) => null);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIdInventoryLoading((_prev) => null);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleActionAddAdsAccount = async () => {
    if (!(inventoryId)) { return; };
    try {
      setAddConfigLoading((_prev: any) => true);
      const response: any = await addConfigCPM({
        inventory_id: inventoryId,
        cpm: Number(valueRateAdd?.cpm),
        effective_from: valueRateAdd?.effective_from ? moment(new Date(valueRateAdd?.effective_from)).format("Y-MM-DD") : '',
        effective_to: valueRateAdd?.effective_to ? moment(new Date(valueRateAdd?.effective_to)).format("Y-MM-DD") : '',
        type: CPM_TYPE.INVENTORY
      });
      if (response?.code === 200) {
        setQuery((_prev: any) => ({ ..._prev, time_request: + new Date() }));
        setValueRateAdd((prev: any) => ({}));
        setAddConfigLoading((_prev) => false);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        handleActionRefresh && handleActionRefresh();
      } else {
        setAddConfigLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setAddConfigLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleActionDelete = async () => {
    if (!(idConfirmDelete)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteConfigCPM(idConfirmDelete);
      if (response?.code === 200) {
        setQuery((_prev: any) => ({ ..._prev, time_request: + new Date() }));
        setIsConfirmLoading((_prev) => false);
        setIdConfirmDelete((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        handleActionRefresh && handleActionRefresh();
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setIdConfirmDelete((_prev) => null);
  }

  // End::Update Ads Account

  const handleScrollToTop = () => {
    tableRef.current.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleChangePage = useCallback((page: any) => {
    setQuery((_prev: any) => ({ ..._prev, page: page + 1 }));
  }, []);

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card id="leadsList">
          <CardBody className="pt-3">
            <div id="scrollableDiv" ref={tableRef} className={`color-scrollbar-os`} style={{ height: 'calc(100dvh - 280px)', margin: 0, overflowY: 'auto' }}
            >
              <h6 className="text-center text-primary">{t('Inventory CPM')}</h6>
              <div className="table-responsive" style={{ minHeight: '450px' }}>
                <Table className="caption-top table-bordered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col" colSpan={3} className="text-center bold align-middle" style={{ borderRight: '0px solid #fff' }}>
                        <div className="d-flex align-items-center text-start">
                          <div className="flex-shrink-0 me-2 position-relative">
                            <SVGImageDefault style={{ height: '48px' }} />
                            {!!inventory?.parent_code ? <span className="position-absolute text-primary fs-14 text-uppercase" style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)', width: '100%', textAlign: 'center' }}>{t('Sub')}</span> : <></>}
                          </div>
                          <div>
                            <div className="ms-2">{inventory?.name || ''}</div>
                            <div className="ms-2 fs-12 text-muted">{(AD_PLATFORM_INVENTORY_OPTIONS_LANG?.find((op: any) => String(op?.value) === String(inventory?.ad_platform)) || AD_PLATFORM_INVENTORY_OPTIONS_LANG[0])?.label}</div>
                            <strong className="text-muted ms-2">{inventory?.attributes?.width || inventory?.width} * {inventory?.attributes?.height || inventory?.height}</strong>
                            {(String(inventory?.ad_type) === AD_TYPE_INVENTORY_IS_FIXED_BANNER) ? <></> : <TooltipCustom
                              title={`(${t('Position')}, ${t('Margin Left')}, ${t('Margin Right')}, ${t('Margin Bottom')})`}
                              className="d-inline-block vertical-align-middle"
                            >
                              <div className="ms-2 fs-12 text-muted">({POSITION_INVENTORY_OPTIONS_LANG?.find((p) => p?.value === inventory?.attributes?.position)?.label || ''}, {String(inventory?.attributes?.margin_left || '').replace(/px$/, '')}, {String(inventory?.attributes?.margin_right || '').replace(/px$/, '')}, {String(inventory?.attributes?.margin_bottom || '').replace(/px$/, '')})</div>
                            </TooltipCustom>}
                          </div>
                        </div>
                      </th>
                      <th className="align-middle">
                        <Link className={`m-auto btn btn-sm btn-secondary edit-item-btn d-flex align-items-center justify-content-center`}
                          to="#"
                          style={{ height: '30px', width: '85px' }}
                          onClick={(e) => { e.preventDefault(); setInventoryIdShowHistory((prev: any) => (inventoryId)) }}
                        >
                          {t('Button History')}
                        </Link>
                      </th>
                    </tr>
                    <tr>
                      <th scope="col" className="text-center bold">#</th>
                      <th scope="col" className="text-center bold">{t('CPM')}
                        <TooltipCustom
                          title={t('Cost per 1000 impressions')}
                          className="d-inline-block vertical-align-middle ms-1"
                          style={{ transform: 'translateY(2px)' }}
                        >
                          <i className="ri-question-line align-bottom text-secondary" ></i>
                        </TooltipCustom>
                      </th>
                      <th scope="col" className="text-center bold">{t('Effective Date')}</th>
                      <th scope="col" className="text-center bold">{t('Action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key='000' style={{ backgroundColor: '#eaeaea6e' }}>
                      <td className="align-middle text-center h6 ">
                        <div className="w-100 text-center" >
                          *
                        </div>
                      </td>
                      <td className={`align-middle text-center h6`}>
                        <input
                          className="form-control text-center"
                          id={`cell-000-system_revenue_rate`}
                          name={`cell-000-system_revenue_rate`}
                          value={formatNumberWithCommas(valueRateAdd?.cpm ?? '')}
                          placeholder={`${t('CPM')}...`}
                          onChange={(event: any) => {
                            const input = event.target.value;
                            const onlyNumbers = input.replace(/\D/g, '');
                            const s = Number(onlyNumbers);
                            setValueRateAdd((prev: any) => ({
                              ...prev,
                              cpm: s,
                            }));
                          }}
                        />
                      </td>
                      <td className="align-middle">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="date-picker-wrapper-custom" style={{ width: '100%' }}>
                            <DatePicker
                              dateFormat="yyyy-MM-dd"
                              locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                              className="form-control search "
                              placeholderText={t('Effective from')}
                              value={valueRateAdd?.effective_from || undefined}
                              selected={valueRateAdd?.effective_from ? new Date(valueRateAdd?.effective_from) : undefined}
                              // minDate={new Date()}
                              onChange={(event: any) => {
                                setValueRateAdd((prev: any) => ({
                                  ...prev,
                                  effective_from: event,
                                  effective_to: getDefaultEndDate(event)
                                }));
                                const key = `${(Object.keys(valueRate)[0] as any)}`;
                                if (!!key) {
                                  setValueRate((prev: any) => ({
                                    ...prev,
                                    [key]: {
                                      ...prev[key],
                                      effective_from: moment(prev[key]?.effective_from).format('Y-MM-DD'),
                                      effective_to: moment(event).subtract(1, 'days')
                                    }
                                  }));
                                }
                              }}
                            />
                          </div>
                          <div style={{ width: '60px' }} className="text-center">
                            {`>`}
                          </div>
                          <div className="date-picker-wrapper-custom" style={{ width: '100%' }}>
                            <DatePicker
                              dateFormat="yyyy-MM-dd"
                              locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                              className="form-control search "
                              placeholderText={t('Effective from')}
                              value={valueRateAdd?.effective_to || undefined}
                              selected={valueRateAdd?.effective_to ? new Date(valueRateAdd?.effective_to) : undefined}
                              minDate={valueRateAdd?.effective_from || undefined}
                              onChange={(event: any) => {
                                setValueRateAdd((prev: any) => ({
                                  ...prev,
                                  effective_to: event
                                }));
                              }}
                            />
                          </div>
                        </div>
                      </td>
                      <td className={`align-middle text-center h6 text-danger`} style={{ width: '100px' }}>
                        <Link className={`m-auto btn btn-sm btn-warning edit-item-btn d-flex align-items-center justify-content-center ${(addConfigLoading || (valueRateAdd?.cpm === undefined || !valueRateAdd?.effective_from || !valueRateAdd?.effective_to)) ? 'disabled' : ''}`}
                          to="#"
                          style={{ height: '36px', width: '85px' }}
                          onClick={(e) => { e.preventDefault(); handleActionAddAdsAccount() }}
                        >
                          {(addConfigLoading) ? <Spinner size="sm"></Spinner> : <span>{t('Button Create')}</span>}
                        </Link>
                      </td>
                    </tr>
                    {inventoryConfigCPM?.list?.length === 0 && (
                      <tr>
                        <td colSpan={5} className="text-center">
                          {t('No Data')}
                        </td>
                      </tr>
                    )}
                    {inventoryConfigCPM?.list?.map((item: IConfigCPM, index: number) => (
                      <tr key={item?.id} style={item?.is_effective ? { backgroundColor: 'rgba(37, 160, 226, 0.2)' } : {}}>
                        <td className="align-middle text-center h6 position-relative">
                          <div className="w-100 text-center" >
                            {((query?.page - 1) * (query?.limit)) + (index + 1)}
                          </div>
                          {item?.is_effective && <span className="text-primary position-absolute " style={{ top: '10px', left: '10px' }}>{t('Applying')}</span>}
                        </td>
                        <td className={`align-middle text-center h6`}>
                          <input
                            className="form-control text-center"
                            id={`cell-${item?.id}-cpm`}
                            name={`cell-${item?.id}-cpm`}
                            value={formatNumberWithCommas(valueRate[`cell-${item?.id}`]?.cpm)}
                            placeholder={`${t('System Revenue Rate')}...`}
                            onChange={(event: any) => {
                              const input = event.target.value;
                              const onlyNumbers = input.replace(/\D/g, '');
                              const s = Number(onlyNumbers);
                              setValueRate({
                                ...valueRate,
                                [`cell-${item?.id}`]: {
                                  ...valueRate[`cell-${item?.id}`],
                                  cpm: s,
                                }
                              });
                            }}
                          />
                        </td>
                        <td className="align-middle">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="date-picker-wrapper-custom" style={{ width: '100%' }}>
                              <DatePicker
                                dateFormat="yyyy-MM-dd"
                                locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                                className="form-control search "
                                placeholderText={t('Effective from')}
                                value={valueRate[`cell-${item?.id}`]?.effective_from || undefined}
                                selected={valueRate[`cell-${item?.id}`]?.effective_from ? new Date(valueRate[`cell-${item?.id}`]?.effective_from) : undefined}
                                // minDate={new Date()}
                                onChange={(event: any) => {
                                  setValueRate({
                                    ...valueRate,
                                    [`cell-${item?.id}`]: {
                                      ...valueRate[`cell-${item?.id}`],
                                      effective_from: event,
                                      effective_to: getDefaultEndDate(event)
                                    }
                                  });
                                }}
                              />
                            </div>
                            <div style={{ width: '60px' }} className="text-center">
                              {`>`}
                            </div>
                            <div className="date-picker-wrapper-custom" style={{ width: '100%' }}>
                              <DatePicker
                                dateFormat="yyyy-MM-dd"
                                locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                                className="form-control search "
                                placeholderText={t('Effective to')}
                                value={valueRate[`cell-${item?.id}`]?.effective_to || undefined}
                                selected={valueRate[`cell-${item?.id}`]?.effective_to ? new Date(valueRate[`cell-${item?.id}`]?.effective_to) : undefined}
                                minDate={valueRate[`cell-${item?.id}`]?.effective_from || undefined}
                                onChange={(event: any) => {
                                  setValueRate({
                                    ...valueRate,
                                    [`cell-${item?.id}`]: {
                                      ...valueRate[`cell-${item?.id}`],
                                      effective_to: event
                                    }
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td className={`align-middle text-center h6 text-danger`} style={{ width: '100px' }}>
                          <Link className={`m-auto btn btn-sm btn-soft-success edit-item-btn d-flex align-items-center justify-content-center ${(idInventoryLoading === item?.id) ? 'disabled' : ''}`}
                            to="#"
                            style={{ height: '30px', width: '85px' }}
                            onClick={(e) => { e.preventDefault(); handleActionUpdateAdsAccount(item) }}
                          >
                            {(idInventoryLoading === item?.id) ? <Spinner size="sm"></Spinner> : <span>{t('Button Update')}</span>}
                          </Link>
                          <Link className={`m-auto btn mt-2 btn-sm btn-soft-danger edit-item-btn d-flex align-items-center justify-content-center ${(idInventoryLoading === item?.id) ? 'disabled' : ''}`}
                            to="#"
                            style={{ height: '30px', width: '85px' }}
                            onClick={(e) => { e.preventDefault(); setIdConfirmDelete((prev: any) => (item?.id)) }}
                          >
                            {t('Button Delete')}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
            <Row>
              <Col md={10} xxl={10} lg={10} className="table-pagination-modal">
                <div className={`infinite-loading-outer ${isLoading && 'is-show-loading'}`}>
                  <div className="infinite-loading-inner"></div>
                </div>
                <div className="d-flex align-items-start gap-3 mt-3">
                  <div className="align-items-center g-3 text-center">
                    <div className="col-sm-auto">
                      <ReactPaginate
                        nextLabel={`${t('next')} >`}
                        onPageChange={(page) => {
                          if (handleChangePage) {
                            handleScrollToTop && handleScrollToTop();
                            handleChangePage(page.selected);
                          }
                        }}
                        forcePage={query.page - 1}
                        pageRangeDisplayed={Number(window?.innerWidth) < 900 ? 2 : 3}
                        marginPagesDisplayed={2}
                        pageCount={Math.ceil(Number(inventoryConfigCPM?.pagination?.total) / Number(inventoryConfigCPM?.pagination?.limit))}
                        previousLabel={`< ${t('previous')}`}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link ms-0"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination pagination-separated"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                    <div className="col-sm text-start mt-2">
                      <div className="text-muted">
                        {t('Total')}:
                        {" "}
                        <span className="fw-semibold ms-1">{formatNumberWithCommas(inventoryConfigCPM?.pagination?.total)}</span>
                        {" "}
                        {t('Results')}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={2} xxl={2} lg={2} className='mt-4'>
                <div className="hstack gap-2 justify-content-end">
                  <button className="btn btn-soft-secondary fs-14" color="light" type="button" onClick={() => handleCloseModal && handleCloseModal()}>
                    <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                    {t('Button Close')}
                  </button>
                </div>
              </Col>
            </Row>
            <ToastContainer closeButton={false} limit={1} />
          </CardBody>
        </Card>
      </Col>
      <Modal isOpen={!!inventoryIdShowHistory} centered={true} scrollable={true} size="lg">
        <ModalHeader toggle={() => setInventoryIdShowHistory((prev: any) => (prev ? null : prev))}>
          {t('History Config CPM')}
        </ModalHeader>
        <ModalBody className="py-3 pt-0 px-4">
          <div className="table-responsive" style={{ minHeight: '450px' }}>
            <History inventoryId={inventoryId} inventory={inventory} />
          </div>
          <div className="col-lg-12 mt-4">
            <div className="hstack gap-2 justify-content-end">
              <button className="btn btn-soft-secondary fs-14" color="light" type="button" onClick={() => setInventoryIdShowHistory(null)}>
                <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                {t('Button Close')}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ModalConfirm
        header={t('Button Delete Config CPM')}
        isOpen={!!idConfirmDelete}
        isLoading={isConfirmLoading}
        onClose={onCloseConfirmClick}
        onConfirm={handleActionDelete}
      />
    </React.Fragment >
  );
};

export default ConfigInventoryCPM;
