import { Option } from "api/types/_public";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownOption from "components/Common/DropdownOption";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { ARR_INDEX_TEXT_DAY_OF_WEEK, TYPE_SSP } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getAllSSPMedias, reportDailyInterworks as onGetReportDailyInterworks } from "../../../store/thunks";


const SORT_BY_DEFAULT = 'date';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const ReportDailyInterwork = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 100),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        media_log_id: withDefault(StringParam, ''),
        media_id: withDefault(StringParam, ''),
        // media_name: withDefault(StringParam, ''),
        start_date: withDefault(StringParam, moment().subtract(30, 'days').format("Y-MM-DD")),
        end_date: withDefault(StringParam, moment().format("Y-MM-DD")),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Report;

    const ReportProperties = createSelector(
        selectLayoutState,
        (state) => ({
            dailyInterworks: state.dailyInterworks,
            isDailyInterworkSuccess: state.isDailyInterworkSuccess,
            isDailyInterworkLoading: state.isDailyInterworkLoading,
            error: state.error,
        })
    );

    const { dailyInterworks, isDailyInterworkLoading } = useSelector(ReportProperties);

    const [SSPMediaOptions, setSSPMediaOptions] = useState<Option[]>([]);

    const [mediaLogIdSearch, setMediaLogIdSearch] = useState<Option | null>(query?.media_log_id ? { value: query?.media_log_id, label: '' } : null);

    const [mediaNameSearch, setMediaNameSearch] = useState<string>(query?.media_name || "");

    const [mediaIdSearch, setMediaIdSearch] = useState<string>(query?.media_id || "");

    const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date, 'Y-MM-DD').toDate() : null, query?.end_date ? moment(query?.end_date || '', 'Y-MM-DD').toDate() : null]);
    const [startDate, endDate] = dateSearch;


    useEffect(() => {
        dispatch(onGetReportDailyInterworks(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            media_log_id: mediaLogIdSearch?.value || '',
            media_name: mediaNameSearch || '',
            media_id: mediaIdSearch || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            start_date: `${moment().subtract(30, 'days').format("Y-MM-DD")}`,
            end_date: `${moment().format("Y-MM-DD")}`,
            media_log_id: '',
            media_name: '',
            media_id: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
        setMediaLogIdSearch(null);
        setMediaNameSearch('');
        setMediaIdSearch('');
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Date'),
                accessor: "date",
                filterable: true,
                sortable: true,
                thWidth: 110,
                thClass: 'align-middle text-start',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const arrDate = String(cell?.value || '').split(' ');
                    const num = moment(cell?.value).days(); // 0 is Sunday , 1 is Monday, ... , 6 is Saturday
                    const classText = num === 0 ? 'text-danger row-bg-danger' : (num === 6 ? 'text-primary row-bg-primary' : '');
                    const textNote = t(`STANDS_${ARR_INDEX_TEXT_DAY_OF_WEEK[num]}`);
                    return (
                        !!item?.text_row ? <strong>{item?.text_row} </strong> :
                            <div className="text-start" style={{ minWidth: '120px' }}>
                                <span className={classText}>{moment(arrDate?.[0] || '', 'Y-MM-DD').format("MM-DD")} ({textNote})</span>
                            </div>

                    )
                },
            },
            // {
            //     Header: t('Media ID'),
            //     accessor: "media_log_id",
            //     filterable: true,
            //     sortable: false,
            //     thClass: 'align-middle text-start',
            //     thWidth: 100,
            //     Cell: (cell: any) => {
            //         const item = cell?.row?.original;
            //         return (
            //             <>
            //                 {!!item?.text_row ? <></> : <div className="text-start" style={{ minWidth: '80px' }}>{cell?.value}</div>}
            //             </>
            //         )
            //     },
            // },
            // {
            //     Header: t('Media Name'),
            //     accessor: "media_name",
            //     filterable: true,
            //     sortable: false,
            //     thClass: 'align-middle text-start',
            //     thWidth: 200,
            //     Cell: (cell: any) => {
            //         const item = cell?.row?.original;
            //         return (
            //             <>
            //                 {!!item?.text_row ? <strong>{item?.text_row} </strong> : <div className="text-start" style={{ minWidth: '180px' }}>{cell?.value}</div>}
            //             </>
            //         )
            //     },
            // },
            {
                Header: t('Sum Request'),
                accessor: "request",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 120,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Sum Impression'),
                accessor: "impression",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 120,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Fill-Rate'),
                accessor: "fill_rate",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 120,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Sum Click'),
                accessor: "click",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 120,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('CTR'),
                accessor: "ctr",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 120,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)} %</div>
                        </>
                    )
                },
            },
            {
                Header: t('Passback'),
                accessor: "passback",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 120,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Media Amount'),
                accessor: "media_amount",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '130px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('CPM'),
                accessor: "cpm",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 120,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('eCPM'),
                accessor: "ecpm",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 120,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3 " style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
        ],
        [i18n?.language, userPermissions]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                const [resSSPMedia]: any = await Promise.all([getAllSSPMedias()]);
                const _options = (resSSPMedia?.data || [])?.filter((_ssp: any) => String(_ssp?.ssp) === TYPE_SSP.INTERWORK)?.map((_s: any) => ({ value: `${_s?.id}`, label: _s?.name }));
                setSSPMediaOptions((_prev) => _options);

                if (query?.media_log_id) {
                    const _option = _options?.find((_o: any) => String(_o?.value) === String(query?.media_log_id)) || null;
                    setMediaLogIdSearch((_prev: any) => _option);
                }


            } catch (error: any) {
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        document.title = `${t("Report Daily Interworks")} - ${t("SSP")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Report Daily Interworks')} pageTitle={t('SSP')} />
                    {isHavePermissionRole(ROLES_FOR_APP.REPORT_INTERWORDS, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('This data is analyzed per 10 mins')}</div>
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={dailyInterworks?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={6} md={6} xl={2} xxl={2} className='mt-3 mt-xl-2'>
                                                <LabelWrapper label={t('Media')} isShow={!!mediaLogIdSearch}>
                                                    <DropdownOption
                                                        name="media"
                                                        dataList={SSPMediaOptions || []}
                                                        placeholder={`${t("Media")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={mediaLogIdSearch || null}
                                                        onChangeSelect={(e: any) => setMediaLogIdSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={6} xl={2} xxl={2} className='mt-3 mt-xl-2'>
                                                <LabelWrapper label={t('Media ID')} isShow={!!mediaIdSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Media ID')}...`}
                                                        value={mediaIdSearch}
                                                        onChange={(event: any) => {
                                                            const input = event?.target?.value;
                                                            setMediaIdSearch(input)
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={6} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-xl-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDate || null}
                                                        endDate={endDate || null}
                                                        onChangePicker={handleChangePicker}
                                                        showOptions={[
                                                            'today', 'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={6} xl={3} xxl={3} className="hstack gap-1 justify-content-center justify-content-sm-end justify-content-xl-between mt-3 mt-xl-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isDailyInterworkLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed table-have-first-row-is-total sticky-table-report-daily-interwork"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={dailyInterworks?.list?.length ? (
                                                [
                                                    {
                                                        ...dailyInterworks?.total,
                                                        text_row: t('Sum')
                                                    },
                                                    ...dailyInterworks?.list]
                                            ) : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={dailyInterworks?.pagination?.total}
                                            customPageCount={Math.ceil(Number(dailyInterworks?.pagination?.total) / Number(dailyInterworks?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isDailyInterworkLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment >
    );
};

export default ReportDailyInterwork;
