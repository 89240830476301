import { IAdvertising } from "api/types/_advertising";
import { IInventory } from "api/types/_inventory";
import { IMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownOption from "components/Common/DropdownOption";
import DropdownStatus from "components/Common/DropdownStatus";
import Hashtag from "components/Common/Hashtag";
import TooltipCustom from "components/Common/TooltipCustom";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import {
    AD_PLATFORM_INVENTORY_OPTIONS,
    AD_PLATFORM_INVENTORY_TYPE_ADWISER,
    AD_PLATFORM_INVENTORY_TYPE_ADWISER_URL,
    AD_PLATFORM_INVENTORY_TYPE_COUPANG,
    AD_PLATFORM_INVENTORY_TYPE_COUPANG_NASMEDIA,
    AD_PLATFORM_INVENTORY_TYPE_COUPANG_NASMEDIA_KEYWORD,
    AD_PLATFORM_INVENTORY_TYPE_DIGITAL_CAMP,
    AD_PLATFORM_INVENTORY_TYPE_DIGITAL_CAMP_SCRIPT,
    AD_PLATFORM_INVENTORY_TYPE_GMARKET_NASMEDIA,
    AD_PLATFORM_INVENTORY_TYPE_SELF_ADVERTISING,
    AD_PLATFORM_INVENTORY_TYPE_TPMN,
    AD_TYPE_INVENTORY_OPTIONS,
    BANNER_TYPE_INVENTORY_OPTIONS,
    BANNER_TYPE_INVENTORY_SLIDER_BANNER_V2,
    BUTTON_POSITION_INVENTORY_OPTIONS,
    DEVICE_DISPLAY_ADS_OPTIONS,
    DEVICE_DISPLAY_ADS_TYPE,
    FILL_RATE_TIME_OPTIONS,
    IMPRESSION_INVENTORY_OPTIONS,
    INTEGRATION_METHOD_OPTIONS,
    OPERATING_OPTIONS,
    OS_DISPLAY_ADS_OPTIONS,
    POSITION_INVENTORY_OPTIONS,
    SLIDE_BANNER_RATIO_TYPE_INVENTORY_OPTIONS,
    STATUS_INVENTORY_OPTIONS,
    SUPPORTED_FORMATS,
    TYPE_BANNER_ADVERTISING
} from "helpers/constans";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Input, Label, Row, Spinner } from "reactstrap";
import { getInventory, postInventory, postUploadImageCoverInventory, putInventory } from "store/thunks";
import * as Yup from "yup";
// Import React FilePond
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import { FilePond } from 'react-filepond';
import { ICoupang } from "api/types/_coupang";
import { IAgency } from "api/types/_agency";

export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: IInventory | null;
    medias?: IMedia[];
    agencies?: IAgency[]
    inventories?: IInventory[];
    advertising?: IAdvertising[];
    coupangs?: ICoupang[];
    triggerRefresh?: () => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FormInventory = ({
    detail,
    medias = [],
    agencies = [],
    inventories = [],
    advertising = [],
    coupangs = [],
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [filesCover, setFilesCover] = useState<any>([]);

    const [imagesCoverPreview, setImagesCoverPreview] = useState<any>([]);

    const AGENCY_OPTIONS_LANG = (agencies?.map((item) => ({ label: item?.name, value: String(item?.code) })) || []) as Option[];

    const MEDIA_OPTIONS_LANG = medias?.reduce(
        (arr: any, item: any) =>
            item?.code
                ? [...arr, { value: String(item?.code), label: item?.name }]
                : arr,
        []
    );

    const INVENTORY_OPTIONS_LANG = inventories?.reduce(
        (arr: any, item: any) => {
            if ((item?.parent_code === null) || (item?.parent_code === detail?.parent_code)) {
                return [...arr, { value: String(item?.code), label: `${item?.media?.name} (${item?.width}*${item?.height})` }];
            }
            return arr;
        },
        [{ value: '', label: t('None') }]
    );

    const ADVERTISING_OPTIONS_LANG = advertising?.reduce(
        (arr: any, item: any) => {
            return [...arr, { value: String(item?.id), label: item?.title }];
        },
        [{ value: '', label: t('All') }]
    );

    const COUPANG_OPTIONS_LANG = coupangs?.reduce(
        (arr: any, item: any) => {
            return [...arr, { value: String(item?.id), label: item?.name }];
        },
        [{ value: '', label: t('None') }]
    );

    const STATUS_INVENTORY_OPTIONS_LANG = STATUS_INVENTORY_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const POSITION_INVENTORY_OPTIONS_LANG = POSITION_INVENTORY_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );


    const IMPRESSION_INVENTORY_OPTIONS_LANG = IMPRESSION_INVENTORY_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const FILL_RATE_TIME_OPTIONS_LANG = FILL_RATE_TIME_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const OPERATING_OPTIONS_LANG = OPERATING_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: item?.label })
    );

    const DEVICE_DISPLAY_ADS_OPTIONS_LANG = DEVICE_DISPLAY_ADS_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: item?.label })
    );

    const OS_DISPLAY_ADS_OPTIONS_LANG = OS_DISPLAY_ADS_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const AD_PLATFORM_INVENTORY_OPTIONS_LANG = AD_PLATFORM_INVENTORY_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const AD_TYPE_INVENTORY_OPTIONS_LANG = AD_TYPE_INVENTORY_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const BANNER_TYPE_INVENTORY_OPTIONS_LANG = BANNER_TYPE_INVENTORY_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const SLIDE_BANNER_RATIO_TYPE_INVENTORY_OPTIONS_LANG = SLIDE_BANNER_RATIO_TYPE_INVENTORY_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const BUTTON_POSITION_INVENTORY_OPTIONS_LANG = BUTTON_POSITION_INVENTORY_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const INTEGRATION_METHOD_OPTIONS_LANG = INTEGRATION_METHOD_OPTIONS?.map(
        (item: any) => ({ value: item?.value, label: t(item?.label) })
    );

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);

            const data = {
                name: values?.name || '',
                media_code: Number(values?.media_code?.value),
                agency_code: Number(values?.agency_code?.value),
                // coupang_code: Number(values?.coupang_code?.value),
                parent_code: !!values?.parent_code?.value ? Number(values?.parent_code?.value) : null, // SUB INVENTORY
                ad_platform: values?.ad_platform ? Number(values?.ad_platform?.value) : AD_PLATFORM_INVENTORY_OPTIONS_LANG[0]?.value, // 1, 2 SUB INVENTORY
                ad_type: values?.ad_type ? Number(values?.ad_type?.value) : AD_TYPE_INVENTORY_OPTIONS_LANG[0]?.value, // SUB INVENTORY
                banner_type: Number(values?.banner_type?.value || BANNER_TYPE_INVENTORY_OPTIONS_LANG[0]?.value), // Independent Banner
                ad_id: values?.ad_id,
                ad_tracking_code: values?.ad_tracking_code || '',
                ad_sub_id: values?.ad_sub_id || '',
                adwiser_code: values?.adwiser_code || '',
                installation_date: values?.installation_date ? moment(new Date(values?.installation_date || '')).format("Y-MM-DD HH:mm") : '',
                advertising_ids: values?.advertising_id?.value ? [Number(values?.advertising_id?.value)] : null,
                status: Number(values?.status ? values?.status?.value : STATUS_INVENTORY_OPTIONS_LANG[0]?.value), // 0 | 1,
                fill_rate_time: Number(values?.fill_rate_time ? values?.fill_rate_time?.value : FILL_RATE_TIME_OPTIONS_LANG[0]?.value),
                configs: {
                    flg_click: Number(values?.flg_click),
                    impression_option: Number(values?.impression_option),
                    flg_banner_size: Number(values?.flg_banner_size),
                    fill_rate: Number(values?.fill_rate),
                    is_block_referral: Number(values?.is_block_referral),
                    block_internal: Number(values?.block_internal),
                    automatic_click: Number(values?.automatic_click),
                    popup_automatic_click: Number(values?.popup_automatic_click),
                    is_except_keyword: Number(values?.is_except_keyword ?? 1),
                    target_ctr: Number(values?.target_ctr || 0),
                    hidden_ads_count: Number(values?.hidden_ads_count || 0),
                    delay_seconds: Number(values?.delay_seconds || 0),
                    hide_button: Number(values?.hide_button ?? 0),
                    replace_button: Number(values?.replace_button ?? 0),
                    except_general_visitors: Number(values?.except_general_visitors ?? 0),
                    automatic_redirect: Number(values?.automatic_redirect),
                    referral_block_ad: Number(values?.referral_block_ad ?? 0),
                    limit_ads: Number(values?.limit_ads || 0),

                },
                attributes: {
                    position: values?.position ? values?.position?.value : POSITION_INVENTORY_OPTIONS_LANG[0]?.value, // left | right
                    impression: values?.impression ? values?.impression?.value : IMPRESSION_INVENTORY_OPTIONS_LANG[0]?.value, // directly | scrolling
                    margin_bottom: `${values?.margin_bottom}px`,
                    margin_left: `${values?.margin_left}px`,
                    margin_right: `${values?.margin_right}px`,
                    target_id: `${values?.target_id || ''}`,
                    width: `${values?.width}`,
                    height: `${values?.height}`,
                    width_1: `${values?.width_1}`,
                    height_1: `${values?.height_1}`,
                    replace_button_style: `${values?.replace_button_style || ''}`,
                    button_position: values?.button_position ? values?.button_position?.value : BUTTON_POSITION_INVENTORY_OPTIONS_LANG[0]?.value, // left | right
                    button_style: `${values?.button_style}`,
                    button_margin_left: `${values?.button_margin_left || 0}px`,
                    button_margin_right: `${values?.button_margin_right || 0}px`,
                    button_margin_bottom: `${values?.button_margin_bottom || ''}px`,
                    button_margin_top: `${values?.button_margin_top || ''}px`,
                    passback_url: values?.passback_url || '',
                    click_operating_time_start: values?.click_operating_time_start ? values?.click_operating_time_start?.value : OPERATING_OPTIONS_LANG[0]?.value,
                    click_operating_time_end: values?.click_operating_time_end ? values?.click_operating_time_end?.value : OPERATING_OPTIONS_LANG[5]?.value,
                    click_operating_probability: Number(values?.click_operating_probability || 0),
                    popup_operating_time_start: values?.popup_operating_time_start ? values?.popup_operating_time_start?.value : OPERATING_OPTIONS_LANG[0]?.value,
                    popup_operating_time_end: values?.popup_operating_time_end ? values?.popup_operating_time_end?.value : OPERATING_OPTIONS_LANG[5]?.value,
                    popup_operating_probability: Number(values?.popup_operating_probability || 0),
                    popup_automatic_delay_time: Number(values?.popup_automatic_delay_time || 0),
                    popup_click_url: values?.popup_click_url || '',
                    retention_hour: Number(values?.retention_hour || 0),
                    operating_time_start: values?.operating_time_start ? values?.operating_time_start?.value : OPERATING_OPTIONS_LANG[0]?.value,
                    operating_time_end: values?.operating_time_end ? values?.operating_time_end?.value : OPERATING_OPTIONS_LANG[5]?.value,
                    operating_probability: Number(values?.operating_probability || 0),
                    exclusion_time: values?.exclusion_time ? Number(values?.exclusion_time?.value) : Number(FILL_RATE_TIME_OPTIONS_LANG[23]?.value),
                    automatic_delay_time: Number(values?.automatic_delay_time || 0),
                    layer_click_url: values?.layer_click_url || '',
                    integration_method: values?.integration_method ? values?.integration_method?.value : INTEGRATION_METHOD_OPTIONS_LANG[0]?.value,

                    redirect_operating_time_start: values?.redirect_operating_time_start ? values?.redirect_operating_time_start?.value : OPERATING_OPTIONS_LANG[0]?.value,
                    redirect_operating_time_end: values?.redirect_operating_time_end ? values?.redirect_operating_time_end?.value : OPERATING_OPTIONS_LANG[5]?.value,
                    redirect_operating_probability: Number(values?.redirect_operating_probability || 0),
                    redirect_automatic_delay_time: Number(values?.redirect_automatic_delay_time || 0),
                    redirect_automatic_url: values?.redirect_automatic_url || '',

                    referral_block_keyword: (values?.referral_block_keyword || [])?.map((k: { id: string, text: string }) => k?.id),
                    cover_image_url: values?.cover_image_old || null,
                    // cover_click_url: values?.cover_click_url || '',

                    ad_display_time_start: values?.ad_display_time_start ? values?.ad_display_time_start?.value : OPERATING_OPTIONS_LANG[0]?.value,
                    ad_display_time_end: values?.ad_display_time_end ? values?.ad_display_time_end?.value : OPERATING_OPTIONS_LANG[24]?.value,

                    // slide_banner_ratio: Number(values?.slide_banner_ratio?.value ?? SLIDE_BANNER_RATIO_TYPE_INVENTORY_OPTIONS_LANG[0]?.value),

                    target_device: values?.target_device ? values?.target_device?.value : DEVICE_DISPLAY_ADS_OPTIONS_LANG[0]?.value,
                    target_mobile: values?.target_mobile ? values?.target_mobile?.value : OS_DISPLAY_ADS_OPTIONS_LANG[0]?.value,

                    adwiser_url: values?.adwiser_url || '',
                },
            };
            if (!!values?.cover_image && [String(TYPE_BANNER_ADVERTISING.SLIDE_BANNER), String(TYPE_BANNER_ADVERTISING.SLIDE_BANNER_V2)]?.includes(String((values?.banner_type as any)?.value))) {
                const res: any = await postUploadImageCoverInventory({
                    cover_image: values?.cover_image ? filesCover[0]?.file : '',
                });
                data.attributes.cover_image_url = res?.data?.[0]?.url || '';
            }
            const response: any = detail?.id
                ? await putInventory(detail?.id, data)
                : await postInventory(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().nullable(),
        media_code: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .required(`${t("This is required")}`),
        agency_code: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }).nullable(),
        coupang_code: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
        parent_code: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
        ad_platform: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .required(`${t("This is required")}`),
        ad_type: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .required(`${t("This is required")}`),
        banner_type: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }).test(
                'banner-type-validation',
                function (value: any) {
                    const { ad_platform, banner_type } = this.parent;
                    if (String(banner_type?.value) === String(TYPE_BANNER_ADVERTISING.SLIDE_BANNER) && String(ad_platform?.value) !== String(AD_PLATFORM_INVENTORY_TYPE_SELF_ADVERTISING)) {
                        return this.createError({ message: `${t("Slider Banners can only be applied to self-advertising!")}` });
                    }
                    if (String(banner_type?.value) === String(TYPE_BANNER_ADVERTISING.SLIDE_BANNER_V2) && ![
                        String(AD_PLATFORM_INVENTORY_TYPE_COUPANG), String(AD_PLATFORM_INVENTORY_TYPE_SELF_ADVERTISING),
                        String(AD_PLATFORM_INVENTORY_TYPE_COUPANG_NASMEDIA)
                    ]?.includes(String(ad_platform?.value))) {
                        return this.createError({ message: `${t("Slider Banners v2 can only be applied to self-advertising, Coupang, and Coupang (Nasmedia)!")}` });
                    }
                    return true;
                }
            ).nullable(),
        slide_banner_ratio: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }).nullable(),
        ad_id: Yup.string().test('is-required', `${t("This is required")}`,
            function (value) {
                const { ad_platform }: any = this.parent;
                if (![AD_PLATFORM_INVENTORY_TYPE_DIGITAL_CAMP, AD_PLATFORM_INVENTORY_TYPE_SELF_ADVERTISING, AD_PLATFORM_INVENTORY_TYPE_COUPANG_NASMEDIA, AD_PLATFORM_INVENTORY_TYPE_COUPANG_NASMEDIA_KEYWORD, AD_PLATFORM_INVENTORY_TYPE_GMARKET_NASMEDIA, AD_PLATFORM_INVENTORY_TYPE_ADWISER, AD_PLATFORM_INVENTORY_TYPE_ADWISER_URL, AD_PLATFORM_INVENTORY_TYPE_TPMN].includes(ad_platform?.value)) {
                    return !!value;
                }
                return true;
            }
        ).nullable(),
        ad_tracking_code: Yup.string().test('is-required', `${t("This is required")}`,
            function (value) {
                const { ad_platform }: any = this.parent;
                if (![AD_PLATFORM_INVENTORY_TYPE_DIGITAL_CAMP, AD_PLATFORM_INVENTORY_TYPE_SELF_ADVERTISING, AD_PLATFORM_INVENTORY_TYPE_COUPANG_NASMEDIA, AD_PLATFORM_INVENTORY_TYPE_COUPANG_NASMEDIA_KEYWORD, AD_PLATFORM_INVENTORY_TYPE_GMARKET_NASMEDIA, AD_PLATFORM_INVENTORY_TYPE_ADWISER, AD_PLATFORM_INVENTORY_TYPE_ADWISER_URL].includes(ad_platform?.value)) {
                    return !!value;
                }
                return true;
            }
        ).nullable(),
        advertising_id: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }).nullable(),
        ad_sub_id: Yup.string().nullable(),
        passback_url: Yup.string().nullable(),
        adwiser_code: Yup.string().test('is-required', `${t("This is required")}`,
            function (value) {
                const { ad_platform }: any = this.parent;
                if ([AD_PLATFORM_INVENTORY_TYPE_ADWISER].includes(ad_platform?.value)) {
                    return !!value;
                }
                return true;
            }
        ).nullable(),
        adwiser_url: Yup.string().test('is-required', `${t("This is required")}`,
            function (value) {
                const { ad_platform }: any = this.parent;
                if ([AD_PLATFORM_INVENTORY_TYPE_ADWISER_URL].includes(ad_platform?.value)) {
                    return !!value;
                }
                return true;
            }
        ).nullable(),
        retention_hour: Yup.string().nullable(),
        width: Yup.string().required(`${t("This is required")}`),
        height: Yup.string().required(`${t("This is required")}`),
        width_1: Yup.string().required(`${t("This is required")}`),
        height_1: Yup.string().required(`${t("This is required")}`),
        version: Yup.string().nullable(),
        margin_bottom: Yup.string().required(`${t("This is required")}`),
        margin_left: Yup.string().required(`${t("This is required")}`),
        margin_right: Yup.string().required(`${t("This is required")}`),
        target_id: Yup.string().nullable(),
        status: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
        position: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
        impression: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }),
        flg_click: Yup.number().nullable(),
        impression_option: Yup.number().nullable(),
        flg_banner_size: Yup.number().nullable(),

        delay_seconds: Yup.string().nullable(),

        fill_rate: Yup.number().nullable(),
        fill_rate_time: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),

        is_block_referral: Yup.number().nullable(),
        block_internal: Yup.number().nullable(),
        is_except_keyword: Yup.number().nullable(),

        referral_block_ad: Yup.number().nullable(),
        referral_block_keyword: Yup.array().of(
            Yup.object().shape({
                id: Yup.string(),
                text: Yup.string(),
            })
        ).nullable(),

        automatic_click: Yup.number().nullable(),
        popup_automatic_click: Yup.number().nullable(),
        automatic_redirect: Yup.number().nullable(),
        layer_click_url: Yup.string().nullable(),

        target_ctr: Yup.string().required(`${t("This is required")}`),
        hidden_ads_count: Yup.string().required(`${t("This is required")}`),

        hide_button: Yup.number().nullable(),
        replace_button: Yup.number().nullable(),
        replace_button_style: Yup.string().nullable(),

        button_position: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }).nullable(),
        button_style: Yup.string().nullable(),
        button_margin_left: Yup.string().nullable(),
        button_margin_right: Yup.string().nullable(),
        button_margin_bottom: Yup.string().nullable(),
        button_margin_top: Yup.string().nullable(),
        installation_date: Yup.string().nullable(),
        except_general_visitors: Yup.number().nullable(),

        operating_time_start: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        operating_time_end: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        exclusion_time: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        integration_method: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        limit_ads: Yup.string().nullable(),
        target_device: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        target_mobile: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        operating_probability: Yup.string().nullable(),
        automatic_delay_time: Yup.string().nullable(),

        click_operating_time_start: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        click_operating_time_end: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        click_operating_probability: Yup.string().nullable(),

        popup_operating_time_start: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        popup_operating_time_end: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        popup_operating_probability: Yup.string().nullable(),
        popup_automatic_delay_time: Yup.string().nullable(),
        popup_click_url: Yup.string().nullable(),

        redirect_operating_time_start: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        redirect_operating_time_end: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        redirect_operating_probability: Yup.string().nullable(),
        redirect_automatic_delay_time: Yup.string().nullable(),
        redirect_automatic_url: Yup.string().nullable(),
        cover_image_old: Yup.string().nullable(),
        cover_image: Yup.string().test(
            'image-validation',
            function (value: any) {
                const { cover_image_old, banner_type } = this.parent;
                // If cover_image_old is null or empty, image is required and must be a supported format
                if (value && String(value)?.split(',')?.filter((v) => !SUPPORTED_FORMATS.includes(v))?.length > 0) {
                    return this.createError({ message: `${t("Unsupported Format")}` });
                }
                return true;
            }
        ).nullable(),
        cover_click_url: Yup.string().nullable(),
        ad_display_time_start: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        ad_display_time_end: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            media_code: null,
            agency_code: null,
            coupang_code: null,
            parent_code: null,
            ad_platform: null,
            ad_type: null,
            banner_type: null,
            slide_banner_ratio: null,
            advertising_id: null,
            ad_id: "",
            ad_tracking_code: "",
            ad_sub_id: "",
            passback_url: "",
            adwiser_code: "",
            adwiser_url: "",
            retention_hour: "24",
            width: "0",
            height: "0",
            width_1: "0",
            height_1: "0",
            version: "",
            status: null,
            position: null,
            impression: null,
            margin_bottom: "70",
            margin_left: "10",
            margin_right: "10",
            delay_seconds: "0",
            target_id: "",
            flg_click: 1,
            impression_option: 0,
            is_block_referral: 0,
            block_internal: 1,
            automatic_click: 0,
            layer_click_url: '',
            popup_automatic_click: 0,
            popup_click_url: '',
            automatic_redirect: 0,
            is_except_keyword: 1,
            referral_block_ad: 0,
            referral_block_keyword: [],
            flg_banner_size: 0,
            fill_rate: 0,
            fill_rate_time: null,
            target_ctr: '0',
            hidden_ads_count: '3',
            hide_button: 0,
            replace_button: 0,
            replace_button_style: '',

            button_position: null,
            button_style: '',
            button_margin_left: '0',
            button_margin_right: '0',
            button_margin_bottom: '0',
            button_margin_top: '0',

            installation_date: null,
            except_general_visitors: 1,

            click_operating_probability: '10',
            click_operating_time_start: null,
            click_operating_time_end: null,

            popup_operating_probability: '10',
            popup_operating_time_start: null,
            popup_operating_time_end: null,
            popup_automatic_delay_time: '0',

            redirect_operating_probability: '10',
            redirect_operating_time_start: null,
            redirect_operating_time_end: null,
            redirect_automatic_delay_time: '0',
            redirect_automatic_url: '',

            operating_probability: '10',
            operating_time_start: null,
            operating_time_end: null,
            exclusion_time: null,
            integration_method: null,
            limit_ads: '0',
            target_device: null,
            target_mobile: null,
            automatic_delay_time: '0',

            ad_display_time_start: null,
            ad_display_time_end: null,

            cover_image_old: '',
            cover_image: null,
            cover_click_url: '',
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const v_name = valueDefault?.name || '';
        const v_media_code = MEDIA_OPTIONS_LANG?.find(
            (item: any) => String(item?.value) === String(valueDefault?.media?.code)
        );
        const v_agency_code = AGENCY_OPTIONS_LANG?.find(
            (item: any) => String(item?.value) === String(valueDefault?.inventoryAgency?.agency_code)
        );
        const v_coupang_code = COUPANG_OPTIONS_LANG?.find(
            (item: any) => String(item?.value) === String(valueDefault?.coupang_code)
        );
        const v_parent_code = INVENTORY_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(valueDefault?.parent_code)) || INVENTORY_OPTIONS_LANG[0];

        const v_ad_id = valueDefault?.ad_id;
        const v_ad_tracking_code = valueDefault?.ad_tracking_code;
        const v_ad_sub_id = valueDefault?.ad_sub_id;
        const v_passback_url = valueDefault?.attributes?.passback_url || '';
        const v_adwiser_code = valueDefault?.adwiser_code || '';
        const v_adwiser_url = valueDefault?.attributes?.adwiser_url || '';
        const v_retention_hour = valueDefault?.attributes?.retention_hour || '0';

        const v_width = valueDefault?.attributes?.width || valueDefault?.width || 0;
        const v_height = valueDefault?.attributes?.height || valueDefault?.height || 0;
        const v_width_1 = valueDefault?.attributes?.width_1 || valueDefault?.width_1 || 0;
        const v_height_1 = valueDefault?.attributes?.height_1 || valueDefault?.height_1 || 0;
        const v_version = valueDefault?.version;

        const v_margin_bottom = (valueDefault?.attributes?.margin_bottom || valueDefault?.margin_bottom)?.replace("px", "");
        const v_margin_left = (valueDefault?.attributes?.margin_left || valueDefault?.margin_left)?.replace("px", "");
        const v_margin_right = (valueDefault?.attributes?.margin_right || valueDefault?.margin_right)?.replace("px", "");
        const v_target_id = (valueDefault?.attributes?.target_id || valueDefault?.target_id);


        const v_status = STATUS_INVENTORY_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.status)
        );
        const v_position = POSITION_INVENTORY_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.position)
        );
        const v_impression = IMPRESSION_INVENTORY_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.attributes?.impression || valueDefault?.impression)
        );

        const v_ad_platform = AD_PLATFORM_INVENTORY_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.ad_platform)) || AD_PLATFORM_INVENTORY_OPTIONS_LANG[0];
        const v_ad_type = AD_TYPE_INVENTORY_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.ad_type)) || AD_TYPE_INVENTORY_OPTIONS_LANG[0];

        const v_banner_type = BANNER_TYPE_INVENTORY_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.banner_type)) || BANNER_TYPE_INVENTORY_OPTIONS_LANG[0];

        const v_slide_banner_ratio = SLIDE_BANNER_RATIO_TYPE_INVENTORY_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.attributes?.slide_banner_ratio)) || SLIDE_BANNER_RATIO_TYPE_INVENTORY_OPTIONS_LANG[0];

        const v_advertising_id = ADVERTISING_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.advertising?.[0]?.id || '')) || ADVERTISING_OPTIONS_LANG[0];

        const v_flg_click = Number(valueDefault?.configs?.flg_click || 0);
        const v_impression_option = Number(valueDefault?.configs?.impression_option || 0);
        const v_flg_banner_size = Number(valueDefault?.configs?.flg_banner_size || 0);

        const v_fill_rate = Number(valueDefault?.configs?.fill_rate || 0);
        const v_fill_rate_time = FILL_RATE_TIME_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.fill_rate_time));

        const v_is_block_referral = Number(valueDefault?.configs?.is_block_referral || 0);
        const v_block_internal = Number(valueDefault?.configs?.block_internal || 0);
        const v_is_except_keyword = Number(valueDefault?.configs?.is_except_keyword ?? 1);

        const v_referral_block_ad = Number(valueDefault?.configs?.referral_block_ad ?? 0);
        const v_referral_block_keyword = (valueDefault?.attributes?.referral_block_keyword || [])?.map((k: string) => ({ id: k, text: k }));

        const v_target_ctr = valueDefault?.configs?.target_ctr || 0;
        const v_hidden_ads_count = valueDefault?.configs?.hidden_ads_count || valueDefault?.hidden_ads_count || 0;

        const v_delay_seconds = String(valueDefault?.configs?.delay_seconds || 0);

        const v_hide_button = Number(valueDefault?.configs?.hide_button ?? 0);
        const v_replace_button = Number(valueDefault?.configs?.replace_button ?? 0);

        const v_replace_button_style = (valueDefault?.attributes?.replace_button_style || valueDefault?.replace_button_style || '');

        const v_button_position = BUTTON_POSITION_INVENTORY_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.attributes?.button_position)) || BUTTON_POSITION_INVENTORY_OPTIONS_LANG[0];
        const v_button_style = (valueDefault?.attributes?.button_style || valueDefault?.button_style || '');
        const v_button_margin_left = (valueDefault?.attributes?.button_margin_left || "0")?.replace("px", "");
        const v_button_margin_right = (valueDefault?.attributes?.button_margin_right || "0")?.replace("px", "");
        const v_button_margin_bottom = (valueDefault?.attributes?.button_margin_bottom || "0")?.replace("px", "");
        const v_button_margin_top = (valueDefault?.attributes?.button_margin_top || "0")?.replace("px", "");


        const v_installation_date = !!valueDefault?.parent_code ? null : (valueDefault?.installation_date || null);
        const v_except_general_visitors = Number(valueDefault?.configs?.except_general_visitors ?? 0);

        const v_click_operating_time_start = OPERATING_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.attributes?.click_operating_time_start)) || OPERATING_OPTIONS_LANG[0];
        const v_click_operating_time_end = OPERATING_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.attributes?.click_operating_time_end)) || OPERATING_OPTIONS_LANG[5];
        const v_click_operating_probability = String(valueDefault?.attributes?.click_operating_probability || "0");

        const v_automatic_click = Number(valueDefault?.configs?.automatic_click || 0);
        const v_layer_click_url = valueDefault?.attributes?.layer_click_url || '';
        const v_operating_time_start = OPERATING_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.attributes?.operating_time_start)) || OPERATING_OPTIONS_LANG[0];
        const v_operating_time_end = OPERATING_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.attributes?.operating_time_end)) || OPERATING_OPTIONS_LANG[5];
        const v_operating_probability = String(valueDefault?.attributes?.operating_probability || "0");
        const v_exclusion_time = FILL_RATE_TIME_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.attributes?.exclusion_time));
        const v_automatic_delay_time = valueDefault?.attributes?.automatic_delay_time || '0';
        const v_integration_method = INTEGRATION_METHOD_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.attributes?.integration_method));

        const v_limit_ads = Number(valueDefault?.configs?.limit_ads || 0);

        const v_target_device = DEVICE_DISPLAY_ADS_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.attributes?.target_device));
        const v_target_mobile = OS_DISPLAY_ADS_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.attributes?.target_mobile));

        const v_popup_automatic_click = Number(valueDefault?.configs?.popup_automatic_click || 0);
        const v_popup_click_url = valueDefault?.attributes?.popup_click_url || '';
        const v_popup_operating_time_start = OPERATING_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.attributes?.popup_operating_time_start)) || OPERATING_OPTIONS_LANG[0];
        const v_popup_operating_time_end = OPERATING_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.attributes?.popup_operating_time_end)) || OPERATING_OPTIONS_LANG[5];
        const v_popup_operating_probability = String(valueDefault?.attributes?.popup_operating_probability || "0");
        const v_popup_automatic_delay_time = valueDefault?.attributes?.popup_automatic_delay_time || '0';

        const v_automatic_redirect = Number(valueDefault?.configs?.automatic_redirect || 0);
        const v_redirect_automatic_url = valueDefault?.attributes?.redirect_automatic_url || '';
        const v_redirect_operating_time_start = OPERATING_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.attributes?.redirect_operating_time_start)) || OPERATING_OPTIONS_LANG[0];
        const v_redirect_operating_time_end = OPERATING_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.attributes?.redirect_operating_time_end)) || OPERATING_OPTIONS_LANG[5];
        const v_redirect_operating_probability = String(valueDefault?.attributes?.redirect_operating_probability || "0");
        const v_redirect_automatic_delay_time = valueDefault?.attributes?.redirect_automatic_delay_time || '0';

        const v_ad_display_time_start = OPERATING_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.attributes?.ad_display_time_start)) || OPERATING_OPTIONS_LANG[0];
        const v_ad_display_time_end = OPERATING_OPTIONS_LANG?.find((item) => String(item?.value) === String(valueDefault?.attributes?.ad_display_time_end)) || OPERATING_OPTIONS_LANG[24];

        const v_cover_image_old = valueDefault?.attributes?.cover_image_url || '';
        const v_cover_click_url = valueDefault?.attributes?.cover_click_url || '';

        formik.setFieldValue("name", v_name);

        formik.setFieldValue("media_code", v_media_code);

        formik.setFieldValue("agency_code", v_agency_code);

        // formik.setFieldValue("coupang_code", v_coupang_code);

        formik.setFieldValue("parent_code", v_parent_code);
        formik.setFieldValue("ad_platform", v_ad_platform);
        formik.setFieldValue("ad_type", v_ad_type);
        formik.setFieldValue("banner_type", v_banner_type);
        formik.setFieldValue("slide_banner_ratio", v_slide_banner_ratio);


        formik.setFieldValue("advertising_id", v_advertising_id);

        formik.setFieldValue("width", v_width);
        formik.setFieldValue("height", v_height);
        formik.setFieldValue("width_1", v_width_1);
        formik.setFieldValue("height_1", v_height_1);
        formik.setFieldValue("version", v_version);
        formik.setFieldValue("status", v_status);
        formik.setFieldValue("position", v_position);
        formik.setFieldValue("impression", v_impression);

        formik.setFieldValue("margin_bottom", v_margin_bottom);
        formik.setFieldValue("margin_left", v_margin_left);
        formik.setFieldValue("margin_right", v_margin_right);
        formik.setFieldValue("target_id", v_target_id);

        formik.setFieldValue("flg_click", v_flg_click);
        formik.setFieldValue("impression_option", v_impression_option);
        formik.setFieldValue("flg_banner_size", v_flg_banner_size);

        formik.setFieldValue("fill_rate", v_fill_rate);
        formik.setFieldValue("fill_rate_time", v_fill_rate_time);

        formik.setFieldValue("is_block_referral", v_is_block_referral);

        formik.setFieldValue("block_internal", v_block_internal);

        formik.setFieldValue("is_except_keyword", v_is_except_keyword);

        formik.setFieldValue("referral_block_ad", v_referral_block_ad);
        formik.setFieldValue("referral_block_keyword", v_referral_block_keyword);


        formik.setFieldValue("target_ctr", v_target_ctr);
        formik.setFieldValue("hidden_ads_count", v_hidden_ads_count);

        formik.setFieldValue("delay_seconds", v_delay_seconds);

        formik.setFieldValue("hide_button", v_hide_button);
        formik.setFieldValue("replace_button", v_replace_button);
        formik.setFieldValue("replace_button_style", v_replace_button_style);

        formik.setFieldValue("button_position", v_button_position);
        formik.setFieldValue("button_style", v_button_style);
        formik.setFieldValue("button_margin_left", v_button_margin_left);
        formik.setFieldValue("button_margin_right", v_button_margin_right);
        formik.setFieldValue("button_margin_bottom", v_button_margin_bottom);
        formik.setFieldValue("button_margin_top", v_button_margin_top);

        formik.setFieldValue("installation_date", v_installation_date);
        formik.setFieldValue("except_general_visitors", v_except_general_visitors);
        formik.setFieldValue("retention_hour", v_retention_hour);
        formik.setFieldValue("click_operating_time_start", v_click_operating_time_start);
        formik.setFieldValue("click_operating_time_end", v_click_operating_time_end);
        formik.setFieldValue("click_operating_probability", v_click_operating_probability);


        formik.setFieldValue("automatic_click", v_automatic_click);
        formik.setFieldValue("layer_click_url", v_layer_click_url);
        formik.setFieldValue("operating_time_start", v_operating_time_start);
        formik.setFieldValue("operating_time_end", v_operating_time_end);
        formik.setFieldValue("operating_probability", v_operating_probability);


        formik.setFieldValue("popup_automatic_click", v_popup_automatic_click);
        formik.setFieldValue("popup_click_url", v_popup_click_url);
        formik.setFieldValue("popup_operating_time_start", v_popup_operating_time_start);
        formik.setFieldValue("popup_operating_time_end", v_popup_operating_time_end);
        formik.setFieldValue("popup_operating_probability", v_popup_operating_probability);
        formik.setFieldValue("popup_automatic_delay_time", v_popup_automatic_delay_time);

        formik.setFieldValue("automatic_redirect", v_automatic_redirect);
        formik.setFieldValue("redirect_automatic_url", v_redirect_automatic_url);
        formik.setFieldValue("redirect_operating_time_start", v_redirect_operating_time_start);
        formik.setFieldValue("redirect_operating_time_end", v_redirect_operating_time_end);
        formik.setFieldValue("redirect_operating_probability", v_redirect_operating_probability);
        formik.setFieldValue("redirect_automatic_delay_time", v_redirect_automatic_delay_time);

        formik.setFieldValue("ad_display_time_start", v_ad_display_time_start);
        formik.setFieldValue("ad_display_time_end", v_ad_display_time_end);

        formik.setFieldValue("exclusion_time", v_exclusion_time);
        formik.setFieldValue("automatic_delay_time", v_automatic_delay_time);
        formik.setFieldValue("integration_method", v_integration_method);

        formik.setFieldValue("limit_ads", v_limit_ads);

        formik.setFieldValue("target_device", v_target_device);
        formik.setFieldValue("target_mobile", v_target_mobile);

        formik.setFieldValue("cover_image_old", v_cover_image_old);
        formik.setFieldValue("cover_click_url", v_cover_click_url);

        setTimeout(() => {
            formik.setFieldValue("ad_tracking_code", v_ad_tracking_code);
            formik.setFieldValue("ad_id", v_ad_id);
            formik.setFieldValue("ad_sub_id", v_ad_sub_id);
            formik.setFieldValue("passback_url", v_passback_url);
            formik.setFieldValue("adwiser_code", v_adwiser_code);
            formik.setFieldValue("adwiser_url", v_adwiser_url);
        }, 300);
    };

    const IS_INVENTORY_TYPE_COUPANG: boolean = (formik?.values?.ad_platform as any)?.value === AD_PLATFORM_INVENTORY_TYPE_COUPANG;
    const IS_INVENTORY_TYPE_DIGITAL_CAMP_SCRIPT: boolean = (formik?.values?.ad_platform as any)?.value === AD_PLATFORM_INVENTORY_TYPE_DIGITAL_CAMP_SCRIPT;
    const IS_INVENTORY_TYPE_SELF_ADVERTISING: boolean = (formik?.values?.ad_platform as any)?.value === AD_PLATFORM_INVENTORY_TYPE_SELF_ADVERTISING;
    const IS_INVENTORY_TYPE_COUPANG_NASMEDIA: boolean = (formik?.values?.ad_platform as any)?.value === AD_PLATFORM_INVENTORY_TYPE_COUPANG_NASMEDIA;
    const IS_INVENTORY_TYPE_ADWISER: boolean = (formik?.values?.ad_platform as any)?.value === AD_PLATFORM_INVENTORY_TYPE_ADWISER;
    const IS_INVENTORY_TYPE_ADWISER_URL: boolean = (formik?.values?.ad_platform as any)?.value === AD_PLATFORM_INVENTORY_TYPE_ADWISER_URL;
    const IS_INVENTORY_TYPE_TPMN: boolean = (formik?.values?.ad_platform as any)?.value === AD_PLATFORM_INVENTORY_TYPE_TPMN;

    const IS_INTEGRATION_METHOD_IFRAME: boolean = (formik?.values?.integration_method as any)?.value === INTEGRATION_METHOD_OPTIONS_LANG[1]?.value;

    const IS_INVENTORY_TYPE_COUPANG_NASMEDIA_KEYWORD: boolean = (formik?.values?.ad_platform as any)?.value === AD_PLATFORM_INVENTORY_TYPE_COUPANG_NASMEDIA_KEYWORD;

    const IS_MAIN_INVENTORY: boolean = !(formik?.values?.parent_code as any)?.value;

    const IS_TARGET_DEVICE_MOBILE: boolean = `${(formik?.values?.target_device as any)?.value || DEVICE_DISPLAY_ADS_OPTIONS_LANG[0]?.value}` === `${DEVICE_DISPLAY_ADS_TYPE.MOBILE}`;

    const IS_BANNER_TYPE_INVENTORY_SLIDER_BANNER_V2: boolean = (formik?.values?.banner_type as any)?.value === BANNER_TYPE_INVENTORY_SLIDER_BANNER_V2;

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id || detail?.parent_code) {
            handleSetValueForm(detail);
            const getDetail = async () => {
                try {
                    const response: any = await getInventory(detail?.id || detail?.parent_code);
                    if (response?.code === 200) {
                        const cloneInventory: any = structuredClone(response?.data);
                        if (detail?.id) {
                            handleSetValueForm(cloneInventory);
                        } else {
                            if (cloneInventory?.id) {
                                delete cloneInventory.id;
                            }
                            const v: IInventory = {
                                ...cloneInventory,
                                parent_code: response?.data?.id,
                                ad_platform: AD_PLATFORM_INVENTORY_OPTIONS_LANG[1]?.value,
                                ad_tracking_code: '',
                                ad_sub_id: '',
                                ad_id: '',
                                configs: {
                                    ...cloneInventory?.configs,
                                    target_ctr: 15,
                                    hidden_ads_count: 3,
                                }
                            } as IInventory;
                            handleSetValueForm(v);
                        }
                    } else {
                        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
                    }
                } catch (error: any) {
                    toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
                    return error;
                }
            }
            getDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    const handleOnUpdateFilesCover = (e: any) => {
        setFilesCover(e);
        formik.setFieldValue("cover_image", e?.length > 0 ? `${e?.map((f: any) => f?.file?.type).join(',') || ''}` : '');
        setImagesCoverPreview((_prev: any) => e?.map((fileItem: any) => fileItem.file));
    };

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col md={12} lg={12} className="mt-1">
                                                <div>
                                                    <label className="form-label">
                                                        {t("Agency")}
                                                    </label>
                                                    <DropdownOption
                                                        name="agency_code"
                                                        dataList={AGENCY_OPTIONS_LANG || []}
                                                        placeholder={`${t("Agency")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={formik?.values?.agency_code}
                                                        onChangeSelect={(event: any) => formik.setFieldValue("agency_code", event)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('---'), value: '' }}
                                                    />
                                                    {formik.touched.agency_code && formik.errors.agency_code ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.agency_code}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} lg={12} className="mt-2">
                                                <div>
                                                    <label className="form-label">
                                                        {t("Media")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownOption
                                                        name="media"
                                                        dataList={MEDIA_OPTIONS_LANG || []}
                                                        placeholder={`${t("Media")}...`}
                                                        className="dropdown-status-rounded form-ph"
                                                        classNamePrefix="name-prefix"
                                                        disabled={(!!detail?.id && !!detail?.parent_code) || (!!detail?.media && !!detail?.parent_code)}
                                                        initialValue={formik?.values?.media_code || null}
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("media_code", event)
                                                        }
                                                    />
                                                    {formik.errors.media_code ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.media_code}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} lg={12} className="mt-2">
                                                <div>
                                                    <label className="form-label">
                                                        {t("Name")}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        autocomplete={false}
                                                        value={formik?.values?.name}
                                                        placeholder={`${t("Name")}...`}
                                                        onChange={(event: any) =>
                                                            formik.setFieldValue(
                                                                "name",
                                                                event?.target?.value || ""
                                                            )
                                                        }
                                                    />
                                                    {formik.touched.name && formik.errors.name ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={4} lg={4}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Status")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_INVENTORY_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded form-ph"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={
                                                            formik?.values?.status ||
                                                            STATUS_INVENTORY_OPTIONS_LANG[0]
                                                        }
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("status", event)
                                                        }
                                                        colors={["danger", "success"]}
                                                    />
                                                    {formik.errors.status ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.status}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={8} lg={8}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Main Inventory")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownOption
                                                        name="parent_code"
                                                        dataList={INVENTORY_OPTIONS_LANG || []}
                                                        placeholder={`${t("Main Inventory")}...`}
                                                        className="dropdown-status-rounded form-ph"
                                                        classNamePrefix="name-prefix"
                                                        disabled={true}
                                                        initialValue={formik?.values?.parent_code || INVENTORY_OPTIONS_LANG[0]}
                                                        onChangeSelect={(event: any) => {
                                                            formik.setFieldValue("parent_code", event);
                                                        }
                                                        }
                                                    />
                                                    {formik.errors.parent_code ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.parent_code}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-4 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('Ad Platform')}</span>
                                                <Row className="mt-1">
                                                    <Col md={6} lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Ad Platform")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <DropdownOption
                                                                name="ad_platform"
                                                                dataList={AD_PLATFORM_INVENTORY_OPTIONS_LANG || []}
                                                                placeholder={`${t("Ad Platform")}...`}
                                                                className="dropdown-status-rounded form-ph"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={formik?.values?.ad_platform || AD_PLATFORM_INVENTORY_OPTIONS_LANG[0]}
                                                                onChangeSelect={(event: any) => {
                                                                    formik.setFieldValue("ad_platform", event);

                                                                    formik.setFieldValue("ad_tracking_code", '');
                                                                    formik.setFieldValue("ad_id", '');
                                                                    formik.setFieldValue("ad_sub_id", '');
                                                                    formik.setFieldValue("passback_url", '');
                                                                    formik.setFieldValue("adwiser_code", '');
                                                                    formik.setFieldValue("adwiser_url", '');
                                                                    // if ([AD_PLATFORM_INVENTORY_TYPE_DIGITAL_CAMP, AD_PLATFORM_INVENTORY_TYPE_DIGITAL_CAMP_SCRIPT].includes(event?.value)) {
                                                                    //     // formik.setFieldValue("ad_id", '');

                                                                    // } else {
                                                                    //     // formik.setFieldValue("parent_code", { value: '', label: t('None') });
                                                                    // }
                                                                }
                                                                }
                                                            />
                                                            {formik.errors.ad_platform ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.ad_platform}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={6} lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Coupang Code")}
                                                            </label>
                                                            <DropdownOption
                                                                name="coupang_code"
                                                                dataList={COUPANG_OPTIONS_LANG || []}
                                                                placeholder={`${t("Coupang Code")}...`}
                                                                className="dropdown-status-rounded form-ph"
                                                                classNamePrefix="name-prefix"
                                                                disabled={!IS_INVENTORY_TYPE_COUPANG}
                                                                initialValue={formik?.values?.coupang_code || COUPANG_OPTIONS_LANG[0]}
                                                                onChangeSelect={(event: any) => {
                                                                    const _coupang = coupangs?.find((_c: any) => String(_c?.id) === String(event?.value)) || null;
                                                                    formik.setFieldValue("coupang_code", event);
                                                                    formik.setFieldValue("ad_tracking_code", _coupang?.tracking_code || '');
                                                                    formik.setFieldValue("ad_id", _coupang?.ad_id || '');
                                                                    formik.setFieldValue("ad_sub_id", _coupang?.sub_id || '');
                                                                }

                                                                }
                                                            />
                                                            {formik.errors.coupang_code ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.coupang_code}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={4} lg={4} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Tracking Code")}
                                                                <span className="text-danger">{(IS_INVENTORY_TYPE_COUPANG || IS_INVENTORY_TYPE_DIGITAL_CAMP_SCRIPT || IS_INVENTORY_TYPE_TPMN) ? ' *' : ''}</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="ad_tracking_code"
                                                                name="ad_tracking_code"
                                                                autocomplete={false}
                                                                value={formik?.values?.ad_tracking_code}
                                                                placeholder={`${t("Tracking Code")}...`}
                                                                disabled={!IS_INVENTORY_TYPE_COUPANG && !IS_INVENTORY_TYPE_DIGITAL_CAMP_SCRIPT && !IS_INVENTORY_TYPE_TPMN}
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue(
                                                                        "ad_tracking_code",
                                                                        event?.target?.value || ""
                                                                    )
                                                                }
                                                            />
                                                            {formik.touched.ad_tracking_code && formik.errors.ad_tracking_code ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.ad_tracking_code}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={4} lg={4} className="mb-3 mb-md-2">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Ad ID")}
                                                                <span className="text-danger">{IS_INVENTORY_TYPE_COUPANG || IS_INVENTORY_TYPE_DIGITAL_CAMP_SCRIPT ? ' *' : ''}</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="ad_id"
                                                                name="ad_id"
                                                                autocomplete={false}
                                                                disabled={!IS_INVENTORY_TYPE_COUPANG && !IS_INVENTORY_TYPE_DIGITAL_CAMP_SCRIPT}
                                                                value={formik?.values?.ad_id}
                                                                placeholder={`${t("Ad ID")}...`}
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue(
                                                                        "ad_id",
                                                                        event?.target?.value || ""
                                                                    )
                                                                }
                                                            />
                                                            {formik.touched.ad_id && formik.errors.ad_id ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.ad_id}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col md={4} lg={4} className="mb-3 mb-md-2">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Sub ID")}
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="ad_sub_id"
                                                                name="ad_sub_id"
                                                                autocomplete={false}
                                                                disabled={!IS_INVENTORY_TYPE_COUPANG}
                                                                value={formik?.values?.ad_sub_id}
                                                                placeholder={`${t("Sub ID")}...`}
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue(
                                                                        "ad_sub_id",
                                                                        event?.target?.value || ""
                                                                    )
                                                                }
                                                            />
                                                            {formik.touched.ad_sub_id && formik.errors.ad_sub_id ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.ad_sub_id}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={12} lg={12} className="mb-3 mb-md-2 mt-1">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Adwiser Code")}
                                                                <span className="text-danger">{(IS_INVENTORY_TYPE_ADWISER) ? ' *' : ''}</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="adwiser_code"
                                                                name="adwiser_code"
                                                                autocomplete={false}
                                                                disabled={!IS_INVENTORY_TYPE_ADWISER}
                                                                value={formik?.values?.adwiser_code}
                                                                placeholder={`${t("Adwiser Code")}...`}
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue(
                                                                        "adwiser_code",
                                                                        event?.target?.value || ""
                                                                    )
                                                                }
                                                            />
                                                            {formik.touched.adwiser_code && formik.errors.adwiser_code ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.adwiser_code}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={12} lg={12} className="mb-3 mb-md-2 mt-1">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Adwiser URL")}
                                                                <span className="text-danger">{IS_INVENTORY_TYPE_ADWISER_URL ? ' *' : ''}</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="adwiser_url"
                                                                name="adwiser_url"
                                                                autocomplete={false}
                                                                disabled={!IS_INVENTORY_TYPE_ADWISER_URL}
                                                                value={formik?.values?.adwiser_url}
                                                                placeholder={`${t("Adwiser URL")}...`}
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue(
                                                                        "adwiser_url",
                                                                        event?.target?.value || ""
                                                                    )
                                                                }
                                                            />
                                                            {formik.touched.adwiser_url && formik.errors.adwiser_url ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.adwiser_url}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={12} lg={12} className="mb-3 mb-md-2 mt-1">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Passback URL")}
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="passback_url"
                                                                name="passback_url"
                                                                autocomplete={false}
                                                                disabled={!IS_INVENTORY_TYPE_DIGITAL_CAMP_SCRIPT}
                                                                value={formik?.values?.passback_url}
                                                                placeholder={`${t("Passback URL")}...`}
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue(
                                                                        "passback_url",
                                                                        event?.target?.value || ""
                                                                    )
                                                                }
                                                            />
                                                            {formik.touched.passback_url && formik.errors.passback_url ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.passback_url}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={12} lg={12} className="mb-3 mb-md-2 mt-1">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Advertising")}
                                                                {IS_INVENTORY_TYPE_SELF_ADVERTISING && <span className="text-danger"> *</span>}
                                                            </label>
                                                            <DropdownOption
                                                                name="advertising_id"
                                                                dataList={ADVERTISING_OPTIONS_LANG || []}
                                                                placeholder={`${t("Advertising")}...`}
                                                                className="dropdown-status-rounded form-ph"
                                                                classNamePrefix="name-prefix"
                                                                disabled={!IS_INVENTORY_TYPE_SELF_ADVERTISING}
                                                                initialValue={formik?.values?.advertising_id || ADVERTISING_OPTIONS_LANG[0]}
                                                                onChangeSelect={(event: any) => {
                                                                    formik.setFieldValue("advertising_id", event);
                                                                }
                                                                }
                                                            />
                                                            {formik.errors.advertising_id ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.advertising_id}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={12} lg={12} className="mb-3 mb-md-2 mt-1">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Integration Method")}
                                                            </label>

                                                            <DropdownOption
                                                                name="integration_method"
                                                                dataList={INTEGRATION_METHOD_OPTIONS_LANG || []}
                                                                placeholder={`${t("Integration Method")}...`}
                                                                className="dropdown-status-rounded form-ph"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={
                                                                    formik?.values?.integration_method ||
                                                                    INTEGRATION_METHOD_OPTIONS_LANG[0]
                                                                }
                                                                onChangeSelect={(event: any) => {
                                                                    formik.setFieldValue("integration_method", event);
                                                                    if (String(event?.value) === String(INTEGRATION_METHOD_OPTIONS_LANG[1]?.value)) {
                                                                        formik.setFieldValue("margin_bottom", '0');
                                                                        formik.setFieldValue("margin_left", '0');
                                                                        formik.setFieldValue("margin_right", '0');
                                                                    }
                                                                }
                                                                }
                                                            // disabled={!IS_INVENTORY_TYPE_SELF_ADVERTISING}
                                                            />
                                                            {formik.errors.integration_method ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.integration_method}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} className="mt-2 mb-2">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Operating Time (start)")}
                                                                <span className="text-danger"> *</span>
                                                                <TooltipCustom
                                                                    title={t("The time allows ads to be shown only during the specified times.")}
                                                                    className="d-inline-block vertical-align-middle ms-1"
                                                                    style={{ transform: 'translateY(1px)' }}
                                                                >
                                                                    <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                </TooltipCustom>
                                                            </label>
                                                            <DropdownOption
                                                                name="ad_display_time_start"
                                                                dataList={OPERATING_OPTIONS_LANG || []}
                                                                placeholder={`${t("Operating Time (start)")}...`}
                                                                className="dropdown-status-rounded form-ph"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={
                                                                    formik?.values?.ad_display_time_start ||
                                                                    OPERATING_OPTIONS_LANG[0]
                                                                }
                                                                onChangeSelect={(event: any) =>
                                                                    formik.setFieldValue("ad_display_time_start", event)
                                                                }
                                                            // disabled={!IS_INTEGRATION_METHOD_IFRAME}

                                                            />
                                                            {formik.errors.ad_display_time_start ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.ad_display_time_start}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} className="mt-2 mb-2">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Operating Time (end)")}
                                                                <span className="text-danger"> *</span>
                                                                <TooltipCustom
                                                                    title={t("The time allows ads to be shown only during the specified times.")}
                                                                    className="d-inline-block vertical-align-middle ms-1"
                                                                    style={{ transform: 'translateY(1px)' }}
                                                                >
                                                                    <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                </TooltipCustom>
                                                            </label>
                                                            <DropdownOption
                                                                name="ad_display_time_end"
                                                                dataList={OPERATING_OPTIONS_LANG || []}
                                                                placeholder={`${t("Operating Time (end)")}...`}
                                                                className="dropdown-status-rounded form-ph"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={
                                                                    formik?.values?.ad_display_time_end ||
                                                                    OPERATING_OPTIONS_LANG[24]
                                                                }
                                                                onChangeSelect={(event: any) =>
                                                                    formik.setFieldValue("ad_display_time_end", event)
                                                                }
                                                            // disabled={!IS_INTEGRATION_METHOD_IFRAME}
                                                            />
                                                            {formik.errors.ad_display_time_end ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.ad_display_time_end}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={12} className="mt-2 mb-2">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Impression limitation per user")}
                                                                <TooltipCustom
                                                                    title={t("Ads are limited to one impression per user per day; if set to 0, they will display without restriction.")}
                                                                    className="d-inline-block vertical-align-middle ms-1"
                                                                    style={{ transform: 'translateY(1px)' }}
                                                                >
                                                                    <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                </TooltipCustom>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="limit_ads"
                                                                name="limit_ads"
                                                                autocomplete={false}
                                                                value={formik?.values?.limit_ads}
                                                                placeholder={`${t("Impression limitation per user")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("limit_ads", s);
                                                                }}
                                                                disabled={(!!detail?.parent_code) || (!!detail?.media && !!detail?.parent_code)}
                                                            />
                                                            {formik.errors.limit_ads ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.limit_ads}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col sm={6} className="mt-2 mb-2">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Target Device")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <DropdownOption
                                                                name="target_device"
                                                                dataList={DEVICE_DISPLAY_ADS_OPTIONS_LANG || []}
                                                                placeholder={`${t("Target Device")}...`}
                                                                className="dropdown-status-rounded form-ph"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={
                                                                    formik?.values?.target_device ||
                                                                    DEVICE_DISPLAY_ADS_OPTIONS_LANG[0]
                                                                }
                                                                onChangeSelect={(event: any) => {
                                                                    formik.setFieldValue("target_device", event);
                                                                    if (`${event?.value}` === `${DEVICE_DISPLAY_ADS_TYPE.PC}`) {
                                                                        formik.setFieldValue("target_mobile", OS_DISPLAY_ADS_OPTIONS_LANG[0]);
                                                                    }
                                                                }

                                                                }
                                                            // disabled={!IS_INTEGRATION_METHOD_IFRAME}
                                                            />
                                                            {formik.errors.target_device ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.target_device}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} className="mt-2 mb-2">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Target Mobile")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <DropdownOption
                                                                name="target_mobile"
                                                                dataList={OS_DISPLAY_ADS_OPTIONS_LANG || []}
                                                                placeholder={`${t("Target Mobile")}...`}
                                                                className="dropdown-status-rounded form-ph"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={
                                                                    formik?.values?.target_mobile || OS_DISPLAY_ADS_OPTIONS_LANG[0]
                                                                }
                                                                onChangeSelect={(event: any) =>
                                                                    formik.setFieldValue("target_mobile", event)
                                                                }
                                                                disabled={!IS_TARGET_DEVICE_MOBILE}
                                                                isHasOptionAll={false}
                                                            />
                                                            {formik.errors.target_mobile ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.target_mobile}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                </Row>
                                            </Col>

                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative" style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('Banner')}</span>
                                                <Row className="mt-1">
                                                    <Col md={12} lg={12} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Banner Type")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <DropdownOption
                                                                name="banner_type"
                                                                dataList={BANNER_TYPE_INVENTORY_OPTIONS_LANG || []}
                                                                placeholder={`${t("Banner Type")}...`}
                                                                className="dropdown-status-rounded form-ph"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={formik?.values?.banner_type || BANNER_TYPE_INVENTORY_OPTIONS_LANG[0]}
                                                                onChangeSelect={(event: any) =>
                                                                    formik.setFieldValue("banner_type", event)
                                                                }
                                                            />
                                                            {formik.errors.banner_type ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.banner_type}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    {/* <Col md={12} lg={12} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Slide Banner Ratio")}
                                                                <TooltipCustom
                                                                    title={t("This is the setting for how far the slider banner must move before the ad is clicked.")}
                                                                    className="d-inline-block vertical-align-middle ms-1"
                                                                    style={{ transform: 'translateY(1px)' }}
                                                                >
                                                                    <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                </TooltipCustom>
                                                            </label>
                                                            <DropdownOption
                                                                name="slide_banner_ratio"
                                                                dataList={SLIDE_BANNER_RATIO_TYPE_INVENTORY_OPTIONS_LANG || []}
                                                                placeholder={`${t("Slide Banner Ratio")}...`}
                                                                className="dropdown-status-rounded form-ph"
                                                                classNamePrefix="name-prefix"
                                                                disabled={(formik?.values?.banner_type as any)?.value !== BANNER_TYPE_INVENTORY_SLIDER_BANNER_V2}
                                                                initialValue={formik?.values?.slide_banner_ratio || SLIDE_BANNER_RATIO_TYPE_INVENTORY_OPTIONS_LANG[0]}
                                                                onChangeSelect={(event: any) =>
                                                                    formik.setFieldValue("slide_banner_ratio", event)
                                                                }
                                                            />
                                                            {formik.errors.slide_banner_ratio ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.slide_banner_ratio}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col> */}
                                                    <Col md={12} lg={12} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Ad Type")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <DropdownOption
                                                                name="ad_type"
                                                                dataList={AD_TYPE_INVENTORY_OPTIONS_LANG || []}
                                                                placeholder={`${t("Ad Type")}...`}
                                                                className="dropdown-status-rounded form-ph"
                                                                classNamePrefix="name-prefix"
                                                                disabled={!IS_MAIN_INVENTORY}
                                                                initialValue={formik?.values?.ad_type || AD_TYPE_INVENTORY_OPTIONS_LANG[0]}
                                                                onChangeSelect={(event: any) =>
                                                                    formik.setFieldValue("ad_type", event)
                                                                }
                                                            />
                                                            {formik.errors.ad_type ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.ad_type}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Impression")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <DropdownOption
                                                                name="impression"
                                                                dataList={IMPRESSION_INVENTORY_OPTIONS_LANG || []}
                                                                placeholder={`${t("Impression")}...`}
                                                                className="dropdown-status-rounded form-ph"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={
                                                                    formik?.values?.impression ||
                                                                    IMPRESSION_INVENTORY_OPTIONS_LANG[0]
                                                                }
                                                                onChangeSelect={(event: any) =>
                                                                    formik.setFieldValue("impression", event)
                                                                }
                                                            // colors={['danger', 'success']}
                                                            />
                                                            {formik.errors.impression ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.impression}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Delay Time")}
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="delay_seconds"
                                                                name="delay_seconds"
                                                                autocomplete={false}
                                                                value={formik?.values?.delay_seconds}
                                                                placeholder={`${t("Delay Time")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("delay_seconds", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                {t('seconds')}
                                                            </div>
                                                            {formik.errors.delay_seconds ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.delay_seconds}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Width")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="width"
                                                                name="width"
                                                                autocomplete={false}
                                                                value={formik?.values?.width}
                                                                placeholder={`${t("Width")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("width", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.width ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.width}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Height")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="height"
                                                                name="height"
                                                                autocomplete={false}
                                                                value={formik?.values?.height}
                                                                placeholder={`${t("Height")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("height", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.height ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.height}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Position")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <DropdownOption
                                                                name="position"
                                                                dataList={POSITION_INVENTORY_OPTIONS_LANG || []}
                                                                placeholder={`${t("Position")}...`}
                                                                className="dropdown-status-rounded form-ph"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={
                                                                    formik?.values?.position ||
                                                                    POSITION_INVENTORY_OPTIONS_LANG[0]
                                                                }
                                                                onChangeSelect={(event: any) =>
                                                                    formik.setFieldValue("position", event)
                                                                }
                                                            // colors={['danger', 'success']}
                                                            />
                                                            {formik.errors.position ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.position}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="mb-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Margin Bottom")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="margin_bottom"
                                                                name="margin_bottom"
                                                                autocomplete={false}
                                                                value={formik?.values?.margin_bottom}
                                                                placeholder={`${t("Margin Bottom")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("margin_bottom", s);
                                                                }
                                                                }
                                                                className="pe-5"
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.margin_bottom ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.margin_bottom}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="mb-3 mb-lg-2">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Margin Left")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="margin_left"
                                                                name="margin_left"
                                                                autocomplete={false}
                                                                value={formik?.values?.margin_left}
                                                                placeholder={`${t("Margin Left")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("margin_left", s);
                                                                }
                                                                }
                                                                className="pe-5"
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.margin_left ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.margin_left}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="mb-2">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Margin Right")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="margin_right"
                                                                name="margin_right"
                                                                autocomplete={false}
                                                                value={formik?.values?.margin_right}
                                                                placeholder={`${t("Margin Right")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("margin_right", s);
                                                                }
                                                                }
                                                                className="pe-5"
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.margin_right ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.margin_right}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={12} className="mb-3 mb-lg-2 mt-2">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Target ID")}
                                                                <TooltipCustom
                                                                    title={t("The ad object will be created within the specified Target ID")}
                                                                    className="d-inline-block vertical-align-middle ms-1"
                                                                    style={{ transform: 'translateY(1px)' }}
                                                                >
                                                                    <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                </TooltipCustom>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="target_id"
                                                                name="target_id"
                                                                autocomplete={false}
                                                                value={formik?.values?.target_id}
                                                                placeholder={`${t("Target ID")}...`}
                                                                onChange={(event: any) => {
                                                                    const v = event.target.value;
                                                                    formik.setFieldValue("target_id", v);
                                                                }
                                                                }
                                                                className="pe-5"
                                                            />
                                                            {formik.errors.target_id ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.target_id}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    {/* <Col sm={12} className="mt-0 mb-2">
                                                        <div>
                                                            <label className="form-label">{t("Click URL")}</label>
                                                            <Input
                                                                type="text"
                                                                id="cover_click_url"
                                                                name="cover_click_url"
                                                                autocomplete={false}
                                                                value={formik?.values?.cover_click_url}
                                                                placeholder={`${t("Click URL")}...`}
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue(
                                                                        "cover_click_url",
                                                                        event?.target?.value || ""
                                                                    )
                                                                }
                                                                disabled={!IS_INVENTORY_TYPE_SELF_ADVERTISING}
                                                            />
                                                            {formik.touched.cover_click_url && formik.errors.cover_click_url ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.cover_click_url}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col> */}
                                                </Row>
                                            </Col>
                                            {(String((formik?.values?.banner_type as any)?.value) !== String(TYPE_BANNER_ADVERTISING.SINGLE_BANNER)) &&
                                                <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative" style={{ borderRadius: '5px' }}>
                                                    <span className="position-absolute text-primary" style={{
                                                        top: 0,
                                                        transform: 'translate(-5px, -55%)',
                                                        backgroundColor: 'var(--vz-light)',
                                                        padding: '0 5px',
                                                        zIndex: 1,
                                                    }}>{t('Slider Banner')}</span>
                                                    <Row className="mt-0">
                                                        <Col className=" mt-2">
                                                            <label className="form-label">
                                                                {t("Slider Image")}
                                                            </label>
                                                            <FilePond
                                                                files={filesCover}
                                                                onupdatefiles={handleOnUpdateFilesCover}
                                                                allowMultiple={false}
                                                                maxFiles={1}
                                                                name="files"
                                                                instantUpload={false}
                                                                disabled={!!detail?.is_preview}
                                                                labelIdle={t("Drag & Drop your image or <span class='filepond--label-action'>Browse</span>")}
                                                            />
                                                            {formik.errors.cover_image ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.cover_image}
                                                                </div>
                                                            ) : null}
                                                        </Col>
                                                        {(!!(imagesCoverPreview?.length > 0) || !!formik?.values?.cover_image_old) && <Col lg={12} className="mb-2">
                                                            <label className="form-label">
                                                                {t("Preview")} ({`${200}`} * {`${200}`})
                                                            </label>
                                                            <div className="text-center">
                                                                {imagesCoverPreview?.length > 0 ? (
                                                                    imagesCoverPreview?.map((image: any, index: number) => (
                                                                        <img key={index} src={URL.createObjectURL(image)} alt="Image Cover Preview" style={{ width: `${200}px`, height: `${200}px`, backgroundColor: '#eaeaea', objectFit: 'contain' }} />
                                                                    ))
                                                                ) : (
                                                                    formik?.values?.cover_image_old ?
                                                                        <div className="position-relative d-inline-block">
                                                                            <img src={formik?.values?.cover_image_old} alt="Image Preview" style={{ width: `${200}px`, height: `${200}px`, backgroundColor: '#eaeaea', objectFit: 'contain' }} />
                                                                            <span className="position-absolute d-flex justify-content-center align-items-center cursor-pointer btn-delete-preview" style={{ top: 10, right: 10, width: '30px', height: '30px', backgroundColor: 'var(--vz-light)', padding: '2px 5px', borderRadius: '15px' }} onClick={() => formik.setFieldValue('cover_image_old', '')}>
                                                                                <i className="ri ri-delete-bin-4-line text-danger" />
                                                                            </span>
                                                                        </div>
                                                                        : <></>
                                                                )}
                                                            </div>
                                                        </Col>}
                                                    </Row>
                                                </Col>
                                            }
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative" style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('Close Button')}</span>
                                                <Col sm={12} className="mt-2">
                                                    <div className="form-check form-switch mb-0">
                                                        <Input className="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            id="hide_button"
                                                            name="hide_button"
                                                            checked={!!formik?.values?.hide_button}
                                                            onChange={(event: any) => formik.setFieldValue('hide_button', Number(event?.target.checked))}
                                                        />

                                                        <Label className="form-check-label" for="hide_button">{t('Hide Button')}</Label>
                                                        <TooltipCustom
                                                            title={t("Not display close button")}
                                                            className="d-inline-block vertical-align-middle ms-1"
                                                            style={{ transform: 'translateY(1px)' }}
                                                        >
                                                            <i className="ri-question-line align-bottom text-secondary" ></i>
                                                        </TooltipCustom>
                                                    </div>
                                                </Col>
                                                <Col sm={12} className="mt-2">
                                                    <div>
                                                        <label className="form-label">
                                                            {t("Button Position")}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                        <DropdownOption
                                                            name="button_position"
                                                            dataList={BUTTON_POSITION_INVENTORY_OPTIONS_LANG || []}
                                                            placeholder={`${t("Button Position")}...`}
                                                            className="dropdown-status-rounded form-ph"
                                                            classNamePrefix="name-prefix"
                                                            initialValue={
                                                                formik?.values?.button_position ||
                                                                BUTTON_POSITION_INVENTORY_OPTIONS_LANG[0]
                                                            }
                                                            onChangeSelect={(event: any) =>
                                                                formik.setFieldValue("button_position", event)
                                                            }
                                                        />
                                                        {formik.errors.button_position ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.button_position}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col sm={12} lg={12} className="mb-2 mt-3">
                                                    <div>
                                                        <label className="form-label">
                                                            {t("Button Style")}
                                                        </label>
                                                        <Input
                                                            type="textarea"
                                                            rows={3}
                                                            id="button_style"
                                                            name="button_style"
                                                            autocomplete={false}
                                                            value={formik?.values?.button_style}
                                                            placeholder={`${t("font-size: 10px;")}...`}
                                                            onChange={(event: any) =>
                                                                formik.setFieldValue(
                                                                    "button_style",
                                                                    event?.target?.value || ""
                                                                )
                                                            }
                                                        />
                                                        {formik.errors.button_style ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.button_style}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Row className="mb-2">
                                                    <Col lg={6} className="mt-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Button Margin Top")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="button_margin_top"
                                                                name="button_margin_top"
                                                                autocomplete={false}
                                                                value={formik?.values?.button_margin_top}
                                                                placeholder={`${t("Button Margin Top")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("button_margin_top", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.button_margin_top ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.button_margin_top}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="mt-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Button Margin Bottom")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="button_margin_bottom"
                                                                name="button_margin_bottom"
                                                                autocomplete={false}
                                                                value={formik?.values?.button_margin_bottom}
                                                                placeholder={`${t("Button Margin Bottom")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("button_margin_bottom", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.button_margin_bottom ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.button_margin_bottom}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="mt-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Button Margin Left")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="button_margin_left"
                                                                name="button_margin_left"
                                                                autocomplete={false}
                                                                value={formik?.values?.button_margin_left}
                                                                placeholder={`${t("Button Margin Left")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("button_margin_left", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.button_margin_left ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.button_margin_left}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="mt-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Button Margin Right")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="button_margin_right"
                                                                name="button_margin_right"
                                                                autocomplete={false}
                                                                value={formik?.values?.button_margin_right}
                                                                placeholder={`${t("Button Margin Right")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("button_margin_right", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.button_margin_right ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.button_margin_right}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <div className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('Fill-Rate')}</span>
                                                <Col sm={12} className="mt-2">
                                                    <div className="form-check form-switch mb-0">
                                                        <Input className="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            id="fill_rate"
                                                            name="fill_rate"
                                                            checked={!!formik?.values?.fill_rate}
                                                            onChange={(event: any) => formik.setFieldValue('fill_rate', Number(event?.target.checked))}
                                                        />
                                                        <Label className="form-check-label" for="fill_rate">{t('Fill-Rate')}</Label>
                                                        <TooltipCustom
                                                            title={t("Ad is displayed once every hour")}
                                                            className="d-inline-block vertical-align-middle ms-1"
                                                            style={{ transform: 'translateY(1px)' }}
                                                        >
                                                            <i className="ri-question-line align-bottom text-secondary" ></i>
                                                        </TooltipCustom>
                                                    </div>
                                                </Col>
                                                <Col sm={12} className="mt-2 mb-2">
                                                    <div>
                                                        <label className="form-label">
                                                            {t("Set interval time (hour)")}
                                                            <span className="text-danger"> *</span>
                                                        </label>
                                                        <DropdownOption
                                                            name="fill_rate_time"
                                                            dataList={FILL_RATE_TIME_OPTIONS_LANG || []}
                                                            placeholder={`${t("Fill-Rate Time")}...`}
                                                            className="dropdown-status-rounded form-ph"
                                                            classNamePrefix="name-prefix"
                                                            initialValue={
                                                                formik?.values?.fill_rate_time ||
                                                                FILL_RATE_TIME_OPTIONS_LANG[0]
                                                            }
                                                            onChangeSelect={(event: any) =>
                                                                formik.setFieldValue("fill_rate_time", event)
                                                            }
                                                        // colors={['danger', 'success']}
                                                        />
                                                        {formik.errors.fill_rate_time ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.fill_rate_time}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </div>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('Block Internal')}</span>
                                                <div className="form-check form-switch mb-1 mt-2">
                                                    <Input className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="block_internal"
                                                        name="block_internal"
                                                        checked={!!formik?.values?.block_internal}
                                                        onChange={(event: any) => formik.setFieldValue('block_internal', Number(event?.target.checked))}
                                                    />
                                                    <Label className="form-check-label" for="block_internal">{t('Block Internal')}</Label>
                                                    <TooltipCustom
                                                        title={t("When the referral URL is an internal URL, the 'Click Option' and 'Size Option' do not work")}
                                                        className="d-inline-block vertical-align-middle ms-1"
                                                        style={{ transform: 'translateY(1px)' }}
                                                    >
                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                    </TooltipCustom>
                                                </div>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('Click Option')}</span>
                                                <div className="form-check form-switch mb-1 mt-2 " >
                                                    <Input className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="flg_click"
                                                        name="flg_click"
                                                        checked={!!formik?.values?.flg_click}
                                                        onChange={(event: any) => formik.setFieldValue('flg_click', Number(event?.target.checked))}
                                                    />
                                                    <Label className="form-check-label" for="flg_click">{t('Click Option')}</Label>
                                                    <TooltipCustom
                                                        title={t("When incoming from an external URL, click on the advertisement")}
                                                        className="d-inline-block vertical-align-middle ms-1"
                                                        style={{ transform: 'translateY(1px)' }}
                                                    >
                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                    </TooltipCustom>
                                                </div>
                                                <div className="mb-1 mt-2" >
                                                    <label className="form-label">
                                                        {t("Retention Hour")}
                                                        {IS_INTEGRATION_METHOD_IFRAME && <span className="text-danger"> *</span>}
                                                        <TooltipCustom
                                                            title={t("After being clicked, it cannot be clicked again for a specified period of hour.")}
                                                            className="d-inline-block vertical-align-middle ms-1"
                                                            style={{ transform: 'translateY(1px)' }}
                                                        >
                                                            <i className="ri-question-line align-bottom text-secondary" ></i>
                                                        </TooltipCustom>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="retention_hour"
                                                        name="retention_hour"
                                                        autocomplete={false}
                                                        value={formik?.values?.retention_hour}
                                                        placeholder={`${t("Width")}...`}
                                                        onChange={(event: any) => {
                                                            const input = event.target.value;
                                                            const onlyNumbers = input.replace(/\D/g, "");
                                                            const s = Number(onlyNumbers);
                                                            formik.setFieldValue("retention_hour", s);
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-check form-switch mb-0 mt-3 " >
                                                    <Input className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="impression_option"
                                                        name="impression_option"
                                                        checked={!!formik?.values?.impression_option}
                                                        onChange={(event: any) => formik.setFieldValue('impression_option', Number(event?.target.checked))}
                                                    />

                                                    <Label className="form-check-label" for="impression_option">{t('Impression Option')}</Label>
                                                    <TooltipCustom
                                                        title={t("If clicked within the retention hour, ads will not be displayed during the retention hour.")}
                                                        className="d-inline-block vertical-align-middle ms-1"
                                                        style={{ transform: 'translateY(1px)' }}
                                                    >
                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                    </TooltipCustom>
                                                </div>
                                                <div className="text-center mt-4 mb-2 position-relative " style={{
                                                    borderBottom: '1px dashed rgba(var(--vz-primary-rgb)'
                                                }}>
                                                    <i className="ri ri-arrow-down-s-line text-primary position-absolute"
                                                        style={{
                                                            transform: 'translate(-60%, -45%)',
                                                            backgroundColor: 'var(--vz-light)',
                                                            fontSize: '18px',
                                                        }} />

                                                    <TooltipCustom
                                                        title={t("This setting applies when the script is integrated through an iframe.")}
                                                        className="d-inline-block vertical-align-middle ms-1 position-absolute"
                                                        style={{
                                                            right: 0,
                                                            transform: 'translateY(-45%)',
                                                            backgroundColor: 'var(--vz-light)',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        <i className="ri-question-line align-bottom text-primary" ></i>
                                                    </TooltipCustom>

                                                </div>
                                                <Row>
                                                    <Col sm={6} className="mt-2 mb-2">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Operating Time (start)")}
                                                                <span className="text-danger"> *</span>
                                                                <TooltipCustom
                                                                    title={t("The time period during which automatic clicks operate.")}
                                                                    className="d-inline-block vertical-align-middle ms-1"
                                                                    style={{ transform: 'translateY(1px)' }}
                                                                >
                                                                    <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                </TooltipCustom>
                                                            </label>
                                                            <DropdownOption
                                                                name="click_operating_time_start"
                                                                dataList={OPERATING_OPTIONS_LANG || []}
                                                                placeholder={`${t("Operating Time (start)")}...`}
                                                                className="dropdown-status-rounded form-ph"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={
                                                                    formik?.values?.click_operating_time_start ||
                                                                    OPERATING_OPTIONS_LANG[0]
                                                                }
                                                                onChangeSelect={(event: any) =>
                                                                    formik.setFieldValue("click_operating_time_start", event)
                                                                }
                                                                disabled={!IS_INTEGRATION_METHOD_IFRAME}

                                                            />
                                                            {formik.errors.click_operating_time_start ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.click_operating_time_start}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} className="mt-2 mb-2">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Operating Time (end)")}
                                                                <span className="text-danger"> *</span>
                                                                <TooltipCustom
                                                                    title={t("The time period during which automatic clicks operate.")}
                                                                    className="d-inline-block vertical-align-middle ms-1"
                                                                    style={{ transform: 'translateY(1px)' }}
                                                                >
                                                                    <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                </TooltipCustom>
                                                            </label>
                                                            <DropdownOption
                                                                name="click_operating_time_end"
                                                                dataList={OPERATING_OPTIONS_LANG || []}
                                                                placeholder={`${t("Operating Time (end)")}...`}
                                                                className="dropdown-status-rounded form-ph"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={
                                                                    formik?.values?.click_operating_time_end ||
                                                                    OPERATING_OPTIONS_LANG[5]
                                                                }
                                                                onChangeSelect={(event: any) =>
                                                                    formik.setFieldValue("click_operating_time_end", event)
                                                                }
                                                                disabled={!IS_INTEGRATION_METHOD_IFRAME}
                                                            />
                                                            {formik.errors.click_operating_time_end ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.click_operating_time_end}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} className="mt-2 mb-2">
                                                        <div className="position-relative">
                                                            <label className="form-label">
                                                                {t("Operating Probability")}
                                                                <span className="text-danger"> *</span>
                                                                <TooltipCustom
                                                                    title={t("The probability of automatic clicks operating during the specified time period.")}
                                                                    className="d-inline-block vertical-align-middle ms-1"
                                                                    style={{ transform: 'translateY(1px)' }}
                                                                >
                                                                    <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                </TooltipCustom>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="click_operating_probability"
                                                                name="click_operating_probability"
                                                                autocomplete={false}
                                                                value={formik?.values?.click_operating_probability}
                                                                placeholder={`${t("Operating Probability")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("click_operating_probability", s);
                                                                }}
                                                                disabled={!IS_INTEGRATION_METHOD_IFRAME}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                {t('%')}
                                                            </div>
                                                            {formik.errors.click_operating_probability ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.click_operating_probability}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-3 pt-2 mt-3 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('Size Option')}</span>
                                                <div className="form-check form-switch mb-2 mt-2">
                                                    <Input className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="flg_banner_size"
                                                        name="flg_banner_size"
                                                        checked={!!formik?.values?.flg_banner_size}
                                                        onChange={(event: any) => formik.setFieldValue('flg_banner_size', Number(event?.target.checked))}
                                                    />

                                                    <Label className="form-check-label" for="flg_banner_size">{t('Size Option')}</Label>
                                                    <TooltipCustom
                                                        title={t("When incoming from an external URL, change the banner size")}
                                                        className="d-inline-block vertical-align-middle ms-1"
                                                        style={{ transform: 'translateY(1px)' }}
                                                    >
                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                    </TooltipCustom>
                                                </div>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Width")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="width_1"
                                                                name="width_1"
                                                                autocomplete={false}
                                                                value={formik?.values?.width_1}
                                                                placeholder={`${t("Width")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("width_1", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.width_1 ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.width_1}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Height")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="height_1"
                                                                name="height_1"
                                                                autocomplete={false}
                                                                value={formik?.values?.height_1}
                                                                placeholder={`${t("Height")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("height_1", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                px
                                                            </div>
                                                            {formik.errors.height_1 ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.height_1}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('Block Referral')}</span>
                                                <div className="form-check form-switch mb-1 mt-2">
                                                    <Input className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="is_block_referral"
                                                        name="is_block_referral"
                                                        checked={!!formik?.values?.is_block_referral}
                                                        onChange={(event: any) => formik.setFieldValue('is_block_referral', Number(event?.target.checked))}
                                                    />
                                                    <Label className="form-check-label" for="is_block_referral">{t('Block Referral')}</Label>
                                                    <TooltipCustom
                                                        title={t("If the referral is empty or main, it's now shown ad")}
                                                        className="d-inline-block vertical-align-middle ms-1"
                                                        style={{ transform: 'translateY(1px)' }}
                                                    >
                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                    </TooltipCustom>
                                                </div>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('Block Keyword')}</span>
                                                <div className="form-check form-switch mb-1 mt-2">
                                                    <Input className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="is_except_keyword"
                                                        name="is_except_keyword"
                                                        checked={!!formik?.values?.is_except_keyword}
                                                        onChange={(event: any) => formik.setFieldValue('is_except_keyword', Number(event?.target.checked))}
                                                    />

                                                    <Label className="form-check-label" for="is_except_keyword">{t('Block Keyword')}</Label>
                                                    <TooltipCustom
                                                        title={t("If specific keywords is included in referral url, we show ad but it's not working 'Click Option', 'Size Option', only working normally in any cases.")}
                                                        className="d-inline-block vertical-align-middle ms-1"
                                                        style={{ transform: 'translateY(1px)' }}
                                                    >
                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                    </TooltipCustom>
                                                </div>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('Block Ad (Referral)')}
                                                </span>
                                                <div className="form-check form-switch mb-2 mt-2">
                                                    <Input className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="referral_block_ad"
                                                        name="referral_block_ad"
                                                        checked={!!formik?.values?.referral_block_ad}
                                                        onChange={(event: any) => formik.setFieldValue('referral_block_ad', Number(event?.target.checked))}
                                                    />

                                                    <Label className="form-check-label" for="referral_block_ad">{t('Block Ad (Referral)')}</Label>
                                                    <TooltipCustom
                                                        title={t("Ads will not be displayed when a specific keyword is included in the referrer")}
                                                        className="d-inline-block vertical-align-middle ms-1"
                                                        style={{ transform: 'translateY(1px)' }}
                                                    >
                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                    </TooltipCustom>
                                                </div>
                                                <Col xxl={12}>
                                                    <div style={{ minHeight: '100px' }}>
                                                        <label className="form-label">
                                                            {t("Block Keyword (Referral)")}
                                                        </label>
                                                        <Hashtag
                                                            name="tags"
                                                            placeholder={`${t("Press Enter to add a new keyword")}...`}
                                                            suggestions={[]}
                                                            initialValue={formik?.values?.referral_block_keyword || []}
                                                            onChangeTag={(event: any) => {
                                                                formik.setFieldValue('referral_block_keyword', event);
                                                            }}
                                                            idSelected="hashtags-CampaignFormMulti"
                                                            maxHashtags={2000}
                                                            maxHeight="150px"
                                                            allowDeleteFromEmptyInput={false}
                                                        />
                                                    </div>
                                                </Col>
                                            </Col>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 d-none" style={{ borderRadius: '5px' }}>
                                                <div className="form-check form-switch mb-0">
                                                    <Input className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="replace_button"
                                                        name="replace_button"
                                                        checked={!!formik?.values?.replace_button}
                                                        onChange={(event: any) => formik.setFieldValue('replace_button', Number(event?.target.checked))}
                                                    />
                                                    <Label className="form-check-label" for="replace_button">{t('Replace Button')}</Label>
                                                    <TooltipCustom
                                                        title={t("Disappear media's button and change close button style like media's button")}
                                                        className="d-inline-block vertical-align-middle ms-1"
                                                        style={{ transform: 'translateY(1px)' }}
                                                    >
                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                    </TooltipCustom>
                                                </div>
                                                <Col sm={12} lg={12} className="mb-2 mt-2">
                                                    <div>
                                                        <label className="form-label">
                                                            {t("Style Button")}
                                                        </label>
                                                        <Input
                                                            type="textarea"
                                                            rows={3}
                                                            id="replace_button_style"
                                                            name="replace_button_style"
                                                            autocomplete={false}
                                                            value={formik?.values?.replace_button_style}
                                                            placeholder={`${t("margin-bottom: 10px;")}...`}
                                                            onChange={(event: any) =>
                                                                formik.setFieldValue(
                                                                    "replace_button_style",
                                                                    event?.target?.value || ""
                                                                )
                                                            }
                                                        />
                                                        {formik.errors.replace_button_style ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.replace_button_style}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Col>
                                            <div className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('Except Visitors Automatic')}</span>
                                                <Col sm={12} className="mt-2">
                                                    <div className="form-check form-switch mb-0">
                                                        <Input className="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            id="except_general_visitors"
                                                            name="except_general_visitors"
                                                            checked={!!formik?.values?.except_general_visitors}
                                                            onChange={(event: any) => formik.setFieldValue('except_general_visitors', Number(event?.target.checked))}
                                                        />
                                                        <Label className="form-check-label" for="except_general_visitors">{t('Except general visitors')}</Label>
                                                    </div>
                                                </Col>
                                            </div>
                                            {(IS_INVENTORY_TYPE_SELF_ADVERTISING || IS_BANNER_TYPE_INVENTORY_SLIDER_BANNER_V2) &&
                                                <div className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative" style={{ borderRadius: '5px' }}>
                                                    <span className="position-absolute text-primary" style={{
                                                        top: 0,
                                                        transform: 'translate(-5px, -55%)',
                                                        backgroundColor: 'var(--vz-light)',
                                                        padding: '0 5px',
                                                        zIndex: 1,
                                                    }}>{t('Force Click (Layer & Slider)')}</span>
                                                    <Col sm={12} className="mt-2">
                                                        <div className="form-check form-switch mb-0">
                                                            <Input className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="automatic_click"
                                                                name="automatic_click"
                                                                checked={!!formik?.values?.automatic_click}
                                                                onChange={(event: any) => formik.setFieldValue('automatic_click', Number(event?.target.checked))}
                                                            />
                                                            <Label className="form-check-label" for="automatic_click">{t('Force Click (Layer & Slider)')}</Label>
                                                            <TooltipCustom
                                                                title={t("For self-advertisements, clicking around the ad also counts as clicking the ad. In the case of Slider Banner V2, touching it triggers the ad click.")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </div>
                                                    </Col>
                                                    <Row>
                                                        <Col sm={6} className="mt-2 mb-2">
                                                            <div>
                                                                <label className="form-label">
                                                                    {t("Operating Time (start)")}
                                                                    <span className="text-danger"> *</span>
                                                                    <TooltipCustom
                                                                        title={t("The time period during which automatic clicks operate.")}
                                                                        className="d-inline-block vertical-align-middle ms-1"
                                                                        style={{ transform: 'translateY(1px)' }}
                                                                    >
                                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                    </TooltipCustom>
                                                                </label>
                                                                <DropdownOption
                                                                    name="operating_time_start"
                                                                    dataList={OPERATING_OPTIONS_LANG || []}
                                                                    placeholder={`${t("Operating Time (start)")}...`}
                                                                    className="dropdown-status-rounded form-ph"
                                                                    classNamePrefix="name-prefix"
                                                                    initialValue={
                                                                        formik?.values?.operating_time_start ||
                                                                        OPERATING_OPTIONS_LANG[0]
                                                                    }
                                                                    onChangeSelect={(event: any) =>
                                                                        formik.setFieldValue("operating_time_start", event)
                                                                    }

                                                                />
                                                                {formik.errors.operating_time_start ? (
                                                                    <div className="text-danger mt-2">
                                                                        {formik.errors.operating_time_start}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col sm={6} className="mt-2 mb-2">
                                                            <div>
                                                                <label className="form-label">
                                                                    {t("Operating Time (end)")}
                                                                    <span className="text-danger"> *</span>
                                                                    <TooltipCustom
                                                                        title={t("The time period during which automatic clicks operate.")}
                                                                        className="d-inline-block vertical-align-middle ms-1"
                                                                        style={{ transform: 'translateY(1px)' }}
                                                                    >
                                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                    </TooltipCustom>
                                                                </label>
                                                                <DropdownOption
                                                                    name="operating_time_end"
                                                                    dataList={OPERATING_OPTIONS_LANG || []}
                                                                    placeholder={`${t("Operating Time (end)")}...`}
                                                                    className="dropdown-status-rounded form-ph"
                                                                    classNamePrefix="name-prefix"
                                                                    initialValue={
                                                                        formik?.values?.operating_time_end ||
                                                                        OPERATING_OPTIONS_LANG[5]
                                                                    }
                                                                    onChangeSelect={(event: any) =>
                                                                        formik.setFieldValue("operating_time_end", event)
                                                                    }
                                                                />
                                                                {formik.errors.operating_time_end ? (
                                                                    <div className="text-danger mt-2">
                                                                        {formik.errors.operating_time_end}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6} className="mt-2 mb-2">
                                                            <div className="position-relative">
                                                                <label className="form-label">
                                                                    {t("Operating Probability")}
                                                                    <span className="text-danger"> *</span>
                                                                    <TooltipCustom
                                                                        title={t("The probability of automatic clicks operating during the specified time period.")}
                                                                        className="d-inline-block vertical-align-middle ms-1"
                                                                        style={{ transform: 'translateY(1px)' }}
                                                                    >
                                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                    </TooltipCustom>
                                                                </label>
                                                                <Input
                                                                    type="text"
                                                                    id="operating_probability"
                                                                    name="operating_probability"
                                                                    autocomplete={false}
                                                                    value={formik?.values?.operating_probability}
                                                                    placeholder={`${t("Operating Probability")}...`}
                                                                    onChange={(event: any) => {
                                                                        const input = event.target.value;
                                                                        const onlyNumbers = input.replace(/\D/g, "");
                                                                        const s = Number(onlyNumbers);
                                                                        formik.setFieldValue("operating_probability", s);
                                                                    }}
                                                                />
                                                                <div
                                                                    style={{ bottom: 10, right: 20 }}
                                                                    className="position-absolute fw-medium text-secondary"
                                                                >
                                                                    {t('%')}
                                                                </div>
                                                                {formik.errors.operating_probability ? (
                                                                    <div className="text-danger mt-2">
                                                                        {formik.errors.operating_probability}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col sm={6} className="mt-2 mb-2">
                                                            <div>
                                                                <label className="form-label">
                                                                    {t("Exclusion Time (hour)")}
                                                                    <span className="text-danger"> *</span>
                                                                    <TooltipCustom
                                                                        title={t("The time period during which automatic clicks will not operate after an automatic click occurs.")}
                                                                        className="d-inline-block vertical-align-middle ms-1"
                                                                        style={{ transform: 'translateY(1px)' }}
                                                                    >
                                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                    </TooltipCustom>
                                                                </label>
                                                                <DropdownOption
                                                                    name="exclusion_time"
                                                                    dataList={FILL_RATE_TIME_OPTIONS_LANG || []}
                                                                    placeholder={`${t("Fill-Rate Time")}...`}
                                                                    className="dropdown-status-rounded form-ph"
                                                                    classNamePrefix="name-prefix"
                                                                    initialValue={
                                                                        formik?.values?.exclusion_time ||
                                                                        FILL_RATE_TIME_OPTIONS_LANG[23]
                                                                    }
                                                                    onChangeSelect={(event: any) =>
                                                                        formik.setFieldValue("exclusion_time", event)
                                                                    }
                                                                />
                                                                {formik.errors.exclusion_time ? (
                                                                    <div className="text-danger mt-2">
                                                                        {formik.errors.exclusion_time}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div className="text-center mt-4 mb-2 position-relative " style={{
                                                        borderBottom: '1px dashed rgba(var(--vz-primary-rgb)'
                                                    }}>
                                                        <i className="ri ri-arrow-down-s-line text-primary position-absolute"
                                                            style={{
                                                                transform: 'translate(-60%, -45%)',
                                                                backgroundColor: 'var(--vz-light)',
                                                                fontSize: '18px',
                                                            }} />

                                                        <TooltipCustom
                                                            title={t("This is the setting for self-advertisements.")}
                                                            className="d-inline-block vertical-align-middle ms-1 position-absolute"
                                                            style={{
                                                                right: 0,
                                                                transform: 'translateY(-45%)',
                                                                backgroundColor: 'var(--vz-light)',
                                                                fontSize: '14px',
                                                            }}
                                                        >
                                                            <i className="ri-question-line align-bottom text-primary" ></i>
                                                        </TooltipCustom>

                                                    </div>
                                                    <Row>
                                                        <Col sm={12} className="mt-2 mb-2">
                                                            <div>
                                                                <label className="form-label">
                                                                    {t("Delay (sec)")}
                                                                    <TooltipCustom
                                                                        title={t("Click after the specified time.")}
                                                                        className="d-inline-block vertical-align-middle ms-1"
                                                                        style={{ transform: 'translateY(1px)' }}
                                                                    >
                                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                    </TooltipCustom>
                                                                </label>
                                                                <Input
                                                                    type="text"
                                                                    id="automatic_delay_time"
                                                                    name="automatic_delay_time"
                                                                    autocomplete={false}
                                                                    value={formik?.values?.automatic_delay_time}
                                                                    placeholder={`${t("Delay (sec)")}...`}
                                                                    onChange={(event: any) => {
                                                                        const input = event.target.value;
                                                                        const onlyNumbers = input.replace(/\D/g, "");
                                                                        const s = Number(onlyNumbers);
                                                                        formik.setFieldValue("automatic_delay_time", s);
                                                                    }}
                                                                    disabled={!IS_INVENTORY_TYPE_SELF_ADVERTISING}
                                                                />
                                                                <div
                                                                    style={{ bottom: 10, right: 20 }}
                                                                    className="position-absolute fw-medium text-secondary"
                                                                >
                                                                    {t('seconds')}
                                                                </div>
                                                                {formik.errors.automatic_delay_time ? (
                                                                    <div className="text-danger mt-2">
                                                                        {formik.errors.automatic_delay_time}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} className="mt-2 mb-2">
                                                            <div>
                                                                <label className="form-label">{t("Click URL")}</label>
                                                                <Input
                                                                    type="text"
                                                                    id="layer_click_url"
                                                                    name="layer_click_url"
                                                                    autocomplete={false}
                                                                    value={formik?.values?.layer_click_url}
                                                                    placeholder={`${t("Click URL")}...`}
                                                                    onChange={(event: any) =>
                                                                        formik.setFieldValue(
                                                                            "layer_click_url",
                                                                            event?.target?.value || ""
                                                                        )
                                                                    }
                                                                    disabled={!IS_INVENTORY_TYPE_SELF_ADVERTISING}
                                                                />
                                                                {formik.touched.layer_click_url && formik.errors.layer_click_url ? (
                                                                    <div className="text-danger mt-2">
                                                                        {formik.errors.layer_click_url}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }

                                            {IS_INVENTORY_TYPE_SELF_ADVERTISING &&
                                                <div className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative" style={{ borderRadius: '5px' }}>
                                                    <span className="position-absolute text-primary" style={{
                                                        top: 0,
                                                        transform: 'translate(-5px, -55%)',
                                                        backgroundColor: 'var(--vz-light)',
                                                        padding: '0 5px',
                                                        zIndex: 1,
                                                    }}>{t('Automatic Click (Popup)')}</span>
                                                    <Col sm={12} className="mt-2">
                                                        <div className="form-check form-switch mb-0">
                                                            <Input className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="popup_automatic_click"
                                                                name="popup_automatic_click"
                                                                disabled={!IS_INVENTORY_TYPE_SELF_ADVERTISING}
                                                                checked={!!formik?.values?.popup_automatic_click}
                                                                onChange={(event: any) => formik.setFieldValue('popup_automatic_click', Number(event?.target.checked))}
                                                            />
                                                            <Label className="form-check-label" for="popup_automatic_click">{t('Automatic Click (Popup)')}</Label>
                                                            <TooltipCustom
                                                                title={t("Ads will be auto-clicked based on a specified probability (the popup may be blocked).")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </div>
                                                    </Col>
                                                    <Row>
                                                        <Col sm={6} className="mt-2 mb-2">
                                                            <div>
                                                                <label className="form-label">
                                                                    {t("Operating Time (start)")}
                                                                    <span className="text-danger"> *</span>
                                                                    <TooltipCustom
                                                                        title={t("The time period during which automatic clicks operate.")}
                                                                        className="d-inline-block vertical-align-middle ms-1"
                                                                        style={{ transform: 'translateY(1px)' }}
                                                                    >
                                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                    </TooltipCustom>
                                                                </label>
                                                                <DropdownOption
                                                                    name="popup_operating_time_start"
                                                                    dataList={OPERATING_OPTIONS_LANG || []}
                                                                    placeholder={`${t("Operating Time (start)")}...`}
                                                                    className="dropdown-status-rounded form-ph"
                                                                    classNamePrefix="name-prefix"
                                                                    initialValue={
                                                                        formik?.values?.popup_operating_time_start ||
                                                                        OPERATING_OPTIONS_LANG[0]
                                                                    }
                                                                    onChangeSelect={(event: any) =>
                                                                        formik.setFieldValue("popup_operating_time_start", event)
                                                                    }
                                                                    disabled={!IS_INVENTORY_TYPE_SELF_ADVERTISING}

                                                                />
                                                                {formik.errors.popup_operating_time_start ? (
                                                                    <div className="text-danger mt-2">
                                                                        {formik.errors.popup_operating_time_start}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col sm={6} className="mt-2 mb-2">
                                                            <div>
                                                                <label className="form-label">
                                                                    {t("Operating Time (end)")}
                                                                    <span className="text-danger"> *</span>
                                                                    <TooltipCustom
                                                                        title={t("The time period during which automatic clicks operate.")}
                                                                        className="d-inline-block vertical-align-middle ms-1"
                                                                        style={{ transform: 'translateY(1px)' }}
                                                                    >
                                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                    </TooltipCustom>
                                                                </label>
                                                                <DropdownOption
                                                                    name="popup_operating_time_end"
                                                                    dataList={OPERATING_OPTIONS_LANG || []}
                                                                    placeholder={`${t("Operating Time (end)")}...`}
                                                                    className="dropdown-status-rounded form-ph"
                                                                    classNamePrefix="name-prefix"
                                                                    initialValue={
                                                                        formik?.values?.popup_operating_time_end ||
                                                                        OPERATING_OPTIONS_LANG[5]
                                                                    }
                                                                    onChangeSelect={(event: any) =>
                                                                        formik.setFieldValue("popup_operating_time_end", event)
                                                                    }
                                                                    disabled={!IS_INVENTORY_TYPE_SELF_ADVERTISING}
                                                                />
                                                                {formik.errors.popup_operating_time_end ? (
                                                                    <div className="text-danger mt-2">
                                                                        {formik.errors.popup_operating_time_end}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6} className="mt-2 mb-2">
                                                            <div className="position-relative">
                                                                <label className="form-label">
                                                                    {t("Operating Probability")}
                                                                    <span className="text-danger"> *</span>
                                                                    <TooltipCustom
                                                                        title={t("The probability of automatic clicks operating during the specified time period.")}
                                                                        className="d-inline-block vertical-align-middle ms-1"
                                                                        style={{ transform: 'translateY(1px)' }}
                                                                    >
                                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                    </TooltipCustom>
                                                                </label>
                                                                <Input
                                                                    type="text"
                                                                    id="popup_operating_probability"
                                                                    name="popup_operating_probability"
                                                                    autocomplete={false}
                                                                    value={formik?.values?.popup_operating_probability}
                                                                    placeholder={`${t("Operating Probability")}...`}
                                                                    onChange={(event: any) => {
                                                                        const input = event.target.value;
                                                                        const onlyNumbers = input.replace(/\D/g, "");
                                                                        const s = Number(onlyNumbers);
                                                                        formik.setFieldValue("popup_operating_probability", s);
                                                                    }}
                                                                    disabled={!IS_INVENTORY_TYPE_SELF_ADVERTISING}
                                                                />
                                                                <div
                                                                    style={{ bottom: 10, right: 20 }}
                                                                    className="position-absolute fw-medium text-secondary"
                                                                >
                                                                    {t('%')}
                                                                </div>
                                                                {formik.errors.popup_operating_probability ? (
                                                                    <div className="text-danger mt-2">
                                                                        {formik.errors.popup_operating_probability}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col sm={6} className="mt-2 mb-2">
                                                            <div>
                                                                <label className="form-label">
                                                                    {t("Delay (sec)")}
                                                                    <TooltipCustom
                                                                        title={t("Click after the specified time.")}
                                                                        className="d-inline-block vertical-align-middle ms-1"
                                                                        style={{ transform: 'translateY(1px)' }}
                                                                    >
                                                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                    </TooltipCustom>
                                                                </label>
                                                                <Input
                                                                    type="text"
                                                                    id="popup_automatic_delay_time"
                                                                    name="popup_automatic_delay_time"
                                                                    autocomplete={false}
                                                                    value={formik?.values?.popup_automatic_delay_time}
                                                                    placeholder={`${t("Delay (sec)")}...`}
                                                                    onChange={(event: any) => {
                                                                        const input = event.target.value;
                                                                        const onlyNumbers = input.replace(/\D/g, "");
                                                                        const s = Number(onlyNumbers);
                                                                        formik.setFieldValue("popup_automatic_delay_time", s);
                                                                    }}
                                                                    disabled={!IS_INVENTORY_TYPE_SELF_ADVERTISING}
                                                                />
                                                                <div
                                                                    style={{ bottom: 10, right: 20 }}
                                                                    className="position-absolute fw-medium text-secondary"
                                                                >
                                                                    {t('seconds')}
                                                                </div>
                                                                {formik.errors.popup_automatic_delay_time ? (
                                                                    <div className="text-danger mt-2">
                                                                        {formik.errors.popup_automatic_delay_time}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} className="mt-2 mb-2">
                                                            <div>
                                                                <label className="form-label">
                                                                    {t("Click URL")}
                                                                </label>
                                                                <Input
                                                                    type="text"
                                                                    id="popup_click_url"
                                                                    name="popup_click_url"
                                                                    autocomplete={false}
                                                                    value={formik?.values?.popup_click_url}
                                                                    placeholder={`${t("Click URL")}...`}
                                                                    disabled={!IS_INVENTORY_TYPE_SELF_ADVERTISING}
                                                                    onChange={(event: any) =>
                                                                        formik.setFieldValue(
                                                                            "popup_click_url",
                                                                            event?.target?.value || ""
                                                                        )
                                                                    }
                                                                />
                                                                {formik.touched.popup_click_url && formik.errors.popup_click_url ? (
                                                                    <div className="text-danger mt-2">
                                                                        {formik.errors.popup_click_url}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                            <div className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative" style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('Automatic Redirect')}</span>
                                                <Col sm={12} className="mt-2">
                                                    <div className="form-check form-switch mb-0">
                                                        <Input className="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            id="automatic_redirect"
                                                            name="automatic_redirect"
                                                            disabled={IS_INTEGRATION_METHOD_IFRAME}
                                                            checked={!!formik?.values?.automatic_redirect}
                                                            onChange={(event: any) => formik.setFieldValue('automatic_redirect', Number(event?.target.checked))}
                                                        />
                                                        <Label className="form-check-label" for="automatic_redirect">{t('Automatic Redirect')}</Label>
                                                        <TooltipCustom
                                                            title={t("If the script installation type is set to 'normal,' it will function by automatically redirecting the user to the specified redirect URL if no click occurs within the designated time frame of 24 hours.")}
                                                            className="d-inline-block vertical-align-middle ms-1"
                                                            style={{ transform: 'translateY(1px)' }}
                                                        >
                                                            <i className="ri-question-line align-bottom text-secondary" ></i>
                                                        </TooltipCustom>
                                                    </div>
                                                </Col>
                                                <Row>
                                                    <Col sm={6} className="mt-2 mb-2">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Operating Time (start)")}
                                                                <span className="text-danger"> *</span>
                                                                <TooltipCustom
                                                                    title={t("The time period during which automatic clicks operate.")}
                                                                    className="d-inline-block vertical-align-middle ms-1"
                                                                    style={{ transform: 'translateY(1px)' }}
                                                                >
                                                                    <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                </TooltipCustom>
                                                            </label>
                                                            <DropdownOption
                                                                name="redirect_operating_time_start"
                                                                dataList={OPERATING_OPTIONS_LANG || []}
                                                                placeholder={`${t("Operating Time (start)")}...`}
                                                                className="dropdown-status-rounded form-ph"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={
                                                                    formik?.values?.redirect_operating_time_start ||
                                                                    OPERATING_OPTIONS_LANG[0]
                                                                }
                                                                onChangeSelect={(event: any) =>
                                                                    formik.setFieldValue("redirect_operating_time_start", event)
                                                                }
                                                                disabled={IS_INTEGRATION_METHOD_IFRAME}

                                                            />
                                                            {formik.errors.redirect_operating_time_start ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.redirect_operating_time_start}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} className="mt-2 mb-2">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Operating Time (end)")}
                                                                <span className="text-danger"> *</span>
                                                                <TooltipCustom
                                                                    title={t("The time period during which automatic clicks operate.")}
                                                                    className="d-inline-block vertical-align-middle ms-1"
                                                                    style={{ transform: 'translateY(1px)' }}
                                                                >
                                                                    <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                </TooltipCustom>
                                                            </label>
                                                            <DropdownOption
                                                                name="redirect_operating_time_end"
                                                                dataList={OPERATING_OPTIONS_LANG || []}
                                                                placeholder={`${t("Operating Time (end)")}...`}
                                                                className="dropdown-status-rounded form-ph"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={
                                                                    formik?.values?.redirect_operating_time_end ||
                                                                    OPERATING_OPTIONS_LANG[5]
                                                                }
                                                                onChangeSelect={(event: any) =>
                                                                    formik.setFieldValue("redirect_operating_time_end", event)
                                                                }
                                                                disabled={IS_INTEGRATION_METHOD_IFRAME}
                                                            />
                                                            {formik.errors.redirect_operating_time_end ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.redirect_operating_time_end}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6} className="mt-2 mb-2">
                                                        <div className="position-relative">
                                                            <label className="form-label">
                                                                {t("Operating Probability")}
                                                                <span className="text-danger"> *</span>
                                                                <TooltipCustom
                                                                    title={t("The probability of automatic clicks operating during the specified time period.")}
                                                                    className="d-inline-block vertical-align-middle ms-1"
                                                                    style={{ transform: 'translateY(1px)' }}
                                                                >
                                                                    <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                </TooltipCustom>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="redirect_operating_probability"
                                                                name="redirect_operating_probability"
                                                                autocomplete={false}
                                                                value={formik?.values?.redirect_operating_probability}
                                                                placeholder={`${t("Operating Probability")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("redirect_operating_probability", s);
                                                                }}
                                                                disabled={IS_INTEGRATION_METHOD_IFRAME}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                {t('%')}
                                                            </div>
                                                            {formik.errors.redirect_operating_probability ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.redirect_operating_probability}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} className="mt-2 mb-2">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Delay (sec)")}
                                                                <TooltipCustom
                                                                    title={t("Click after the specified time.")}
                                                                    className="d-inline-block vertical-align-middle ms-1"
                                                                    style={{ transform: 'translateY(1px)' }}
                                                                >
                                                                    <i className="ri-question-line align-bottom text-secondary" ></i>
                                                                </TooltipCustom>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="redirect_automatic_delay_time"
                                                                name="redirect_automatic_delay_time"
                                                                autocomplete={false}
                                                                value={formik?.values?.redirect_automatic_delay_time}
                                                                placeholder={`${t("Delay (sec)")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("redirect_automatic_delay_time", s);
                                                                }}
                                                                disabled={IS_INTEGRATION_METHOD_IFRAME}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                {t('seconds')}
                                                            </div>
                                                            {formik.errors.redirect_automatic_delay_time ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.redirect_automatic_delay_time}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} className="mt-2 mb-2">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Redirect URL")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="redirect_automatic_url"
                                                                name="redirect_automatic_url"
                                                                autocomplete={false}
                                                                value={formik?.values?.redirect_automatic_url}
                                                                placeholder={`${t("Redirect URL")}...`}
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue(
                                                                        "redirect_automatic_url",
                                                                        event?.target?.value || ""
                                                                    )
                                                                }
                                                                disabled={IS_INTEGRATION_METHOD_IFRAME}
                                                            />
                                                            {formik.touched.redirect_automatic_url && formik.errors.redirect_automatic_url ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.redirect_automatic_url}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Col lg={12} className="border border-dashed border-primary p-2 pb-3 pt-2 mt-3 mb-2 position-relative" style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('Custom Option')}</span>
                                                <Row className="mt-1">
                                                    <Col lg={6}>
                                                        <div>
                                                            <Label className="form-label">
                                                                {t("Additional Ad")}
                                                                <span className="text-danger"> *</span>
                                                            </Label>
                                                            <TooltipCustom
                                                                title={t("Hidden Ads Count")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                            <Input
                                                                type="text"
                                                                id="hidden_ads_count"
                                                                name="hidden_ads_count"
                                                                autocomplete={false}
                                                                value={formik?.values?.hidden_ads_count}
                                                                placeholder={`${t("Additional Ad")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers);
                                                                    formik.setFieldValue("hidden_ads_count", s);
                                                                }}
                                                            />
                                                            {formik.errors.hidden_ads_count ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.hidden_ads_count}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div>
                                                            <Label className="form-label">
                                                                {t("Target CTR")}
                                                                <span className="text-danger">{!!(formik?.values?.parent_code as any)?.value ? '*' : ''}</span>
                                                            </Label>
                                                            <TooltipCustom
                                                                title={t("Only in sub inventory")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                            <Input
                                                                type="text"
                                                                id="target_ctr"
                                                                name="target_ctr"
                                                                autocomplete={false}
                                                                disabled={IS_MAIN_INVENTORY}
                                                                value={formik?.values?.target_ctr}
                                                                placeholder={`${t("Target CTR")}...`}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                                    const s = Number(onlyNumbers) >= 100 ? 100 : Number(onlyNumbers);
                                                                    formik.setFieldValue("target_ctr", s);
                                                                }}
                                                            />
                                                            <div
                                                                style={{ bottom: 10, right: 20 }}
                                                                className="position-absolute fw-medium text-secondary"
                                                            >
                                                                %
                                                            </div>
                                                            {formik.errors.target_ctr ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.target_ctr}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <div className="border border-dashed border-primary p-2 pb-2 pt-2 mt-3 mb-2 position-relative " style={{ borderRadius: '5px' }}>
                                                <span className="position-absolute text-primary" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -55%)',
                                                    backgroundColor: 'var(--vz-light)',
                                                    padding: '0 5px',
                                                    zIndex: 1,
                                                }}>{t('Script')}</span>
                                                <Col sm={12} lg={6} className="mt-2 mb-2">
                                                    <div>
                                                        <label className="form-label">
                                                            {t("Installation Date")}
                                                        </label>
                                                        <div>
                                                            <DatePicker
                                                                disabled={!!!!detail?.parent_code}
                                                                className="form-control search"
                                                                wrapperClassName="w-100"
                                                                placeholderText={`${t('Installation Date')}`}
                                                                value={formik?.values?.installation_date || undefined}
                                                                selected={formik?.values?.installation_date ? new Date(formik?.values?.installation_date) : undefined}
                                                                dateFormat="yyyy-MM-dd HH:mm"
                                                                showTimeSelect
                                                                timeIntervals={5}
                                                                autoComplete="off"
                                                                timeCaption={t("Time")}
                                                                timeFormat="HH:mm"
                                                                isClearable={false}
                                                                locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue("installation_date", event)
                                                                }
                                                            />
                                                        </div>
                                                        {formik.errors.installation_date ? (
                                                            <div className="text-danger mt-2">
                                                                {formik.errors.installation_date}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </div>

                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: "100px" }}
                                                        onClick={() => {
                                                            handleClose && handleClose();
                                                        }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>
                                                    {!detail?.is_preview && (
                                                        <button
                                                            className="btn btn-primary rounded-pill fs-14"
                                                            color="success"
                                                            type="submit"
                                                            disabled={isLoading}
                                                            style={{ width: "150px" }}
                                                        >
                                                            {isLoading ? (
                                                                <Spinner size="sm" className="me-2"></Spinner>
                                                            ) : (
                                                                <>
                                                                    {detail?.id
                                                                        ? t("Button Update Inventory")
                                                                        : t("Button Create Inventory")}
                                                                </>
                                                            )}
                                                        </button>
                                                    )}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment>
    );
};

export default FormInventory;
