import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { ITracking, ITrackingSummaries } from "./types/_tracking";
const api = new APIClient();

const path = '/trackings';
const trackingApi = {
  trackings(params: any): Promise<AxiosResponse<ResponseData<ITracking[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  trackingLogs(params: any): Promise<AxiosResponse<ResponseData<ITracking[]> & PaginationResponse, any>> {
    const url = `${path}/ad-logs`
    return api.get(url, params)
  },
  downloadExcelTrackingLogs(params: any): Promise<AxiosResponse<ResponseData<ITracking[]> & PaginationResponse, any>> {
    const url = `${path}/ad-logs`
    return api.get(url, params)
  },
  trackingSummariesLogs(params: any): Promise<AxiosResponse<ResponseData<ITrackingSummaries[]> & PaginationResponse, any>> {
    const url = `${path}/ad-logs/summaries`
    return api.get(url, params)
  },
}
export default trackingApi
